import styled from "styled-components";

import { colors } from "src/styles";

export const TicketContainer = styled.div`
  background-color: white;
  border: 1px solid ${colors.grey.default};
  color: ${colors.grey.dark};
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  width: 100%;
`;

export const TicketHeading = styled.h4`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.grey.dark};
`;

export const TicketSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketDot = styled.div`
  height: 2px;
  width: 2px;
  background-color: ${colors.grey.dark};
  border-radius: 50%;
  display: inline-block;
`;
export const TicketButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p {
    text-decoration: underline;
    cursor: pointer !important;
  }
`;
