import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

const getWorkRequest = (state: RootState) => state.workRequest;

export const getWorkRequestMessage = createSelector([getWorkRequest], (workRequest) => workRequest.workRequestMessage);

export const getWorkRequestMessageVariant = createSelector(
  [getWorkRequest],
  (workRequest) => workRequest.workRequestMessageVariant,
);
