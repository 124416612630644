import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import LoadingContainer from "src/components/LoadingContainer";
import { Tile } from "src/components/Tile";
import { useIsMobile } from "src/hooks/useIsMobile";
import { getUserData } from "src/redux/selectors/user";
import { useNavigateCallbacks } from "../hooks";
import { ServiceTypesRow, Subtitle } from "../styles";
import { ServiceTypesTilesProps } from "../types";
import { ServiceTypeTile } from "./ServiceTypeTile";

/**
 * A helper component to display the list of service types tiles
 */
export const ServiceTypesTiles = ({ serviceTypes, numberOfTilestoDisplay, isLoading }: ServiceTypesTilesProps) => {
  const { isManager, clientId } = useSelector(getUserData);
  const { isMobile } = useIsMobile();
  const { onServiceTypeClick } = useNavigateCallbacks();
  const [numberOfTilesShown, setNumberOfTilesShown] = useState(numberOfTilestoDisplay);

  const clientServiceTypes = serviceTypes?.[clientId] ?? [];
  const numberOfServiceTypes = clientServiceTypes.length;
  const itemList = clientServiceTypes.slice(0, numberOfTilesShown ?? numberOfServiceTypes);

  const onShowMoreClick = useCallback(() => {
    setNumberOfTilesShown(numberOfServiceTypes);
  }, [serviceTypes, clientId]);

  return !isManager ? (
    <>
      <Subtitle $isMobile={isMobile}>Create New Work Request: </Subtitle>

      <ServiceTypesRow>
        <LoadingContainer isLoading={isLoading}>
          {itemList.map((serviceType) => (
            <ServiceTypeTile key={serviceType.id} onClick={onServiceTypeClick} serviceType={serviceType} />
          ))}
          {numberOfTilesShown < numberOfServiceTypes && (
            <Tile key="1" title="Show All" icon={"faEllipsisH"} onClick={onShowMoreClick}></Tile>
          )}
        </LoadingContainer>
      </ServiceTypesRow>
    </>
  ) : null;
};
