import { faPlus } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";

import Button from "src/components/Button";
import { Pagination } from "src/components/Pagination";
import { useIsMobile } from "src/hooks/useIsMobile";
import { setCompleteStatuses } from "src/redux/actions/completeStatuses";
import {
  clearWorkRequestMessage,
  setWorkRequestAlertVariant,
  setWorkRequestMessage,
} from "src/redux/actions/workRequest";
import { getUserData } from "src/redux/selectors/user";
import { getWorkRequestMessage, getWorkRequestMessageVariant } from "src/redux/selectors/workRequestMessage";
import { FirebaseDataService, Response } from "src/services/Firebase/data";
import {
  ID,
  PriorityLevelList,
  ServiceTypeList,
  TableHeadersTypes,
  Ticket,
  WorkOrderData,
  WorkOrderFilters,
  searchType,
} from "src/types";

import BreadCrumbs from "src/components/BreadCrumbs";
import { HorizontalTabs } from "src/components/Tabs/HorizontalTabs";
import { AssignWOResourceFormValues } from "src/components/molecules/AssignWOResource/types";
import { CloseWorkOrderFormValues } from "src/components/molecules/CloseWorkOrderForm/types";
import CloseWorkOrderModal from "src/components/organisms/CloseWorkOrderModal";
import { MAXIMO_TIME_ZONE } from "src/config/сonstants";
import { useIsMounted } from "src/hooks/useIsMounted";
import { setPageNameHeader } from "src/redux/actions/header";
import { setActiveResources } from "src/redux/actions/resources";
import { Content } from "src/styles";
import { getBase64File } from "src/utils/convertToBase64";
import { Container, EmptyContainer, NoTicketsMessage, PaginationContainer } from "../../components/ListScreen";
import Search from "./Search";
import { TicketTable } from "./TicketTable";
import { TicketsList } from "./TicketsList";
import { ServiceTypesTiles } from "./components/ServiceTypeTiles";
import {
  COUNT_OF_SERVICE_TYPE,
  MANAGER_WORKREQUESTS_BREADCRUMBS,
  WORK_REQUEST_TYPE,
  managerTableHeaders,
  sortingDirections,
  sortingOptions,
  tableHeaders,
  vendorTableHeaders,
} from "./constants";
import { useNavigateCallbacks } from "./hooks";
import { CreateButtonWrapper, Dropdowns, TableHeader, TabsWrapper } from "./styles";
import { DropdownItem } from "src/components/Dropdown/types";
import Dropdown from "src/components/Dropdown";

export const Tickets = () => {
  const initialFilters = {
    orderBy: [["dateCreated", "desc"]],
    where: [],
    pageSize: 0,
  };
  const initialSortingOption = {
    sort: "dateCreated",
    direction: "desc",
    completedSort: "dateCreated",
    completedDirection: "desc",
    plannedSort: "dateCreated",
    plannedDirection: "desc",
    approvalRequiredSort: "dateCreated",
    approvalRequiredDirection: "desc",
  };
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { isManager, clientId, isVendor, vendorId, maximoTimezone: maximoTimezoneDef } = useSelector(getUserData);
  const workRequestMessage = useSelector(getWorkRequestMessage);
  const workRequestAlertvariant = useSelector(getWorkRequestMessageVariant);
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeList>();
  const [workOrders, setWorkOrders] = useState<WorkOrderData>();
  const [plannedWorkOrders, setPlannedWorkOrders] = useState<WorkOrderData>();
  const [completedWorkOrders, setCompletedWorkOrders] = useState<WorkOrderData | null>(null);
  const [approvalRequiredWorkOrders, setApprovalRequiredWorkOrders] = useState<WorkOrderData | null>(null);

  const { id: loggedInUserId } = useSelector(getUserData);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlannedLoading, setIsPlannedLoading] = useState(false);
  const [isCompletedLoading, setIsCompletedLoading] = useState(false);
  const [isApprovalRequiredLoading, setIsApprovalRequiredLoading] = useState(false);

  const [siblingCount, setSiblingCount] = useState(0);
  const [priorityLevels, setPriorityLevels] = useState<PriorityLevelList>({});
  const [workOrderFilters, setWorkOrderFilter] = useState<WorkOrderFilters>(initialFilters);
  const [completedWorkOrderFilters, setCompletedWorkOrderFilter] = useState<WorkOrderFilters>(initialFilters);
  const [plannedWorkOrderFilters, setPlannedWorkOrderFilter] = useState<WorkOrderFilters>(initialFilters);
  const [approvalRequiredWorkOrderFilters, setApprovalRequiredWorkOrderFilter] =
    useState<WorkOrderFilters>(initialFilters);

  const [openCategory, setOpenCategory] = useQueryParam("openCategory", StringParam);
  const [openDirection, setOpenDirection] = useQueryParam("openDirection", StringParam);

  const [completedCategory, setCompletedCategory] = useQueryParam("completedCategory", StringParam);
  const [completedDirection, setCompletedDirection] = useQueryParam("completedDirection", StringParam);

  const [plannedCategory, setPlannedCategory] = useQueryParam("plannedCategory", StringParam);
  const [plannedDirection, setPlannedDirection] = useQueryParam("plannedDirection", StringParam);

  const [approvalRequiredCategory, setApprovalRequiredCategory] = useQueryParam(
    "approvalRequiredCategory",
    StringParam,
  );
  const [approvalRequiredDirection, setApprovalRequiredDirection] = useQueryParam(
    "approvalRequiredDirection",
    StringParam,
  );

  const [countOfServiceType, setCountOfServiceType] = useState<number>(COUNT_OF_SERVICE_TYPE);
  const [openedWorkRequestsPage, setOpenedWorkRequestsPage] = useQueryParam("openedWorkRequestsPage", NumberParam);
  const [plannedopenedWorkRequestsPage, setPlannedWorkRequestsPage] = useQueryParam(
    "plannedWorkRequestsPage",
    NumberParam,
  );
  const [completedWorkRequestsPage, setCompletedWorkRequestsPage] = useQueryParam(
    "closedWorkRequestsPage",
    NumberParam,
  );
  const [approvalRequiredWorkRequestsPage, setApprovalRequiredWorkRequestsPage] = useQueryParam(
    "approvalRequiredWorkRequestsPage",
    NumberParam,
  );
  const [searchTerm, setSearchTerm] = useQueryParam("searchTerm", StringParam);
  const [completedSerchTerm, setCompletedSerchTerm] = useQueryParam("completedSerchTerm", StringParam);
  const [plannedSerchTerm, setPlannedSerchTerm] = useQueryParam("plannedSerchTerm", StringParam);
  const [approvalRequiredSerchTerm, setApprovalRequiredSerchTerm] = useQueryParam(
    "approvalRequiredSerchTerm",
    StringParam,
  );
  const [openedWorkRequestsFetched, setOpenedWorkRequestsFetched] = useState<boolean>(false);
  const [plannedWorkRequestsFetched, setPlannedWorkRequestsFetched] = useState<boolean>(false);
  const [completedWorkRequestsFetched, setCompletedWorkRequestsFetched] = useState<boolean>(false);
  const [approvalRequiredWorkRequestsFetched, setApprovalRequiredWorkRequestsFetched] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useQueryParam("activeTab", StringParam);
  const [isCloseWOModalVisible, setIsCloseWOModalVisible] = useState<boolean>();
  const [search, setSearch] = useState<searchType>();
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<Ticket>();
  const { onRowClick, onAddNewRequestClick } = useNavigateCallbacks();

  const maximoTimezone = maximoTimezoneDef || MAXIMO_TIME_ZONE;

  useEffect(() => {
    if (!activeTab) {
      setActiveTab("open");
    }
    setSearch({
      value: !!openedWorkRequestsPage && !!searchTerm ? searchTerm ?? "" : "",
      applied: !!openedWorkRequestsPage && !!searchTerm ? true : false,
      completedValue: !!completedWorkRequestsPage && !!completedSerchTerm ? completedSerchTerm ?? "" : "",
      completedApplied: !!completedWorkRequestsPage && !!completedSerchTerm ? true : false,
      plannedValue: !!plannedopenedWorkRequestsPage && !!plannedSerchTerm ? plannedSerchTerm ?? "" : "",
      plannedApplied: !!plannedopenedWorkRequestsPage && !!plannedSerchTerm ? true : false,
      approvalRequiredValue:
        !!approvalRequiredWorkRequestsPage && !!approvalRequiredSerchTerm ? approvalRequiredSerchTerm ?? "" : "",
      approvalRequiredApplied: !!approvalRequiredWorkRequestsPage && !!approvalRequiredSerchTerm ? true : false,
    });
    const queryFilter = [[openCategory ?? "", openDirection ?? ""]];
    setWorkOrderFilter({ ...workOrderFilters, orderBy: queryFilter });
    const completedQueryFilter = [[completedCategory ?? "", completedDirection ?? ""]];
    setCompletedWorkOrderFilter({ ...completedWorkOrderFilters, orderBy: completedQueryFilter });

    const plannedQueryFilter = [[plannedCategory ?? "", plannedDirection ?? ""]];
    setPlannedWorkOrderFilter({ ...plannedWorkOrderFilters, orderBy: plannedQueryFilter });
    const approvalRequiredQueryFilter = [[approvalRequiredCategory ?? "", approvalRequiredDirection ?? ""]];
    setApprovalRequiredWorkOrderFilter({
      ...approvalRequiredWorkOrderFilters,
      orderBy: approvalRequiredQueryFilter,
    });
  }, []);

  useEffect(() => {
    if (workOrders) {
      dispatch(setActiveResources({ employees: workOrders.employees, vendors: workOrders.vendors }));
    }
  }, [workOrders]);
  const onSubmitCloseWorkOrderHandler = async (values: CloseWorkOrderFormValues) => {
    if (!selectedWorkOrder) {
      return;
    }
    const actstart = moment(values.actstart).tz(maximoTimezone, true).format();
    const actfinish = moment(values.actfinish).tz(maximoTimezone, true).format();
    const { status, message, data } = await FirebaseDataService.closeWorkOrder(
      selectedWorkOrder.wonum,
      values.notes,
      actstart,
      actfinish,
      values.approvedamount,
    );

    if (status === "success") {
      await uploadImages(data.workOrderId, values.attachments);
      dispatch(setWorkRequestMessage(message));
      dispatch(setWorkRequestAlertVariant(status));
      getWorkOrders(openedWorkRequestsPage || 1, workOrderFilters, search?.value);
    } else {
      const msg = message.split("\n")[1];
      dispatch(setWorkRequestMessage(msg));
      dispatch(setWorkRequestAlertVariant("danger"));
    }
    setIsCloseWOModalVisible(false);
    setSelectedWorkOrder(undefined);

    setTimeout(() => {
      dispatch(clearWorkRequestMessage());
    }, 10000);
  };

  const uploadImages = async (workOrderId: string, files: File[]) => {
    if (!files?.length) return;
    if (workOrderId) {
      for (const item of files) {
        const file = item;
        const folderName = "Technician";

        const convertedFile = (await getBase64File(file)) as string;
        const fileToUpload = convertedFile?.slice(convertedFile.indexOf(",") + 1);
        await FirebaseDataService.addMaximoWorkOrderAttachment(
          fileToUpload,
          workOrderId,
          file.name,
          file.type,
          folderName,
        );
      }
    }
  };

  const getWorkOrders = async (
    pageNum: number,
    filters?: WorkOrderFilters,
    searchKeyword?: string,
    isSearchJustApplied = false,
  ) => {
    setIsLoading(true);
    const { data } = await FirebaseDataService.getMaximoWorkOrders(
      loggedInUserId,
      {
        pageNum,
        orderBy: filters?.orderBy,
        pageSize: filters?.pageSize,
        workRequestType: WORK_REQUEST_TYPE.OPEN,
      },
      isManager,
      searchKeyword || "",
      vendorId,
    );

    if (!isMounted()) {
      setIsLoading(false);
      return;
    }

    if (data.workOrderPage !== openedWorkRequestsPage) {
      setOpenedWorkRequestsFetched(true);
      setOpenedWorkRequestsPage(data.workOrderPage);
    } else {
      setOpenedWorkRequestsFetched(false);
    }

    setWorkOrders(data);
    setIsLoading(false);
    if (vendorId) {
      return;
    }
    const { wonum } = data.workOrders[0] || {};
    if (
      data?.workOrders?.length === 1 &&
      wonum?.toLowerCase() === searchKeyword?.toLowerCase() &&
      isSearchJustApplied
    ) {
      navigate(`/workrequests/${wonum}`, { state: { ticketNumber: wonum } });
    }
  };

  const getPlannedWorkOrders = async (pageNum: number, searchKeyword?: string, isSearchJustApplied = false) => {
    setIsPlannedLoading(true);
    const { data } = await FirebaseDataService.getMaximoWorkOrders(
      loggedInUserId,
      {
        pageNum,
        orderBy: plannedWorkOrderFilters?.orderBy,
        pageSize: plannedWorkOrderFilters?.pageSize,
        workRequestType: WORK_REQUEST_TYPE.PLANNED,
      },
      isManager,
      searchKeyword || "",
    );

    if (!isMounted()) {
      setIsPlannedLoading(false);
      return;
    }

    if (data.workOrderPage !== plannedopenedWorkRequestsPage) {
      setPlannedWorkRequestsFetched(true);
      setPlannedWorkRequestsPage(data.workOrderPage);
    } else {
      setPlannedWorkRequestsFetched(false);
    }

    setPlannedWorkOrders(data);
    setIsPlannedLoading(false);
    const { wonum } = data.workOrders[0] || {};
    if (
      data?.workOrders?.length === 1 &&
      wonum?.toLowerCase() === searchKeyword?.toLowerCase() &&
      isSearchJustApplied
    ) {
      navigate(`/workrequests/${wonum}`, { state: { ticketNumber: wonum } });
    }
  };

  const getApprovalRequiredWorkOrders = async (
    pageNum: number,
    searchKeyword?: string,
    isSearchJustApplied = false,
  ) => {
    setIsApprovalRequiredLoading(true);
    const { data } = await FirebaseDataService.getMaximoWorkOrders(
      loggedInUserId,
      {
        pageNum,
        orderBy: plannedWorkOrderFilters?.orderBy,
        pageSize: plannedWorkOrderFilters?.pageSize,
        workRequestType: WORK_REQUEST_TYPE.APPROVAL_REQUIRED,
      },
      isManager,
      searchKeyword || "",
    );

    if (!isMounted()) {
      setIsApprovalRequiredLoading(false);
      return;
    }

    if (data.workOrderPage !== approvalRequiredWorkRequestsPage) {
      setApprovalRequiredWorkRequestsFetched(true);
      setApprovalRequiredWorkRequestsPage(data.workOrderPage);
    } else {
      setApprovalRequiredWorkRequestsFetched(false);
    }

    setApprovalRequiredWorkOrders(data);
    setIsApprovalRequiredLoading(false);
    const { wonum } = data.workOrders[0] || {};
    if (
      data?.workOrders?.length === 1 &&
      wonum?.toLowerCase() === searchKeyword?.toLowerCase() &&
      isSearchJustApplied
    ) {
      navigate(`/workrequests/${wonum}`, { state: { ticketNumber: wonum } });
    }
  };

  const fetchData = async () => {
    const { data } = await FirebaseDataService.getDataForTicketListPage(loggedInUserId, isManager, isVendor);
    if (!isMounted()) {
      return;
    }

    const serviceTypesLength = data.serviceTypes.serviceTypes[clientId]?.length || 0;
    const serviceTypesAmount = data.serviceTypes.serviceTypesAmount;

    // store ticketsPerPage
    setWorkOrderFilter({
      ...workOrderFilters,
      pageSize: data.ticketsPerPage,
      orderBy: [[openCategory ?? initialSortingOption.sort, openDirection ?? initialSortingOption.direction]],
    });

    setCompletedWorkOrderFilter({
      ...completedWorkOrderFilters,
      pageSize: data.ticketsPerPage,
      orderBy: [
        [
          completedCategory ?? initialSortingOption.completedSort,
          completedDirection ?? initialSortingOption.completedDirection,
        ],
      ],
    });

    setPlannedWorkOrderFilter({
      ...plannedWorkOrderFilters,
      pageSize: data.ticketsPerPage,
      orderBy: [
        [
          plannedCategory ?? initialSortingOption.plannedSort,
          plannedDirection ?? initialSortingOption.plannedDirection,
        ],
      ],
    });

    setApprovalRequiredWorkOrderFilter({
      ...approvalRequiredWorkOrderFilters,
      pageSize: data.ticketsPerPage,
      orderBy: [
        [
          approvalRequiredCategory ?? initialSortingOption.approvalRequiredSort,
          approvalRequiredDirection ?? initialSortingOption.approvalRequiredDirection,
        ],
      ],
    });
    setSiblingCount(data.siblings);

    setServiceTypes(data.serviceTypes.serviceTypes);

    if (serviceTypesAmount && serviceTypesLength - serviceTypesAmount > 1) {
      setCountOfServiceType(serviceTypesAmount);
    } else {
      setCountOfServiceType(serviceTypesLength);
    }

    setPriorityLevels(data.priorityLevels);

    dispatch(setCompleteStatuses(data.completeStatuses));

    // store completeStatuses
    const statuses = data.completeStatuses.map((status) => status.abbreviation);
    setCompletedWorkOrderFilter((prevState) => ({
      ...prevState,
      where: [...prevState.where, ["status", "in", statuses]],
    }));

    const whereClauses = statuses.map((status) => ["status", "!=", status]);

    setWorkOrderFilter((prevState) => ({
      ...prevState,
      where: [...prevState.where, ...whereClauses],
    }));

    setInited(true);
  };

  const getCompletedWorkOrders = async (
    pageNum: number,
    filter: WorkOrderFilters,
    searchKeyword?: string,
    isSearchJustApplied = false,
  ) => {
    setIsCompletedLoading(true);
    const { data } = await FirebaseDataService.getMaximoWorkOrders(
      loggedInUserId,
      {
        pageNum,
        orderBy: filter.orderBy,
        pageSize: filter.pageSize,
        workRequestType: WORK_REQUEST_TYPE.COMPLETED,
      },
      isManager,
      searchKeyword || "",
    );

    if (!isMounted()) {
      setIsCompletedLoading(false);
      return;
    }

    if (data.workOrderPage !== completedWorkRequestsPage) {
      setCompletedWorkRequestsFetched(true);
      setCompletedWorkRequestsPage(data.workOrderPage);
    } else {
      setCompletedWorkRequestsFetched(false);
    }

    setCompletedWorkOrders(data || []);
    setIsCompletedLoading(false);
    const { wonum } = data.workOrders[0] || {};
    if (data?.workOrders?.length === 1 && wonum === searchKeyword && isSearchJustApplied) {
      navigate(`/workrequests/${wonum}`, { state: { ticketNumber: wonum } });
    }
  };

  const mergeData = (data: Ticket[]) => {
    return data?.map((workOrder: Ticket): Ticket => {
      return {
        ...workOrder,
        dateCreated: moment(workOrder.dateCreated).format("MMMM Do YYYY, h:mm:ss a"),
        location: workOrder.location || "No location specified",
      };
    });
  };

  const onPageChangeClick = (pageNumber: number) => {
    setOpenedWorkRequestsFetched(false);
    setOpenedWorkRequestsPage(pageNumber);
  };

  const onPlannedPageChangeClick = (pageNumber: number) => {
    setPlannedWorkRequestsFetched(false);
    setPlannedWorkRequestsPage(pageNumber);
  };

  const onCompletedPageChangeClick = (number: number) => {
    setCompletedWorkRequestsFetched(false);
    setCompletedWorkRequestsPage(number);
  };
  const onApprovalRequiredPageChangeClick = (number: number) => {
    setApprovalRequiredWorkRequestsFetched(false);
    setApprovalRequiredWorkRequestsPage(number);
  };

  const onDropdownItemClick = (sortingOption: DropdownItem) => {
    const category = sortingOption.filter[0][0];
    const queryFilter = [[category, openDirection]];
    setOpenCategory(category);
    setOpenedWorkRequestsFetched(false);
    setWorkOrderFilter({ ...workOrderFilters, orderBy: queryFilter });
  };

  const onDropdownDirectionClick = (sortingDirection: DropdownItem) => {
    const direction = sortingDirection.filter[0];
    const queryFilter = [[openCategory, direction]];
    setOpenDirection(direction);
    setOpenedWorkRequestsFetched(false);
    setWorkOrderFilter({ ...workOrderFilters, orderBy: queryFilter });
  };

  const onCompletedDropdownItemClick = (sortingOption: DropdownItem) => {
    const category = sortingOption.filter[0][0];
    const queryFilter = [[category, completedDirection]];
    setCompletedCategory(category);
    setCompletedWorkRequestsFetched(false);
    setCompletedWorkOrderFilter({ ...completedWorkOrderFilters, orderBy: queryFilter });
  };

  const onCompletedDropdownDirectionClick = (sortingDirection: DropdownItem) => {
    const direction = sortingDirection.filter[0];
    const queryFilter = [[completedCategory, direction]];
    setCompletedDirection(direction);
    setCompletedWorkRequestsFetched(false);
    setCompletedWorkOrderFilter({ ...completedWorkOrderFilters, orderBy: queryFilter });
  };

  const onPlannedDropdownItemClick = (sortingOption: DropdownItem) => {
    const category = sortingOption.filter[0][0];
    const queryFilter = [[category, plannedDirection]];
    setPlannedCategory(category);
    setPlannedWorkRequestsFetched(false);
    setPlannedWorkOrderFilter({ ...plannedWorkOrderFilters, orderBy: queryFilter });
  };

  const onPlannedDropdownDirectionClick = (sortingDirection: DropdownItem) => {
    const direction = sortingDirection.filter[0];
    const queryFilter = [[plannedCategory, direction]];
    setPlannedDirection(direction);
    setPlannedWorkRequestsFetched(false);
    setPlannedWorkOrderFilter({ ...plannedWorkOrderFilters, orderBy: queryFilter });
  };

  const onApprovalRequiredDropdownItemClick = (sortingOption: DropdownItem) => {
    const category = sortingOption.filter[0][0];
    const queryFilter = [[category, approvalRequiredDirection]];
    setApprovalRequiredCategory(category);
    setApprovalRequiredWorkRequestsFetched(false);
    setApprovalRequiredWorkOrderFilter({ ...approvalRequiredWorkOrderFilters, orderBy: queryFilter });
  };
  const onApprovalRequiredDropdownDirectionClick = (sortingDirection: DropdownItem) => {
    const direction = sortingDirection.filter[0];
    const queryFilter = [[approvalRequiredCategory, direction]];
    setApprovalRequiredDirection(direction);
    setApprovalRequiredWorkRequestsFetched(false);
    setApprovalRequiredWorkOrderFilter({ ...approvalRequiredWorkOrderFilters, orderBy: queryFilter });
  };

  const tickets = useMemo(() => (workOrders ? mergeData(workOrders.workOrders) : []), [workOrders]);
  const completedTickets = useMemo(
    () => (completedWorkOrders ? mergeData(completedWorkOrders.workOrders) : []),
    [completedWorkOrders],
  );
  const plannedTickets = useMemo(
    () => (plannedWorkOrders ? mergeData(plannedWorkOrders.workOrders) : []),
    [plannedWorkOrders],
  );
  const approvalRequiredTickets = useMemo(
    () => (approvalRequiredWorkOrders ? mergeData(approvalRequiredWorkOrders.workOrders) : []),
    [approvalRequiredWorkOrders],
  );

  const handleApply = (workRequestType: string) => {
    if (workRequestType === WORK_REQUEST_TYPE.COMPLETED) {
      setSearch((old) => ({ ...old, completedApplied: true }));
      setCompletedWorkRequestsPage(1);
      setCompletedSerchTerm(search?.completedValue);
      getCompletedWorkOrders(1, completedWorkOrderFilters, search?.completedValue, true);
    } else if (workRequestType === WORK_REQUEST_TYPE.OPEN) {
      setSearch((old) => ({ ...old, applied: true }));
      getWorkOrders(1, workOrderFilters, search?.value, true);
      setOpenedWorkRequestsPage(1);
      setSearchTerm(search?.value);
    } else if (workRequestType === WORK_REQUEST_TYPE.APPROVAL_REQUIRED) {
      setSearch((old) => ({ ...old, approvalRequiredApplied: true }));
      getApprovalRequiredWorkOrders(1, search?.approvalRequiredValue, true);
      setApprovalRequiredWorkRequestsPage(1);
      setApprovalRequiredSerchTerm(search?.approvalRequiredValue);
    } else {
      setSearch((old) => ({ ...old, plannedApplied: true }));
      getPlannedWorkOrders(1, search?.plannedValue, true);
      setPlannedWorkRequestsPage(1);
      setPlannedSerchTerm(search?.plannedValue);
    }
  };

  const handleClear = (workRequestType: string) => {
    if (workRequestType === WORK_REQUEST_TYPE.COMPLETED) {
      setSearch((old) => ({ ...old, completedApplied: false, completedValue: "" }));
      setCompletedWorkRequestsPage(1);
      setCompletedSerchTerm(null);
      getCompletedWorkOrders(1, completedWorkOrderFilters);
    } else if (workRequestType === WORK_REQUEST_TYPE.OPEN) {
      setSearch((old) => ({ ...old, applied: false, value: "" }));
      setOpenedWorkRequestsPage(1);
      setSearchTerm(null);
      getWorkOrders(1, workOrderFilters);
    } else if (workRequestType === WORK_REQUEST_TYPE.APPROVAL_REQUIRED) {
      setSearch((old) => ({ ...old, approvalRequiredApplied: false, approvalRequiredValue: "" }));
      setApprovalRequiredWorkRequestsPage(1);
      setApprovalRequiredSerchTerm(null);
      getApprovalRequiredWorkOrders(1);
    } else {
      setSearch((old) => ({ ...old, plannedApplied: false, plannedValue: "" }));
      setOpenedWorkRequestsPage(1);
      setPlannedSerchTerm(null);
      getPlannedWorkOrders(1);
    }
  };
  const onClickBreadcrumb = (route: ID | null) => typeof route === "string" && navigate(route);

  useEffect(() => {
    fetchData();
    const timeSeconds = 5000;
    const timeout = setTimeout(() => dispatch(clearWorkRequestMessage()), timeSeconds);
    return () => {
      dispatch(clearWorkRequestMessage());
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (inited && !openedWorkRequestsFetched && activeTab === "open") {
      getWorkOrders(openedWorkRequestsPage || 1, workOrderFilters, search?.value);
    }
  }, [inited, workOrderFilters, openedWorkRequestsPage, activeTab]);

  useEffect(() => {
    if (inited && !plannedWorkRequestsFetched && activeTab === "planned") {
      getPlannedWorkOrders(plannedopenedWorkRequestsPage || 1, search?.plannedValue);
    }
  }, [inited, plannedWorkOrderFilters, plannedopenedWorkRequestsPage, activeTab]);

  useEffect(() => {
    if (inited && !completedWorkRequestsFetched && activeTab === "complete") {
      const searchValue = search?.completedApplied ? search.completedValue : "";
      getCompletedWorkOrders(completedWorkRequestsPage || 1, completedWorkOrderFilters, searchValue);
    }
  }, [inited, completedWorkRequestsPage, completedWorkOrderFilters, activeTab]);
  useEffect(() => {
    if (inited && !approvalRequiredWorkRequestsFetched && activeTab === "approvalRequired") {
      getApprovalRequiredWorkOrders(approvalRequiredWorkRequestsPage || 1, search?.approvalRequiredValue);
    }
  }, [inited, approvalRequiredWorkRequestsPage, approvalRequiredWorkOrderFilters, activeTab]);

  const getTableHeader = () => {
    if (isManager) {
      return managerTableHeaders;
    } else if (isVendor) {
      return vendorTableHeaders;
    } else {
      return tableHeaders;
    }
  };

  //set page name to the Header
  useEffect(() => {
    dispatch(setPageNameHeader("Work Requests"));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, []);
  const onSubmitAssignResourceHandler = async (
    data: AssignWOResourceFormValues,
    workOrderId: string,
    managerId: string,
  ): Promise<Response | undefined> => {
    if (!workOrderId) {
      return;
    }

    return FirebaseDataService.assignResourceInWorkOrder({
      wonum: workOrderId,
      approvedamount: data.vendors.approvedamount,
      vendorId: data.vendors.vendorId,
      woacceptscharges: data.vendors.woacceptscharges,
      managerId,
      laborCode: data.employees.laborCode,
    });
  };
  /**
   * This method is common method for pagination in work order request.
   */
  const getPaginations = (
    workOrderCount: number,
    pageSize: number,
    onPageChange: (pageNum: number) => void,
    openedWorkReqPage: number,
    sibCount: number,
  ) => {
    return (
      <PaginationContainer>
        <Pagination
          totalCount={workOrderCount}
          pageSize={pageSize}
          onPageChange={onPageChange}
          currentPage={openedWorkReqPage}
          siblingCount={sibCount}
        />
      </PaginationContainer>
    );
  };

  /**
   * This is also an common table headers method to renders header components.
   */
  const tableHeadersComp = (data: TableHeadersTypes) => {
    return (
      <TableHeader>
        <Search
          value={data?.value}
          applied={data?.applied}
          onChange={data?.onChange}
          onClickCancel={data?.onClickCancel}
          onClickApply={data?.handleApplyCall}
          onClickClear={data?.handleClearCall}
        />

        <Dropdowns $isMobile={isMobile}>
          <Dropdown
            id="filters-dropdown"
            width="220px"
            onClick={data?.onDropdownItemClick}
            items={data?.items}
            selectedDropdownItem={data.defaultItem}
          />
          <Dropdown
            width="220px"
            id="filters-dropdown"
            onClick={data?.onDropdownDirectionClick}
            items={data?.directions}
            selectedDropdownItem={data.defaultDirection}
          />
        </Dropdowns>
      </TableHeader>
    );
  };

  /**
   * This method is renders the table format for open work request for mobile.
   */
  const openWorkRequesTableForm = () => {
    return !workOrders ? (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    ) : (
      <div>
        {!isMobile && (
          <TicketTable
            clientId={clientId}
            priorityLevels={priorityLevels}
            onRowClick={(id) => {
              if (!vendorId) {
                onRowClick(id);
              }
            }}
            isLoading={isLoading}
            isRowClickable={!isVendor}
            headers={getTableHeader()}
            data={tickets}
            searchValue={search?.applied ? search.value : ""}
            managerRole={isManager}
            employees={workOrders.employees}
            vendors={workOrders.vendors}
            onSubmitAssignResource={onSubmitAssignResourceHandler}
            vendorRole={isVendor}
            onCloseWorkOrderClick={(wo) => {
              setSelectedWorkOrder(wo);
              setIsCloseWOModalVisible(true);
            }}
          />
        )}

        {!tickets.length && !isLoading && (
          <NoTicketsMessage $isMobile={isMobile}>
            {search?.applied
              ? `We couldn't find anything matching your search. Please, try again with different search criteria.`
              : "No work requests available"}
          </NoTicketsMessage>
        )}
        {isMobile && (
          <TicketsList
            vendorRole={isVendor}
            onClick={(id) => {
              if (!vendorId) {
                onRowClick(id);
              }
            }}
            data={tickets}
            onCloseWorkOrderClick={(wo) => {
              setSelectedWorkOrder(wo);
              setIsCloseWOModalVisible(true);
            }}
          />
        )}
        {getPaginations(
          workOrders?.workOrderCount || 0,
          workOrderFilters.pageSize,
          onPageChangeClick,
          openedWorkRequestsPage || 1,
          siblingCount,
        )}
      </div>
    );
  };

  /**
   * This method is renders the table format for planned work request for mobile.
   */
  const plannedWorkRequestTableForm = () => {
    return !plannedWorkOrders ? (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    ) : (
      <div>
        {!isMobile && (
          <TicketTable
            clientId={clientId}
            priorityLevels={priorityLevels}
            onRowClick={onRowClick}
            isLoading={isPlannedLoading}
            isRowClickable
            headers={isManager ? managerTableHeaders : tableHeaders}
            data={plannedTickets}
            searchValue={search?.plannedApplied ? search.plannedValue : ""}
            managerRole={isManager}
            employees={plannedWorkOrders.employees}
            vendors={plannedWorkOrders.vendors}
            onSubmitAssignResource={onSubmitAssignResourceHandler}
          />
        )}

        {!plannedTickets.length && !isPlannedLoading && (
          <NoTicketsMessage $isMobile={isMobile}>
            {search?.plannedApplied
              ? `We couldn't find anything matching your search. Please, try again with different search criteria.`
              : "No work requests available"}
          </NoTicketsMessage>
        )}
        {isMobile && <TicketsList onClick={onRowClick} data={plannedTickets} />}
        {getPaginations(
          plannedWorkOrders?.workOrderCount || 0,
          plannedWorkOrderFilters.pageSize,
          onPlannedPageChangeClick,
          plannedopenedWorkRequestsPage || 1,
          siblingCount,
        )}
      </div>
    );
  };

  /**
   * This method is renders the table format for completed work request for mobile.
   */
  const completedWorkRequesTableForm = () => {
    return !completedWorkOrders ? (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    ) : (
      <div>
        {!isMobile && (
          <TicketTable
            clientId={clientId}
            priorityLevels={priorityLevels}
            onRowClick={onRowClick}
            isLoading={isCompletedLoading}
            isRowClickable
            headers={isManager ? managerTableHeaders : tableHeaders}
            data={completedTickets}
            searchValue={search?.completedApplied ? search.completedValue : ""}
            managerRole={isManager}
            employees={completedWorkOrders.employees}
            vendors={completedWorkOrders.vendors}
            onSubmitAssignResource={onSubmitAssignResourceHandler}
          />
        )}

        {!completedTickets.length && !isCompletedLoading && (
          <NoTicketsMessage $isMobile={isMobile}>
            {search?.completedApplied
              ? `We couldn't find anything matching your search. Please, try again with different search criteria.`
              : "No work requests available"}
          </NoTicketsMessage>
        )}

        {isMobile && <TicketsList onClick={onRowClick} data={completedTickets} />}
        {getPaginations(
          (completedWorkOrders?.workOrderCount as number) || 0,
          completedWorkOrderFilters.pageSize,
          onCompletedPageChangeClick,
          completedWorkRequestsPage || 1,
          siblingCount,
        )}
      </div>
    );
  };
  /**
   * This method is renders the table format for approval required work request for mobile.
   */
  const approvalRequiredWorkRequesTableForm = () => {
    return !approvalRequiredWorkOrders ? (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    ) : (
      <div>
        {!isMobile && (
          <TicketTable
            clientId={clientId}
            priorityLevels={priorityLevels}
            onRowClick={onRowClick}
            isLoading={isApprovalRequiredLoading}
            isRowClickable
            headers={isManager ? managerTableHeaders : tableHeaders}
            data={approvalRequiredTickets}
            searchValue={search?.approvalRequiredApplied ? search.approvalRequiredValue : ""}
            managerRole={isManager}
            employees={approvalRequiredWorkOrders.employees}
            vendors={approvalRequiredWorkOrders.vendors}
            onSubmitAssignResource={onSubmitAssignResourceHandler}
          />
        )}

        {!approvalRequiredTickets.length && !isApprovalRequiredLoading && (
          <NoTicketsMessage $isMobile={isMobile}>
            {search?.approvalRequiredApplied
              ? `We couldn't find anything matching your search. Please, try again with different search criteria.`
              : "No work requests available"}
          </NoTicketsMessage>
        )}

        {isMobile && <TicketsList onClick={onRowClick} data={approvalRequiredTickets} />}
        {getPaginations(
          (approvalRequiredWorkOrders?.workOrderCount as number) || 0,
          approvalRequiredWorkOrderFilters.pageSize,
          onApprovalRequiredPageChangeClick,
          approvalRequiredWorkRequestsPage || 1,
          siblingCount,
        )}
      </div>
    );
  };
  /**
   * This method is renders all open work requests in UI.
   */
  const openWorkRequestComp = () => {
    return (
      <div className="open-work-requests">
        {tableHeadersComp({
          value: search?.value ?? "",
          applied: search?.applied ?? false,
          onChange: (value) => setSearch((old) => ({ ...old, value })),
          onClickCancel: () => setSearch((old) => ({ ...old, value: "" })),
          handleApplyCall: () => handleApply(WORK_REQUEST_TYPE.OPEN),
          handleClearCall: () => handleClear(WORK_REQUEST_TYPE.OPEN),
          items: isManager ? sortingOptions : sortingOptions.filter((e) => !e.onlyManager),
          directions: sortingDirections,
          onDropdownItemClick,
          onDropdownDirectionClick,
          defaultDirection:
            sortingDirections.find((option) => option.filter[0] === openDirection) ?? sortingDirections[0],
          defaultItem:
            sortingOptions.find((option) => option.filter[0][0] === openCategory) ||
            (isManager ? sortingOptions[0] : sortingOptions.filter((e) => !e.onlyManager)[0]),
        })}
        {workRequestMessage && (
          <Alert variant={workRequestAlertvariant} onClose={() => dispatch(clearWorkRequestMessage())} dismissible>
            <Alert.Heading>{workRequestMessage}</Alert.Heading>
          </Alert>
        )}
        {openWorkRequesTableForm()}
      </div>
    );
  };

  /**
   * This method is renders all completed work requests in UI.
   */
  const completedWorkRequestComp = () => {
    return (
      <div className="completed-work-requests">
        {tableHeadersComp({
          value: search?.completedValue ?? "",
          applied: search?.completedApplied ?? false,
          onChange: (completedValue) => setSearch((old) => ({ ...old, completedValue })),
          onClickCancel: () => setSearch((old) => ({ ...old })),
          handleApplyCall: () => handleApply(WORK_REQUEST_TYPE.COMPLETED),
          handleClearCall: () => handleClear(WORK_REQUEST_TYPE.COMPLETED),
          items: isManager ? sortingOptions : sortingOptions.filter((e) => !e.onlyManager),
          directions: sortingDirections,
          onDropdownItemClick: onCompletedDropdownItemClick,
          onDropdownDirectionClick: onCompletedDropdownDirectionClick,
          defaultDirection:
            sortingDirections.find((option) => option.filter[0] === completedDirection) ?? sortingDirections[0],
          defaultItem:
            sortingOptions.find((option) => option.filter[0][0] === completedCategory) ||
            (isManager ? sortingOptions[0] : sortingOptions.filter((e) => !e.onlyManager)[0]),
        })}
        {completedWorkRequesTableForm()}
      </div>
    );
  };

  /**
   * This method is renders all planned work requests in UI.
   */
  const plannedWorkRequestComp = () => {
    return (
      <div className="planned-work-requests">
        {tableHeadersComp({
          value: search?.plannedValue ?? "",
          applied: search?.plannedApplied ?? false,
          onChange: (plannedValue) => setSearch((old) => ({ ...old, plannedValue })),
          onClickCancel: () => setSearch((old) => ({ ...old, plannedValue: "" })),
          handleApplyCall: () => handleApply(WORK_REQUEST_TYPE.PLANNED),
          handleClearCall: () => handleClear(WORK_REQUEST_TYPE.PLANNED),
          items: isManager ? sortingOptions : sortingOptions.filter((e) => !e.onlyManager),
          directions: sortingDirections,
          onDropdownItemClick: onPlannedDropdownItemClick,
          onDropdownDirectionClick: onPlannedDropdownDirectionClick,
          defaultDirection:
            sortingDirections.find((option) => option.filter[0] === plannedDirection) ?? sortingDirections[0],
          defaultItem:
            sortingOptions.find((option) => option.filter[0][0] === plannedCategory) ||
            (isManager ? sortingOptions[0] : sortingOptions.filter((e) => !e.onlyManager)[0]),
        })}
        {workRequestMessage && (
          <Alert variant="success" onClose={() => dispatch(clearWorkRequestMessage())} dismissible>
            <Alert.Heading>{workRequestMessage}</Alert.Heading>
          </Alert>
        )}
        {plannedWorkRequestTableForm()}
      </div>
    );
  };
  /**
   * This method is renders all approval required work requests in UI.
   */
  const approvalRequiredWorkRequestComp = () => {
    return (
      <div className="approval-required-work-requests">
        {tableHeadersComp({
          value: search?.approvalRequiredValue ?? "",
          applied: search?.approvalRequiredApplied ?? false,
          onChange: (approvalRequiredValue) => setSearch((old) => ({ ...old, approvalRequiredValue })),
          onClickCancel: () => setSearch((old) => ({ ...old, approvalRequiredValue: "" })),
          handleApplyCall: () => handleApply(WORK_REQUEST_TYPE.APPROVAL_REQUIRED),
          handleClearCall: () => handleClear(WORK_REQUEST_TYPE.APPROVAL_REQUIRED),
          items: isManager ? sortingOptions : sortingOptions.filter((e) => !e.onlyManager),
          directions: sortingDirections,
          onDropdownItemClick: onApprovalRequiredDropdownItemClick,
          onDropdownDirectionClick: onApprovalRequiredDropdownDirectionClick,
          defaultDirection:
            sortingDirections.find((option) => option.filter[0] === approvalRequiredDirection) ?? sortingDirections[0],
          defaultItem:
            sortingOptions.find((option) => option.filter[0][0] === approvalRequiredCategory) ||
            (isManager ? sortingOptions[0] : sortingOptions.filter((e) => !e.onlyManager)[0]),
        })}
        {approvalRequiredWorkRequesTableForm()}
      </div>
    );
  };

  /**
   * This method is for create an common horizantal tab component array.
   */
  const getHorizantalTabArray = () => {
    return [
      {
        eventKey: "open",
        label: "Open",
        content: openWorkRequestComp(),
      },
      {
        eventKey: "complete",
        label: "Complete",
        content: completedWorkRequestComp(),
      },
      {
        eventKey: "planned",
        label: "Planned Maintenance",
        content: plannedWorkRequestComp(),
      },
      {
        eventKey: "approvalRequired",
        label: "Approvals",
        content: approvalRequiredWorkRequestComp(),
      },
    ];
  };

  /**
   *
   * This method is an horizantal Tab component which take parameters and display the tabs.
   */
  const horizantalTabs = () => {
    return (
      activeTab && <HorizontalTabs tabs={getHorizantalTabArray()} activeTab={activeTab} setActiveTab={setActiveTab} />
    );
  };

  return (
    <Container>
      {(isManager || isVendor) && (
        <BreadCrumbs folderStack={MANAGER_WORKREQUESTS_BREADCRUMBS} onClickItem={onClickBreadcrumb} />
      )}
      <Content>
        {!isVendor ? (
          <>
            <ServiceTypesTiles
              serviceTypes={serviceTypes}
              numberOfTilestoDisplay={countOfServiceType}
              isLoading={!inited}
            />
            <TabsWrapper>
              {isManager && (
                <CreateButtonWrapper>
                  <Button
                    text="New Work Request"
                    variant="white"
                    additionalStyles={{ display: "flex" }}
                    icon={faPlus as any}
                    onClick={() => onAddNewRequestClick()}
                  />
                </CreateButtonWrapper>
              )}
              {horizantalTabs()}
            </TabsWrapper>
          </>
        ) : (
          <>{openWorkRequestComp()}</>
        )}
      </Content>
      {isCloseWOModalVisible && selectedWorkOrder && (
        <CloseWorkOrderModal
          closeModal={() => setIsCloseWOModalVisible(false)}
          onSubmit={onSubmitCloseWorkOrderHandler}
          workorder={selectedWorkOrder}
        />
      )}
    </Container>
  );
};
