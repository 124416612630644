import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Badge from "src/components/Badge";
import DataTable from "src/components/Table";
import TableCell from "src/components/Table/TableCell";
import TableRow from "src/components/Table/TableRow";
import { AssignWOResourceFormValues } from "src/components/molecules/AssignWOResource/types";
import AssignWOResourceModal from "src/components/organisms/AssignWOResourseModal";
import { getUserData } from "src/redux/selectors/user";
import { Response } from "src/services/Firebase/data";
import { colors } from "src/styles";
import { PriorityLevelList, Ticket } from "src/types";
import styled from "styled-components";
import { Cell } from "../../../components/Table/Cell";
import { TicketButton } from "../Ticket/styles";
import ResourseAssigned from "../components/ResourseAssigned";
import { getApprovedAmount } from "../utils";

export interface TicketTableProps {
  clientId: string;
  priorityLevels: PriorityLevelList;
  isLoading: boolean;
  headers: string[];
  data: Ticket[];
  isRowClickable: boolean;
  onRowClick?: (id: string) => void;
  isHeaderDisabled?: boolean;
  searchValue?: string;
  managerRole?: boolean;
  vendorRole?: boolean;
  onCloseWorkOrderClick?: (workOrder: Ticket) => void;
  vendors: ActiveVendor[];
  employees: ActiveEmployee[];
  onSubmitAssignResource: (
    data: AssignWOResourceFormValues,
    workOrderId: string,
    managerId: string,
  ) => Promise<Response | undefined>;
}
export interface ActiveEmployee {
  id: string;
  laborCode: string;
  displayName: string;
  email?: string;
}

export interface ActiveVendor {
  id: string;
  vendorId: string;
  displayName: string;
  contacts: DBVendorContact[];
  clients: string[];
}

export interface DBVendorContact {
  id?: string;
  email: string;
  name: string;
  phone: string;
  position: string;
}

export const TicketTable = ({
  clientId,
  isLoading,
  headers,
  data,
  isRowClickable,
  onRowClick,
  isHeaderDisabled,
  priorityLevels,
  searchValue,
  managerRole,
  employees,
  vendors,
  onSubmitAssignResource,
  vendorRole,
  onCloseWorkOrderClick,
}: TicketTableProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<AssignWOResourceFormValues>();
  const [workOrderId, setWorkOrderId] = useState<string>("");
  const [selectedClinetId, setSelectedClinetId] = useState<string>("");
  const [assignResourceErrorOnSubmit, setAssignResourceErrorOnSubmit] = useState("");
  const [allTicket, setAllTicket] = useState<Ticket[]>([]);
  const { id } = useSelector(getUserData);
  useEffect(() => {
    setAllTicket(data);
  }, [data]);

  const onAssignResourcehandler = async (workOrder: AssignWOResourceFormValues) => {
    setAssignResourceErrorOnSubmit("");
    const res = await onSubmitAssignResource(workOrder, workOrderId, id);

    if (res && res.status === "success") {
      setAllTicket((tickets) =>
        tickets.map((ticket) => {
          if (ticket.wonum === workOrderId) {
            ticket["vendorId"] = workOrder.vendors.vendorId;
            ticket["laborId"] = workOrder.employees.laborCode;
            ticket["woacceptscharges"] = workOrder.vendors.woacceptscharges;
            ticket["approvedamount"] = workOrder.vendors.approvedamount;
            return ticket;
          }
          return ticket;
        }),
      );
      setIsModalVisible(false);
    } else {
      setAssignResourceErrorOnSubmit(res?.message ?? "");
    }
  };

  return (
    <>
      <DataTable isLoading={isLoading} headers={headers} disableHeader={isHeaderDisabled}>
        <>
          {allTicket?.map((ticket: Ticket) => {
            const pickedPriorityLevel = priorityLevels?.[ticket.clientId ?? clientId]?.find(
              ({ priority }) => priority === ticket.priorityId,
            );

            const splittedDate = ticket.dateCreated.split(", ");

            return (
              <TableRow
                onClick={() => {
                  onRowClick && onRowClick(ticket.wonum);
                }}
                $clickable={isRowClickable}
                key={ticket.wonum}
              >
                <Cell showMark highlight={searchValue}>
                  {ticket.wonum}
                </Cell>
                <Cell highlight={searchValue} bold={splittedDate[0] || ""}>
                  {searchValue?.trim().length ? ticket.dateCreated : `, ${splittedDate[1] || ""}`}
                </Cell>
                {(managerRole || vendorRole) && (
                  <Cell showMark highlight={searchValue}>
                    {ticket.client || ""}
                  </Cell>
                )}
                <Cell showMark highlight={searchValue}>
                  {ticket.location}
                </Cell>
                <Cell showMark highlight={searchValue}>
                  {ticket.description || ""}
                </Cell>
                <Cell showMark highlight={searchValue}>
                  {ticket.serviceType}
                </Cell>
                {!(managerRole || vendorRole) && (
                  <>
                    <Cell showMark highlight={searchValue}>
                      {ticket.classification}
                    </Cell>
                    <Cell showMark highlight={searchValue}>
                      {ticket.workType || ""}
                    </Cell>
                  </>
                )}

                <TableCell>
                  <TablePriorityBadge color={pickedPriorityLevel ? pickedPriorityLevel.color : colors.grey.default}>
                    {ticket.priority}
                  </TablePriorityBadge>
                </TableCell>
                {vendorRole && <TableCell>{getApprovedAmount(ticket)}</TableCell>}
                {!vendorRole && (
                  <TableCell>
                    <Badge status={ticket.statusDescription as string}></Badge>
                  </TableCell>
                )}
                {managerRole && (
                  <TableCell>
                    <ResourseAssigned
                      isTableCell={true}
                      highlight={searchValue}
                      onClick={() => {
                        setIsModalVisible(true);
                        setSelectedTicket({
                          vendors: {
                            approvedamount: ticket.approvedamount,
                            woacceptscharges: ticket.woacceptscharges,
                            vendorId: ticket.vendorId,
                          },
                          employees: {
                            laborCode: ticket.laborId,
                          },
                          // commented out temporarily to hide the Employees tab. Will be reverted in the future
                          activetab: ticket?.vendorId ? "vendors" : "employees",
                          // activetab: "vendors",
                        });
                        setWorkOrderId(ticket.wonum);
                        setSelectedClinetId(ticket.clientId);
                      }}
                      employees={employees}
                      vendors={vendors}
                      laborCode={ticket.laborId}
                      vendorId={ticket.vendorId}
                    />
                  </TableCell>
                )}

                {vendorRole && (
                  <TableCell $isLink={true}>
                    <TicketButton onClick={() => onCloseWorkOrderClick?.(ticket)}>{"Close"}</TicketButton>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </>
      </DataTable>
      {isModalVisible && selectedTicket && (
        <AssignWOResourceModal
          closeModal={() => {
            setIsModalVisible(false);
          }}
          onSubmit={onAssignResourcehandler}
          employees={employees}
          vendors={vendors.filter((vendor) => vendor.clients && vendor.clients.includes(selectedClinetId))}
          defaultValues={selectedTicket}
          errorMsg={assignResourceErrorOnSubmit}
        />
      )}
    </>
  );
};

export interface TablePriorityBadgeProps {
  high?: string;
  emergency?: string;
  immediate?: string;
  low?: string;
  color: string;
}

const TablePriorityBadge = styled.div<TablePriorityBadgeProps>`
  font-weight: 600;
  color: ${colors.grey.dark};
  color: ${(props) => props.color};
`;
