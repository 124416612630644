import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { getLoggedIn, getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import FirebaseStorageService from "src/services/Firebase/storage";

import { DAYS_AFTER_SESSION_EXPIRES } from "./config/сonstants";

import Layout from "./components/Layout";
import { ChangePasswordPage, ForgotPasswordPage, LoginPage, ResetPasswordPage } from "./pages";
import { ClientDetails } from "./pages/ClientDetails";
import ClientDetailsContextProvider from "./pages/ClientDetails/ClientDetailsProvider";
import { LocationDetails } from "./pages/ClientDetails/LocationDetails";
import LocationDetailsContextProvider from "./pages/ClientDetails/LocationDetails/LocationDetailsProvider";
import { PayPeriod } from "./pages/ClientDetails/TimeAndAttendance/PayPeriod";
import { Clients } from "./pages/Clients";
import DynamicForm from "./pages/DynamicFormContainer";
import DynamicFormList from "./pages/DynamicFormList";
import FileSharing from "./pages/FileSharing";
import { GlobalSettings } from "./pages/GlobalSettings";
import Home from "./pages/Home";
import { NoUserLogin } from "./pages/NoUserLogin";
import { PageNotFound } from "./pages/PageNotFound";
import TeamMemberDetails from "./pages/TeamMemberDetails";
import TeamMembers from "./pages/TeamMembers";
import { TicketForm } from "./pages/TicketForm";
import { Tickets } from "./pages/Tickets";
import VendorDetails from "./pages/VendorDetails";
import Vendors from "./pages/Vendors";
import { logOutUser } from "./redux/actions/user";
import { getLoginDate } from "./redux/selectors/user";
import { getDatesDifference } from "./utils";
import { debugError } from "./utils/log";

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(getLoggedIn);
  const loginDate = useSelector(getLoginDate);
  const { clientId = "", vendorId = "", isManager, isVendor, isClient } = useSelector(getUserData);

  const [name, setName] = useState<string>("");
  const [logo, setLogo] = useState<string>("");

  const clearClientData = () => {
    setName("");
    setLogo("");
  };
  const getClientData = async () => {
    try {
      const { data } = await FirebaseDataService.getClientDataById(clientId);

      if (data.logo) {
        const { data: logoUrl } = await FirebaseStorageService.getDownloadURL(
          process.env.REACT_APP_CLIENT_LOGO_FOLDER,
          data.logo,
        );
        setLogo(logoUrl);
      }
      setName(data.name);
    } catch (error) {
      debugError(error);
    }
  };

  const getVendorData = async () => {
    try {
      const { data } = await FirebaseDataService.getVendorDataById(vendorId);

      if (data.logo) {
        const { data: logoUrl } = await FirebaseStorageService.getDownloadURL(
          process.env.REACT_APP_VENDOR_LOGO_FOLDER,
          data.logo,
        );
        setLogo(logoUrl);
      }
      setName(data.name);
    } catch (error) {
      debugError(error);
    }
  };

  const protectRoute = (Component: JSX.Element) => {
    return loggedIn ? Component : <Navigate to="/login" />;
  };

  const managerRoute = (Component: JSX.Element) => {
    return isManager ? Component : <Navigate to="/" />;
  };

  const clientRoute = (Component: JSX.Element) => {
    return isManager ? <Navigate to="/" /> : Component;
  };

  useEffect(() => {
    if (loggedIn) {
      if (isVendor) {
        getVendorData();
      }
      if (isClient) {
        getClientData();
      }
    } else {
      clearClientData();
    }
  }, [loggedIn]);

  useEffect(() => {
    const daysFromLogin = getDatesDifference(loginDate);
    if (daysFromLogin > DAYS_AFTER_SESSION_EXPIRES) {
      dispatch(logOutUser());
    }
  }, []);

  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Layout name={name} logo={logo} loggedIn={loggedIn}>
          <Routes>
            <Route path="/" element={protectRoute(<Home />)} />
            <Route path="/files" element={protectRoute(<FileSharing />)} />
            <Route path="/change-password" element={protectRoute(<ChangePasswordPage />)} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/clients" element={managerRoute(protectRoute(<Clients />))} />
            <Route path="/team-members" element={managerRoute(protectRoute(<TeamMembers />))} />
            <Route path="/team-members/:id" element={managerRoute(protectRoute(<TeamMemberDetails />))} />
            <Route path="/clients" element={<ClientDetailsContextProvider />}>
              <Route path="/clients/:id" element={managerRoute(protectRoute(<ClientDetails />))} />
              <Route path="/clients/:id/:payPeriodId" element={managerRoute(protectRoute(<PayPeriod />))} />
            </Route>
            <Route
              path="/clients/locations/:id"
              element={managerRoute(
                protectRoute(
                  <LocationDetailsContextProvider>
                    <LocationDetails />
                  </LocationDetailsContextProvider>,
                ),
              )}
            />
            <Route path="/global-settings" element={managerRoute(protectRoute(<GlobalSettings />))} />
            <Route path="/vendors" element={managerRoute(protectRoute(<Vendors />))} />
            <Route path="/vendors/:id" element={managerRoute(protectRoute(<VendorDetails />))} />
            <Route path="/workrequests" element={protectRoute(<Tickets />)} />
            <Route path="/workrequests/create" element={protectRoute(<TicketForm />)} />
            <Route path="/workrequests/:id" element={protectRoute(<TicketForm />)} />
            <Route path="/dynamic-forms" element={protectRoute(<DynamicFormList />)} />
            <Route path="/dynamic-forms/:id" element={protectRoute(<DynamicForm />)} />

            <Route path="/404" element={<PageNotFound />} />
            <Route
              path="/:clientCode/workrequests"
              element={clientRoute(<NoUserLogin name={name} logo={logo} setName={setName} setLogo={setLogo} />)}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Layout>
      </QueryParamProvider>
    </Router>
  );
};
