import React from "react";

import { Ticket as TicketType } from "src/types";

import { getApprovedAmount } from "../utils";
import {
  TicketButton,
  TicketContainer,
  TicketDot,
  TicketHeading,
  TicketInfo,
  TicketStatus,
  TicketSubtitle,
} from "./styles";

export interface TicketProps {
  ticket: TicketType;
  vendorRole?: boolean;
  onCloseWorkOrderClick?: (workOrder: TicketType) => void;
}

export const Ticket: React.FC<TicketProps> = ({ ticket, vendorRole, onCloseWorkOrderClick }) => {
  return (
    <TicketContainer>
      <TicketHeading>
        {ticket.wonum}: {ticket.description}
      </TicketHeading>
      <TicketSubtitle>
        <p>{ticket.dateCreated}</p>
        {!vendorRole && (
          <>
            <TicketDot />
            <p>{ticket.location}</p>
          </>
        )}
      </TicketSubtitle>

      <TicketInfo>
        {vendorRole && <p>{ticket.client}</p>}
        {!vendorRole && <p>{ticket.classification}</p>}
        <TicketDot />
        <p>{ticket.serviceType}</p>
      </TicketInfo>
      <TicketStatus>
        <p>{ticket.priority}</p>
        <TicketDot />
        {!vendorRole && <p>{ticket.status}</p>}
        {vendorRole && <p>{getApprovedAmount(ticket)}</p>}
      </TicketStatus>
      {vendorRole && (
        <TicketButton onClick={() => onCloseWorkOrderClick?.(ticket)}>
          <p>Close</p>
        </TicketButton>
      )}
    </TicketContainer>
  );
};
