/* eslint-disable @typescript-eslint/no-empty-function */
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "src/components/Button";
import Input from "src/components/Input";
import { colors } from "src/styles";
import styled from "styled-components";

type SearchProps = {
  value: string;
  applied: boolean;
  onChange: (value: string) => void;
  onClickCancel?: () => void;
  onClickApply?: () => void;
  onClickClear?: () => void;
};

const Search: React.FC<SearchProps> = ({
  value,
  applied,
  onChange = () => {},
  onClickCancel = () => {},
  onClickApply = () => {},
  onClickClear = () => {},
}) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <Container>
      <InputContainer>
        <StyledInput
          maxLength={100}
          value={value}
          onChange={onInputChange}
          placeholder="Search"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickApply();
            }
          }}
        />
        {(applied || value) && <Icon icon={faTimesCircle} onClick={applied ? onClickClear : onClickCancel} />}
      </InputContainer>

      <ButtonContainer>
        <Button
          additionalStyles={{ backgroundColor: colors.kleenway.green }}
          variant="small-primary"
          text="Search"
          onClick={onClickApply}
          disabled={!value.length}
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 330px;
  box-sizing: content-box;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const StyledInput = styled(Input)`
  border-radius: 4px 0 0 4px;
  border-right: 0;
  height: unset;
  padding: 7px 0px 7px 12px;

  &:focus {
    outline: none;
    border: 1px solid ${colors.kleenway.green};
    border-right: 0;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 12px;
  width: 12px;
  height: 12px;
  color: ${colors.grey.dark4};
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  & button {
    border-radius: 0px 5px 5px 0px;
    padding: 8px 16px;
    &:disabled {
      color: ${colors.white.default};
    }
  }
`;

export default Search;
