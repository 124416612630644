import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ServiceTypeItem } from "src/types";

/**
 * defines a set of callbacks that depend on the useNavigate and makes sure they are wrapped into useCallback
 */
export const useNavigateCallbacks = () => {
  const navigate = useNavigate();

  const onServiceTypeClick = useCallback(
    (serviceType: ServiceTypeItem) => {
      navigate("/workrequests/create", { state: { serviceType } });
    },
    [navigate],
  );

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`/workrequests/${id}`, { state: { ticketNumber: id } });
    },
    [navigate],
  );

  const onAddNewRequestClick = useCallback(() => {
    navigate("/workrequests/create");
  }, [navigate]);

  return {
    onServiceTypeClick,
    onRowClick,
    onAddNewRequestClick,
  };
};
