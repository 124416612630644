import { SelectBox, SelectBoxContainer, SelectedItem } from "src/components/Dropdown/styles";
import { MobileProps } from "src/hooks/useIsMobile";
import { colors } from "src/styles";
import styled, { css } from "styled-components";
export const InputsContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectedItem} {
        font-size: 12px;
      }
    `}
  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBox} {
        width: 200px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      ${InputRow} {
        margin-bottom: 20px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      label {
        font-size: 12px;
        width: 100px;
      }
      input {
        font-size: 12px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBoxContainer} {
        height: 25px;
      }
    `};
`;
export const InputRow = styled.div<MobileProps>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  max-width: 580px;
  width: 100%;
  position: relative;
  & div {
    width: 100%;
  }
  div {
    font-size: 14px;
    p {
      font-style: italic;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }
  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
    padding-top: 10px;
    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 12px;
        width: 100px;
      `}
  }
  input:not([type="checkbox"]):not([type="radio"]) {
    width: 360px;
    ${(props) =>
      props.$isMobile &&
      css`
        width: 200px;
      `};
  }
  div {
    width: 100%;
  }
  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;
export const DateInputRow = styled.div<MobileProps>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  max-width: 580px;
  width: 100%;
  position: relative;
  div {
    font-size: 14px;
    p {
      font-style: italic;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }
  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
    padding-top: 10px;
    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 12px;
        width: 100px;
      `}
  }
  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
  input {
    width: 360px;
    ${(props) =>
      props.$isMobile &&
      css`
        width: 200px;
      `};
  }
`;
export const StyledCheckbox = styled.input`
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  &:checked {
    background-color: #b9e0a5;
    border-color: #b9e0a5;
  }
  &:checked::before {
    content: "✔";
    color: white;
    font-size: 16px;
    position: absolute;
    top: -4px;
    left: 3px;
  }
`;
export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 400 !important;
`;
export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  font-family: Manrope;
`;

export const Label = styled.label<MobileProps>`
  align-self: flex-start;
  margin-right: 12px !important;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-bottom: 10px;
    `}
`;
export const Attachment = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
export const AttachmentData = styled.div`
  margin-left: 8px;
`;
export const Wrapper = styled.div`
  margin-left: 8px;
  & label {
    width: unset;
  }
`;
export const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PhotoUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
