import "react-datetime-picker/dist/DateTimePicker.css";

import * as faIcons from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled, { css } from "styled-components";

import Badge from "src/components/Badge";
import { SelectBoxContainer } from "src/components/Dropdown/styles";
import LoadingContainer from "src/components/LoadingContainer";
import PhotoUpload from "src/components/PhotoUpload";
import { OptionItem } from "src/components/SelectSearch";
import { useIsMobile } from "src/hooks/useIsMobile";
import { Attachments } from "src/pages/TicketForm/Attachments";
import ClientApprovalWindow from "src/pages/TicketForm/ClientApproval";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import { ContentFrame, colors } from "src/styles";
import { MaximoDocument, PriorityLevel, WorkOrder, WorkType, Worklog } from "src/types";
import { parseDateToFormat } from "src/utils/time";

import Button from "src/components/Button";
import BackButton from "src/components/Button/BackButton";
import { AssignWOResourceFormValues } from "src/components/molecules/AssignWOResource/types";
import { ShareFormValues } from "src/components/molecules/ShareForm/types";
import AssignWOResourceModal from "src/components/organisms/AssignWOResourseModal";
import ShareFormModal from "src/components/organisms/ShareFormModal";
import { ActiveEmployee, ActiveVendor } from "src/pages/Tickets/TicketTable";
import ResourseAssigned from "src/pages/Tickets/components/ResourseAssigned";
import { setPageNameHeader } from "src/redux/actions/header";
import { roundTo2Decimals } from "src/utils/helpers";
import { CustomWorkRequestField } from "../CreateForm/types";
import DetailsSection, { CommentRegEx } from "../DetailsSection";
import StatusSelect from "../StatusSelect";
import { ClientApprovalStatuses, FormData, MobileProps, TicketFormProps } from "../types";
import { CustomWorkRequestFieldsViewForm } from "./components/CustomWorkRequestFieldsViewForm";

export const ViewForm = ({}: TicketFormProps) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { isMobile } = useIsMobile();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { id: clientUserId, isManager, firstName, lastName, email } = useSelector(getUserData);

  const defaultServiceType = location?.state?.serviceType;
  const [workTypes, setWorkTypes] = useState<WorkType[]>([]);
  const [error, setError] = useState("");
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [worklog, setWorklog] = useState<Worklog[]>([]);
  const [commentsSectionWorklog, setCommentsSectionWorklog] = useState<Worklog[]>([]);
  const [formData, setFormData] = useState<FormData>({
    ticketName: "",
    description: "",
    serviceType: defaultServiceType?.description || "",
    classification: "",
    priorityName: "",
    priority: 0,
    workType: "",
    location: "",
    targetStart: new Date(),
    targetFinish: new Date(),
  });
  const [clientPhotos, setClientPhotos] = useState<string[]>([]);
  const [technicianPhotos, setTechnicianPhotos] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [statusList, setStatusList] = useState<OptionItem[]>([]);
  const [timezone, setTimezone] = useState("");
  const [priorityLevel, setPriorityLevel] = useState<PriorityLevel>();
  const [documents, setDocuments] = useState<MaximoDocument[]>();
  const [hints, setHints] = useState({ serviceType: "", classification: "" });
  const [addCommentSuccess, setAddCommentSuccess] = useState<boolean>(false);

  const fullWorkType = workTypes?.find((type: WorkType) => type.type === workOrder?.worktype);
  const description = (workOrder?.description_longdescription || "").replace(CommentRegEx, "");
  const addressId = workOrder?.locationData?.label ? workOrder?.locationData?.label : workOrder?.locationData?.address;
  const [clientApprovalStatuses, setClientApprovalStatuses] = useState<ClientApprovalStatuses>();
  const [customWorkRequestFields, setCustomWorkRequestFields] = useState<CustomWorkRequestField[]>();
  const [isShareWorkRequestModalVisible, setIsShareWorkRequestModalVisible] = useState<boolean>(false);
  const [shareFormError, setShareFormError] = useState("");
  const [employees, setEmployees] = useState<ActiveEmployee[]>([]);
  const [vendors, setVendors] = useState<ActiveVendor[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assignResourceErrorOnSubmit, setAssignResourceErrorOnSubmit] = useState("");

  const getClientApprovalStatuses = async () => {
    try {
      const { success, data } = await FirebaseDataService.getClientApproveStatuses();
      if (success) setClientApprovalStatuses(data);
    } catch (error) {
      // this error is ignored because Work Requests should work fine even when approve statuses is absent at the DB
    }
  };

  const parsedCreatedDate = useMemo(
    () => parseDateToFormat(workOrder?.reportdate as string, "MMMM Do YYYY, h:mm A"),
    [workOrder],
  );

  const setServiceType = () => {
    let serviceType = workOrder?.classstructure?.[0].classstructure?.[0].description;
    let classification = workOrder?.classstructure?.[0]?.description;
    const serviceTypeHint = workOrder?.classstructure?.[0].classstructure?.[0].classificationdesc_longdescription;
    const classificationHint = workOrder?.classstructure?.[0]?.classificationdesc_longdescription;

    if (workOrder?.classstructure && !workOrder.classstructure[0].classstructure) {
      serviceType = workOrder?.classstructure?.[0]?.description;
      classification = "";
    }

    setFormData((data) => ({
      ...data,
      serviceType: serviceType as string,
      classification: classification as string,
    }));

    setHints({
      serviceType: serviceTypeHint as string,
      classification: classificationHint as string,
    });
  };

  const fetchData = async () => {
    setIsFormLoading(true);

    const { data: viewTicketData } = await FirebaseDataService.getDataForTicketViewForm(
      id as string,
      clientUserId,
      isManager,
    );
    //at the moment, when clicking on a row in the manager portal, this redirect works, since we receive empty data
    if (!viewTicketData) {
      navigate("/workrequests");
    }

    if (viewTicketData?.locationTimezone) {
      setTimezone(viewTicketData.locationTimezone);
    }

    const clientPhotos = viewTicketData?.attachments.clientPhotos.map((photo) => {
      const query = [
        `workorderid=${String(viewTicketData?.workOrder?.workorderid)}`,
        `attachmentId=${photo.attachmentId}`,
        `fileName=${encodeURI(photo.fileName)}`,
      ].join("&");
      return `${process.env.REACT_APP_FIREBASE_BASE_URL}/clientPortal-getMaximoAttachment?${query}`;
    });

    const technicianPhotos = viewTicketData?.attachments.technicianPhotos.map((photo) => {
      const query = [
        `workorderid=${String(viewTicketData?.workOrder?.workorderid)}`,
        `attachmentId=${photo.attachmentId}`,
        `fileName=${encodeURI(photo.fileName)}`,
      ].join("&");
      return `${process.env.REACT_APP_FIREBASE_BASE_URL}/clientPortal-getMaximoAttachment?${query}`;
    });

    await getClientApprovalStatuses();

    setWorkOrder(viewTicketData?.workOrder);
    setWorkTypes(viewTicketData?.workTypes);
    setClientPhotos(clientPhotos);
    setTechnicianPhotos(technicianPhotos);
    setWorklog(viewTicketData?.technicianNotesWorklog);
    setCommentsSectionWorklog(viewTicketData?.commentsSectionWorklog);
    setPriorityLevel(viewTicketData?.priorityLevel);
    setDocuments(viewTicketData?.attachments.documents);

    setStatusList(viewTicketData?.statusList);
    setCustomWorkRequestFields(viewTicketData?.customWorkRequestFields);

    setEmployees(viewTicketData.employees);
    setVendors(viewTicketData.vendors);
    setIsFormLoading(false);
  };

  const onExitClick = () => {
    if (location?.state?.ticketNumber) {
      navigate(-1);
    } else {
      navigate("/workrequests");
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    setServiceType();
  }, [workOrder]);

  useEffect(() => {
    dispatch(setPageNameHeader("Work Request Details"));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, []);
  const shareWorkRequest = async (value: ShareFormValues) => {
    try {
      if (workOrder) {
        await FirebaseDataService.shareWorkRequest(
          value.emailTo,
          value.emailMessage,
          workOrder?.workorderid,
          `${firstName} ${lastName}`,
          workOrder.wonum,
          email ?? "",
        );
        setIsShareWorkRequestModalVisible(false);
      }
    } catch (err) {
      setShareFormError(err.message);
    }
  };
  const onSubmitAssignResourceHandler = async (data: AssignWOResourceFormValues) => {
    if (!workOrder) {
      return;
    }
    setAssignResourceErrorOnSubmit("");
    const res = await FirebaseDataService.assignResourceInWorkOrder({
      wonum: workOrder.wonum,
      approvedamount: data.vendors.approvedamount,
      vendorId: data.vendors.vendorId,
      woacceptscharges: data.vendors.woacceptscharges,
      managerId: clientUserId,
      laborCode: data.employees.laborCode,
    });

    if (res && res.status === "error") {
      setAssignResourceErrorOnSubmit(res.message);
    } else {
      await fetchData();
      setIsModalVisible(false);
    }
  };
  const getApprovedAmount = (workOrder: WorkOrder) => {
    if (workOrder.woacceptscharges) {
      const service = workOrder.wpservice?.find((service) => service.vendor);
      return service?.linecost ? `$ ${roundTo2Decimals(service.linecost)}` : "Post-completion";
    } else {
      return "Non-billable";
    }
  };
  return (
    <LoadingContainer isLoading={isFormLoading}>
      {isModalVisible && workOrder && (
        <AssignWOResourceModal
          closeModal={() => {
            setAssignResourceErrorOnSubmit("");
            setIsModalVisible(false);
          }}
          errorMsg={assignResourceErrorOnSubmit}
          onSubmit={onSubmitAssignResourceHandler}
          employees={employees}
          vendors={vendors.filter(
            (vendor) => vendor.clients && vendor.clients.includes(workOrder?.pluspcustomer?.customer ?? ""),
          )}
          defaultValues={{
            employees: {
              laborCode: workOrder?.wplabor?.find((labor) => labor.laborcode)?.laborcode,
            },
            vendors: {
              woacceptscharges: workOrder?.woacceptscharges ?? false,
              approvedamount: workOrder?.wpservice?.find((service) => service.vendor)?.linecost,
              vendorId: workOrder?.wpservice?.find((service) => service.vendor)?.vendor,
            },
            // commented out temporarily to hide the Employees tab. Will be reverted in the future
            activetab: workOrder?.wpservice?.find((service) => service.vendor)?.vendor ? "vendors" : "employees",
            // activetab: "vendors",
          }}
        />
      )}
      <Container>
        <Content>
          <Stack
            direction="horizontal"
            className="align-items-center justify-content-between"
            style={{ marginBottom: 30 }}
          >
            <BackButton backHandler={onExitClick} />
            <Button
              text="Share"
              variant="white"
              additionalStyles={{ display: "flex" }}
              icon={faIcons.faShare as any}
              onClick={() => {
                setIsShareWorkRequestModalVisible(true);
              }}
            />
          </Stack>
          <ContentFrame>
            {isShareWorkRequestModalVisible && (
              <ShareFormModal
                closeModal={() => {
                  setIsShareWorkRequestModalVisible(false);
                }}
                error={shareFormError}
                onSubmit={shareWorkRequest}
              />
            )}
            {workOrder &&
              clientApprovalStatuses &&
              workOrder.status === clientApprovalStatuses.approvalRequired.abbreviation && (
                <ClientApprovalWindow
                  workOrder={workOrder}
                  documents={documents}
                  setError={setError}
                  clientApprovalStatuses={clientApprovalStatuses}
                  setWorkOrder={setWorkOrder}
                  setAddCommentSuccess={setAddCommentSuccess}
                />
              )}
            <StyledForm>
              <InputsContainer $isMobile={isMobile}>
                <InputsRow>
                  <InputRow $isMobile={isMobile} ismanager={isManager ? 1 : 0}>
                    <label>Work Request Name:</label>
                    <RawValue>
                      {workOrder?.wonum}: {workOrder?.description}
                    </RawValue>
                  </InputRow>
                  {!isManager && (
                    <InputRow>
                      <label>Location: </label>
                      <RawValue>{addressId || "N/A"}</RawValue>
                    </InputRow>
                  )}
                </InputsRow>

                {workOrder?.parentOrder && (
                  <InputRow $isMobile={isMobile} ismanager={1}>
                    <label>Parent Work Request:</label>
                    <div>
                      <a href={`/workrequests/${workOrder.parentOrder.wonum}`}>
                        {workOrder.parentOrder.wonum}: {workOrder.parentOrder.description}
                      </a>
                    </div>
                  </InputRow>
                )}
                {workOrder?.wochildren && workOrder?.wochildren.length && (
                  <InputRow $isMobile={isMobile} $topLabel>
                    <label>Child Work Requests:</label>
                    <div>
                      {workOrder?.wochildren.map((child: WorkOrder) => (
                        <ChildWorkRequestRow key={child.displaywonum}>
                          <a href={`/workrequests/${child.displaywonum}`}>
                            {child.displaywonum}: {child.description}
                          </a>
                        </ChildWorkRequestRow>
                      ))}
                    </div>
                  </InputRow>
                )}
                {isManager && (
                  <InputsRow>
                    <InputRow>
                      <label>Client:</label>
                      <RawValue>{workOrder?.pluspcustomer?.name}</RawValue>
                    </InputRow>
                    <InputRow>
                      <label>Location: </label>
                      <RawValue>{addressId || "N/A"}</RawValue>
                    </InputRow>
                  </InputsRow>
                )}
                <InputsRow>
                  <InputRow $isMobile={isMobile}>
                    <label>Creation Time:</label>
                    <RawValue>{parsedCreatedDate}</RawValue>
                  </InputRow>
                  <InputRow $isMobile={isMobile}>
                    <label>Status:</label>
                    {isManager && workOrder ? (
                      <StatusSelect workOrder={workOrder} statusList={statusList} />
                    ) : (
                      <Badge status={workOrder?.status_description as string} />
                    )}
                  </InputRow>
                </InputsRow>

                <InputsRow>
                  <InputRow $isMobile={isMobile}>
                    <label>Service Type:</label>
                    <RawValue>{formData.serviceType || "N/A"}</RawValue>

                    <HintIcon
                      style={{
                        visibility: defaultServiceType?.longDescription || hints.serviceType ? "visible" : "hidden",
                      }}
                      title="Edit Mode"
                      data-tip={
                        defaultServiceType?.longDescription || hints.serviceType
                          ? (defaultServiceType?.longDescription || hints.serviceType).replace(/<br ?\/?>/g, "\n")
                          : ""
                      }
                      icon={(faIcons as any)["faQuestionCircle"]}
                    />
                  </InputRow>

                  <InputRow>
                    <label>Classification:</label>
                    <RawValue>{formData.classification || "N/A"} </RawValue>

                    <HintIcon
                      style={{
                        visibility: hints.classification ? "visible" : "hidden",
                      }}
                      data-tip={`<div style="overflow: hidden">${(hints.classification || "").replace(
                        /<br ?\/?>/g,
                        "\n",
                      )}</div>`}
                      icon={(faIcons as any)["faQuestionCircle"]}
                    />
                  </InputRow>

                  <ReactTooltip className="tooltip" />
                </InputsRow>

                <InputsRow>
                  <InputRow>
                    <label>Work Type:</label>
                    <RawValue>{(fullWorkType && fullWorkType["name"]) || "N/A"} </RawValue>
                  </InputRow>

                  <InputRow>
                    <label>Priority Level:</label>
                    <RawValue color={priorityLevel ? priorityLevel.color : colors.grey.default}>
                      {workOrder?.wopriority_description || "N/A"}
                    </RawValue>
                  </InputRow>
                </InputsRow>

                <InputsRow>
                  <InputRow $isMobile={isMobile}>
                    <label>Requestor First Name:</label>
                    <RawValue>{workOrder?.firstname || "N/A"}</RawValue>
                  </InputRow>
                  <InputRow $isMobile={isMobile}>
                    <label>Requestor Email:</label>
                    <RawValue>{workOrder?.email || "N/A"}</RawValue>
                  </InputRow>
                </InputsRow>
                <InputsRow>
                  <InputRow $isMobile={isMobile}>
                    <label>Requestor Last Name:</label>
                    <RawValue>{workOrder?.lastname || "N/A"}</RawValue>
                  </InputRow>
                  <InputRow $isMobile={isMobile}>
                    <label>Requestor Phone No:</label>
                    <RawValue>{workOrder?.phonenumber || "N/A"}</RawValue>
                  </InputRow>
                </InputsRow>
              </InputsContainer>

              {isManager && workOrder && (
                <InputsContainer>
                  <InputsRow>
                    <InputRow $isMobile={isMobile}>
                      <label>Visible to Client? :</label>
                      <RawValue>{workOrder?.isvisibletoclient ? "Yes" : "No"}</RawValue>
                    </InputRow>
                    <InputRow $isMobile={isMobile}>
                      <label style={{ marginRight: 5 }}>Resource (optional) :</label>
                      <ResourseAssigned
                        employees={employees}
                        vendors={vendors}
                        onClick={() => setIsModalVisible(true)}
                        laborCode={workOrder?.wplabor?.find((labor) => labor.laborcode)?.laborcode}
                        vendorId={workOrder?.wpservice?.find((service) => service.vendor)?.vendor}
                      />
                    </InputRow>
                  </InputsRow>
                  <InputsRow>
                    <InputRow $isMobile={isMobile} />
                    <InputRow $isMobile={isMobile}>
                      <label>Approved Amount:</label>
                      <RawValue>{getApprovedAmount(workOrder)}</RawValue>
                    </InputRow>
                  </InputsRow>
                </InputsContainer>
              )}

              {customWorkRequestFields && (
                <CustomWorkRequestFieldsViewForm
                  $isMobile={isMobile}
                  isClient={!isManager}
                  customWorkRequestFields={customWorkRequestFields}
                  workOrder={workOrder}
                />
              )}
              <DescriptionContainer $isMobile={isMobile}>
                <label>Description: </label>

                <DescriptionContainer $isMobile={isMobile}>
                  <ReactQuill
                    readOnly
                    theme="snow"
                    value={description || "N/A"}
                    style={{ width: "calc(100% - 0px)" }}
                  />
                </DescriptionContainer>
              </DescriptionContainer>

              {clientPhotos?.length === 0 ? null : (
                <InputsRow>
                  <PhotoInput $isMobile={isMobile}>
                    <label>
                      Photos<span>(optional):</span>
                    </label>
                    <PhotoUpload
                      files={files}
                      isLoading={false}
                      images={clientPhotos}
                      setFiles={setFiles}
                      isViewMode
                    ></PhotoUpload>
                  </PhotoInput>
                </InputsRow>
              )}

              <AttachmentRow>
                <Attachments
                  documents={documents}
                  workOrderHref={workOrder?.href}
                  workOrderId={workOrder?.workorderid}
                />
              </AttachmentRow>

              <ErrorMessage>{error && error}</ErrorMessage>
            </StyledForm>
            {workOrder && (
              <Details>
                <DetailsSection
                  technicianPhotos={technicianPhotos}
                  $isMobile={isMobile}
                  workOrder={workOrder as WorkOrder}
                  workOrderWorklog={worklog}
                  isViewMode
                  areImagesLoading={false}
                  timezone={timezone}
                  commentsSectionWorklog={commentsSectionWorklog}
                  addCommentSuccess={addCommentSuccess}
                  setAddCommentSuccess={setAddCommentSuccess}
                />
              </Details>
            )}
          </ContentFrame>
        </Content>
      </Container>
    </LoadingContainer>
  );
};

export const PhotoInput = styled.div<MobileProps>`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  width: 100%;
  border-bottom: 1px solid ${colors.grey.light12};
  border-top: 1px solid ${colors.grey.light12};
  padding: 30px 0;

  label {
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 195px;

    color: ${colors.black.light2};
    white-space: nowrap;
    margin-right: 20px;
    display: flex;
    align-items: baseline;

    span {
      display: block;
      font-size: 14px;
      font-weight: 400;
      padding-left: 5px;
    }

    ${(props) => props.$isMobile && `width: 100px;`}
  }
`;

const Details = styled.div`
  padding: 30px 0;
`;

const StyledForm = styled(Form)`
  padding-top: 20px;
`;

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`;

const Content = styled.div`
  margin: 30px;
  background-color: ${colors.white.default};
`;

const ErrorMessage = styled.p`
  color: ${colors.red.default};
  font-size: 14px;
  text-align: center;
`;

interface InputRowProps extends MobileProps {
  $marginBottom?: string;
  isSelectSearch?: boolean;
  ismanager?: number;
}

export const InputRow = styled.div<InputRowProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ $marginBottom }) => `${$marginBottom === undefined ? "40" : $marginBottom}px`};
  max-width: ${({ ismanager }) => (ismanager ? "unset" : "600px")};
  width: 100%;
  position: relative;
  margin-right: 40px;

  & div {
    width: ${({ ismanager }) => ismanager && "100%"};
  }

  ${(props) =>
    props.$topLabel &&
    css`
      align-items: flex-start;
    `}

  div {
    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 14px;
      `}
    p {
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
  }

  input {
    width: ${({ ismanager }) => (ismanager ? "100%" : "360px")};
    ${(props) =>
      !props.isSelectSearch &&
      css`
        padding: 0px 7px;
      `}

    ${(props) =>
      props.$isMobile &&
      css`
        width: 200px;
      `}
  }

  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;

export const RawValue = styled.p<{ color?: string }>`
  border-bottom: 1px solid ${colors.grey.light4};
  width: 100%;
  font-size: 14px;
  padding-bottom: 4px;
  color: ${({ color }) => (color ? color : colors.grey.dark)};
`;

export const InputsRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tooltip {
    max-width: 70%;
  }
`;

const HintIcon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  height: 20px;
  width: 20px;
  position: absolute;
  right: -25px;

  @media (max-width: 480px) {
    right: -5%;
  }
`;

const InputsContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$isMobile &&
    css`
      ${InputRow} {
        margin-bottom: 20px;
      }
    `}

  ${(props) =>
    props.$isMobile &&
    css`
      label {
        width: 100px;
      }

      input {
        width: 100%;
      }
    `}

  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBoxContainer} {
        height: 25px;
      }
    `}
`;

export const DescriptionContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: ${({ $isComments }) => ($isComments ? "column" : "row")};
  margin-bottom: 20px;
  width: 100%;

  .ql-toolbar.ql-snow {
    display: none;
    border: none;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    border: 1px solid ${colors.grey.light13};
    padding: 10px;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    flex: 0 0 200px;
    margin-right: 20px;
    color: ${colors.black.light2};
    margin-bottom: ${({ $isComments }) => ($isComments ? "10px" : "0px")};

    ${(props) =>
      props.$isMobile &&
      css`
        label {
          font-size: 12px;
        }
      `};
  }

  ${(props) =>
    props.$isMobile &&
    css`
      label {
        font-size: 12px;
      }
    `}
`;

const AttachmentRow = styled.div`
  ${InputRow} {
    align-items: flex-start;
  }
`;

const ChildWorkRequestRow = styled.div`
  margin-bottom: 8px;
`;
