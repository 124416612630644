import { createReducer } from "@reduxjs/toolkit";
import { clearWorkRequestMessage, setWorkRequestAlertVariant, setWorkRequestMessage } from "../actions/workRequest";

type WorkRequestState = {
  workRequestMessage: string;
  workRequestMessageVariant: "success" | "danger";
};

const initialState: WorkRequestState = {
  workRequestMessage: "",
  workRequestMessageVariant: "success",
};

export const workRequestReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setWorkRequestMessage, (state, action) => {
      state.workRequestMessage = action.payload;
    })
    .addCase(clearWorkRequestMessage, (state) => {
      state.workRequestMessage = "";
    })
    .addCase(setWorkRequestAlertVariant, (state, action) => {
      state.workRequestMessageVariant = action.payload === "danger" ? "danger" : "success";
    });
});
