export const tableHeaders = [
  "No.",
  "Date Created",
  "Location",
  "Work Request Name",
  "Service Type",
  "Classification",
  "Work type",
  "Priority Level",
  "Status",
];

export const managerTableHeaders = [
  "No.",
  "Date Created",
  "Client",
  "Location",
  "Work Request Name",
  "Service Type",
  "Priority Level",
  "Status",
  "Resource",
];

export const vendorTableHeaders = [
  "No.",
  "Date Created",
  "Client",
  "Location",
  "Work Request Name",
  "Service Type",
  "Priority Level",
  "Approved Amount",
  "Actions",
];

export const sortingDirections = [
  {
    value: "Sorting Order: Descending",
    id: 1,
    filter: ["desc"],
  },
  {
    value: "Sorting Order: Ascending",
    id: 2,
    filter: ["asc"],
  },
];

export const sortingOptions = [
  {
    value: "Sort By: Date Created",
    id: 1,
    filter: [["dateCreated", "desc"]],
    onlyManager: false,
  },
  { value: "Sort By: No.", id: 2, filter: [["id", "desc"]], onlyManager: false },
  { value: "Sort By: Client", id: 3, filter: [["client.raw", "asc"]], onlyManager: true },
  { value: "Sort By: Work Request Name", id: 4, filter: [["description.raw", "asc"]], onlyManager: false },
  {
    value: "Sort By: Priority Level",
    id: 8,
    filter: [
      ["priorityId", "asc"],
      ["dateCreated", "desc"],
    ],
    onlyManager: false,
  },
];

export const MANAGER_WORKREQUESTS_BREADCRUMBS = [
  { id: "/", name: "Home" },
  { id: "/workrequests", name: "Work Requests" },
];

export const COUNT_OF_SERVICE_TYPE = 5;

export const WORK_REQUEST_TYPE = {
  OPEN: "OPEN",
  COMPLETED: "COMPLETED",
  PLANNED: "PLANNED",
  APPROVAL_REQUIRED: "APPROVAL_REQUIRED",
};
