import React, { useCallback } from "react";
import { Tile } from "src/components/Tile";
import { ServiceTypeTileProps } from "../types";

/**
 * A helper component to make sure the click handler is wrapped in useCallback and simplify the parent component code
 */
export const ServiceTypeTile = ({ onClick, serviceType }: ServiceTypeTileProps) => {
  const onClickHandler = useCallback(() => onClick(serviceType), [serviceType]);

  return (
    <Tile
      title={serviceType?.description as string}
      icon={serviceType.icon || "faClipboardListCheck"}
      onClick={onClickHandler}
      hint={serviceType.longDescription}
      hintIcon="faQuestionCircle"
    />
  );
};
