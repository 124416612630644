import "react-datetime-picker/dist/DateTimePicker.css";

import * as faIcons from "@fortawesome/pro-light-svg-icons";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Button from "src/components/Button";
import Checkbox from "src/components/Checkbox";
import { DropdownItem } from "src/components/Dropdown/types";
import LoadingContainer from "src/components/LoadingContainer";
import Modal from "src/components/Modal";
import SelectSearch from "src/components/SelectSearch";
import { MAXIMO_TIME_ZONE } from "src/config/сonstants";
import { useIsMobile } from "src/hooks/useIsMobile";
import { useModal } from "src/hooks/useModal";
import { setWorkRequestMessage } from "src/redux/actions/workRequest";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import { ContentFrame, colors } from "src/styles";
import {
  Client,
  DBLocation,
  IServiceTypeQuestion,
  MaximoDocument,
  PriorityLevel,
  PriorityLevelList,
  ServiceTypeItem,
  ServiceTypeList,
  WorkType,
} from "src/types";
import { getBase64File } from "src/utils/convertToBase64";
import { debugError } from "src/utils/log";
import { objToArray } from "src/utils/objToArray";

import { useForm } from "react-hook-form";
import BackButton from "src/components/Button/BackButton";
import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import { AssignWOResourceFormValues } from "src/components/molecules/AssignWOResource/types";
import AssignWOResourceModal from "src/components/organisms/AssignWOResourseModal";
import ResourseAssigned from "src/pages/Tickets/components/ResourseAssigned";
import { setPageNameHeader } from "src/redux/actions/header";
import { getAllActiveResources } from "src/redux/selectors/resources";
import DateRow from "../DateRow";
import DropdownRow from "../DropdownRow";
import ServiceTypeQuestion from "../ServiceTypeQuestion";
import { UploadWorkOrderAttachments } from "../UploadWorkOrderAttachments";
import { CustomWorkRequestFieldsForm } from "./components/CustomWorkRequestFieldsForm";
import { EmailToForm } from "./components/EmailToForm";
import { IsClientVisbleCheckBoxForm } from "./components/IsClientVisbleCheckBoxForm";
import {
  ButtonContainer,
  Container,
  Content,
  DescriptionContainer,
  ErrorMessage,
  HintIcon,
  InputRow,
  InputsContainer,
  PhotoInput,
  StyledForm,
  SubmitContainer,
} from "./styles";
import { CustomWorkRequestFieldList, FormData, TicketFormProps } from "./types";

export const CreateForm = ({ setStep, noAuthMode, noAuthData, setSuccessMessage }: TicketFormProps) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { isMobile } = useIsMobile();
  const { id } = useParams();
  const dispatch = useDispatch();

  const form = useForm();

  const {
    id: clientUserId,
    firstName,
    lastName,
    phoneNumber,
    email,
    clientId: clientPortalId,
    maximoTimezone: maximoTimezoneDef,
    isManager,
  } = useSelector(getUserData);
  const maximoTimezone = maximoTimezoneDef || MAXIMO_TIME_ZONE;
  const defaultServiceType = location?.state?.serviceType;
  const [workTypes, setWorkTypes] = useState<WorkType[]>([]);
  const [priorityLevels, setPriorityLevels] = useState<PriorityLevelList>();
  const [locations, setLocations] = useState<DBLocation[]>([]);
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeList>();
  const [clients, setClients] = useState<Client[]>([]);
  const [serviceTypeId, setServiceTypeId] = useState(defaultServiceType?.id);
  const [serviceTypeQuestions, setServiceTypeQuestions] = useState<IServiceTypeQuestion[]>([]);
  const { isShowing, toggle } = useModal();
  const [isVerifyChecked, setIsVerifyChecked] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isVerifyMode, setIsVerifyMode] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    ticketName: "",
    description: "",
    serviceType: defaultServiceType?.description || "",
    classification: "",
    priorityName: "",
    priority: 0,
    workType: "",
    location: "",
    targetStart: new Date(),
    targetFinish: new Date(),
  });
  const [files, setFiles] = useState<MaximoDocument[]>([]);
  const [classificationId, setClassificationId] = useState("");
  const [timezone, setTimezone] = useState("");
  const [clientId, setClientId] = useState(clientPortalId || "");
  const [customWorkrequestFields, setCustomWorkRequestFields] = useState<CustomWorkRequestFieldList>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assignResourceValues, setAssignResourceValues] = useState<AssignWOResourceFormValues>();
  const activeResources = useSelector(getAllActiveResources);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { targetFinish, targetStart, ...restFormData } = formData;
  const dropdownLocations = useMemo(() => {
    const newLocations = locations.map((location: any) => {
      return {
        id: location.id,
        value: location.label || location.address,
        timezone: location?.timezone,
        clientId: location.clientId,
      };
    });

    return isManager ? newLocations.filter((item) => item.clientId === clientId) : newLocations;
  }, [locations, clientId]);

  const dropdownWorkTypes = useMemo(
    () =>
      workTypes.map((workType: WorkType) => {
        return {
          id: workType.id,
          value: workType.name,
          type: workType.type,
        };
      }),
    [workTypes],
  );

  const dropdownServiceTypes = useMemo(() => {
    const serviceTypesManager = isManager && clientId ? serviceTypes?.[clientId] : [];

    const dataSource = (isManager ? serviceTypesManager : noAuthData?.serviceTypes.serviceTypes) || [];

    return (dataSource as any)
      ?.filter(
        (serviceType: ServiceTypeItem) =>
          serviceType.showOnPublic !== false &&
          Object.values(serviceType?.classifications || {}).some(
            (classification: any) => classification?.showOnPublic !== false,
          ),
      )
      .map((serviceType: ServiceTypeItem) => {
        return {
          id: serviceType.id,
          value: serviceType.description,
          longDescription: serviceType.longDescription,
        };
      });
  }, [workTypes, serviceTypes, clientId]);

  const dropdownClassifications = useMemo(() => {
    const managerClassifications = isManager
      ? serviceTypes?.[clientId]?.find((serviceType) => serviceType.id === serviceTypeId)
      : defaultServiceType;

    const targetServiceType = noAuthMode
      ? (noAuthData?.serviceTypes?.serviceTypes as any)?.find((serviceType: any) => serviceType.id === serviceTypeId)
      : managerClassifications;

    const classifications = objToArray(targetServiceType?.classifications, (item: any, id: string) => ({
      ...item,
      id,
    }));

    const filteredClassifications = noAuthMode
      ? classifications.filter((item: any) => item.showOnPublic !== false)
      : classifications;

    return filteredClassifications.map((classification) => {
      return {
        id: classification.name,
        value: classification.description,
        hierarchypath: classification.hierarchypath,
        classstructureid: classification.classstructureid,
        longDescription: classification.longDescription,
      };
    });
  }, [defaultServiceType, serviceTypeId, clientId]);

  const dropdownPriorityLevels = useMemo(() => {
    const dataSource = noAuthMode ? priorityLevels : priorityLevels?.[clientId];

    return (dataSource as any)
      ?.map((priorityLevel: PriorityLevel) => {
        const pickedPriorityLevel = priorityLevels?.[clientId]?.find(
          ({ priority }) => priority === priorityLevel.priority,
        );
        return {
          id: priorityLevel.name,
          value: priorityLevel.name,
          priority: priorityLevel.priority,
          color: pickedPriorityLevel ? pickedPriorityLevel.color : colors.grey.default,
          hoursDelay: priorityLevel.hoursDelay,
        };
      })
      .sort((a: PriorityLevel, b: PriorityLevel) => a.priority - b.priority);
  }, [priorityLevels, clientId]);

  const buttonDisabledConditions = useMemo(() => {
    if (!Object.values(formData).every((item) => item)) {
      return true;
    }

    if (formData.serviceTypeQuestions) {
      return !serviceTypeQuestions.every(
        (el: any) =>
          el.rules?.required === false ||
          (Array.isArray(formData.serviceTypeQuestions[el.id])
            ? formData.serviceTypeQuestions[el.id].length
            : formData.serviceTypeQuestions[el.id]),
      );
    }

    return false;
  }, [formData]);

  const fetchData = async () => {
    setIsFormLoading(true);
    if (noAuthMode && noAuthData) {
      setPriorityLevels(noAuthData?.priorityLevels);
      setWorkTypes(noAuthData?.workTypes);
      setLocations(noAuthData?.locations);
      setCustomWorkRequestFields(noAuthData.customWorkRequestFields);
    }

    if (!noAuthMode) {
      const { data: createTicketData } = await FirebaseDataService.getDataForTicketCreateForm(
        id as string,
        clientUserId,
        isManager,
      );

      const formattedClients = createTicketData.clients.map((client) => {
        return {
          ...client,
          id: client.id,
          value: client.name,
        };
      });

      setPriorityLevels(createTicketData.priorityLevels || {});
      setWorkTypes(createTicketData.workTypes);
      setLocations(createTicketData.locations);
      setServiceTypes(createTicketData.serviceTypes);
      setClients(formattedClients);
      setCustomWorkRequestFields(createTicketData.customWorkRequestFields);
    }

    setIsFormLoading(false);
  };

  const onDiscardClick = () => {
    navigate("/workrequests");
  };

  const getServiceTypeQuestions = async () => {
    try {
      const { data, status, message } = await FirebaseDataService.getServiceTypeQuestions(clientId, serviceTypeId);

      if (status === "error") {
        throw new Error(message);
      }

      const additionalFormData = data.reduce((acc: { [key: string]: string }, item: IServiceTypeQuestion) => {
        return {
          ...acc,
          [item.id]: "",
        };
      }, {});

      setFormData((old) => ({ ...old, serviceTypeQuestions: { ...additionalFormData } }));
      setServiceTypeQuestions(data);
    } catch (error) {
      debugError(error);
      setError(error.message);
    }
  };

  const onSubmit = async (e: React.MouseEvent) => {
    setIsLoading(true);
    e.preventDefault();

    const currentClassifications = noAuthMode
      ? (noAuthData?.serviceTypes?.serviceTypes as any)?.find((serviceType: any) => serviceType.id === serviceTypeId)
          ?.name
      : serviceTypes?.[clientId]?.find((serviceType) => serviceType.id === serviceTypeId)?.name;

    const classification = dropdownClassifications?.find(
      (classification) =>
        classification?.hierarchypath ===
        `${defaultServiceType?.name || currentClassifications} \\ ${classificationId}`,
    );

    const additionalInfo =
      !noAuthMode && !isManager
        ? {
            deviceTimezone: moment.tz.guess(),
            locationTimezone: timezone,
          }
        : {
            firstname: firstName,
            lastname: lastName,
            phonenumber: phoneNumber,
            deviceTimezone: moment.tz.guess(),
            locationTimezone: timezone,
            email,
            clientId,
          };

    const targetStart = moment(formData.targetStart).tz(maximoTimezone, true).toISOString();
    const targetFinish = moment(formData.targetFinish).tz(maximoTimezone, true).toISOString();

    if (serviceTypeQuestions.length) {
      formData.description = `${formData.description} ${serviceTypeQuestions
        .map((question) => {
          const questionResult = formData.serviceTypeQuestions[question.id];
          return `<br/><br/>${question.label}<br/>${
            questionResult.dateValue
              ? moment(questionResult.dateValue).format(
                  `${questionResult.date ? "YYYY-MM-DD " : ""}${questionResult.time ? "hh:mm:ss a" : ""}`,
                )
              : Array.isArray(questionResult)
                ? questionResult.join("; ")
                : questionResult || "-"
          }`;
        })
        .join("")}<!-- RICH TEXT -->`;
    }

    const workOrder = {
      siteid: "KLEENWAY",
      description: formData.ticketName.trim(),
      location: formData.location,
      client: clients.find((client) => client.id === clientId),
      worktype: formData.workType,
      wopriority: formData.priority,
      targstartdate: targetStart,
      targcompdate: targetFinish,
      description_longdescription: formData.description,
      classstructureid: classification?.classstructureid,
      ...additionalInfo,
      ...form.getValues(),
      isManager: isManager,
    };
    const { data, message } = await FirebaseDataService.createMaximoWorkOrder(
      workOrder,
      clientUserId,
      assignResourceValues,
    );
    if (data) {
      await uploadImages(String(data.workorderid));

      if (!noAuthMode) {
        dispatch(setWorkRequestMessage(data.message));

        navigate("/workrequests");
      } else {
        setStep?.((old: number) => old + 1);
        setSuccessMessage?.(data.message);
      }
    }

    if (!data) {
      setError(message);
    }

    setIsLoading(false);
  };

  const onConfirmClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (await form.trigger()) {
      setIsVerifyMode(true);
    }
  };

  const onPriorityLevelChange = (item: DropdownItem) => {
    setFormData((data) => ({
      ...data,
      priorityName: item.value,
      priority: item.priority,
      hoursDelay: item.hoursDelay,
    }));
  };

  const uploadImages = async (workOrderId: string) => {
    if (!files?.length) return;
    if (workOrderId) {
      for (const item of files) {
        const file = item.file;
        if (file) {
          const folderName = file.type.includes("image") ? "Images" : "Attachments";

          const convertedFile = (await getBase64File(file)) as string;
          const fileToUpload = convertedFile?.slice(convertedFile.indexOf(",") + 1);
          await FirebaseDataService.addMaximoWorkOrderAttachment(
            fileToUpload,
            workOrderId,
            file.name,
            file.type,
            folderName,
          );
        }
      }
    }
  };

  const onExitClick = () => {
    toggle();
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  const getTimeZone = () => {
    const clientTimeZone = clients.find((client) => client.id === clientId)?.settings?.timezone;
    const locationTimeZone = locations.find((location: any) => location.id === formData.location)?.timezone;
    if (locationTimeZone) {
      return locationTimeZone;
    } else if (clientTimeZone) {
      return clientTimeZone;
    } else {
      return maximoTimezone;
    }
  };
  useEffect(() => {
    setTimezone(getTimeZone());
  }, [clientId, formData.location]);
  useEffect(() => {
    // set target start and taget finish for create new ticket
    if (isVerifyMode) {
      const date = moment(new Date());
      const tZone = getTimeZone();
      if (tZone) {
        date.tz(tZone);
      }

      const finishDate = moment(date).add(formData.hoursDelay, "hours");

      setFormData((data) => ({
        ...data,
        targetStart: date,
        targetFinish: finishDate,
      }));
    }
  }, [isVerifyMode]);

  useEffect(() => {
    serviceTypeId && getServiceTypeQuestions();
  }, [serviceTypeId]);

  useEffect(() => {
    if (!priorityLevels) return;
    const dataSource = isManager ? priorityLevels[clientId] : priorityLevels;

    if (dataSource?.length === 1) {
      setFormData((data) => ({
        ...data,
        priorityName: (dataSource as any)[0].name,
        priority: (dataSource as any)[0].priority,
        hoursDelay: (dataSource as any)[0].hoursDelay,
      }));
    }
  }, [priorityLevels]);

  useEffect(() => {
    if (isManager) {
      setFormData((data) => ({
        ...data,
        location: "",
        serviceType: "",
        priority: 0,
        priorityName: "",
        classification: "",
      }));
      setServiceTypeId("");
      setServiceTypeQuestions([]);
    }
  }, [clientId]);

  useEffect(() => {
    dispatch(setPageNameHeader("Create New Work Request"));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, []);

  const onSubmitAssignResourceHandler = (data: AssignWOResourceFormValues) => {
    setAssignResourceValues({
      activetab: data.activetab,
      employees: {
        laborCode: data.employees.laborCode,
      },
      vendors: {
        approvedamount: data.vendors.approvedamount,
        vendorId: data.vendors.vendorId,
        woacceptscharges: data.vendors.woacceptscharges,
      },
    });

    setIsModalVisible(false);
  };
  return (
    <LoadingContainer isLoading={isFormLoading}>
      <Modal
        onApproveClick={onDiscardClick}
        onCancelClick={() => toggle()}
        title="Discard New Work Request"
        textContent="Are you sure you want to discard this Work Request? All entered information will be lost."
        isShowing={isShowing}
        hide={toggle}
      />
      {isModalVisible && (
        <AssignWOResourceModal
          closeModal={() => {
            setIsModalVisible(false);
          }}
          errorMsg={""}
          onSubmit={onSubmitAssignResourceHandler}
          employees={activeResources.employees}
          vendors={activeResources.vendors.filter(
            (vendor) => vendor.clients && vendor.clients.includes(clientId ?? ""),
          )}
          defaultValues={{
            employees: {
              laborCode: assignResourceValues?.employees?.laborCode
                ? assignResourceValues.employees.laborCode
                : undefined,
            },
            vendors: {
              woacceptscharges: assignResourceValues ? !!assignResourceValues.vendors.woacceptscharges : true,
              approvedamount: assignResourceValues?.vendors?.approvedamount
                ? assignResourceValues.vendors.approvedamount
                : undefined,
              vendorId: assignResourceValues?.vendors?.vendorId ? assignResourceValues.vendors.vendorId : undefined,
            },
            // commented out temporarily to hide the Employees tab. Will be reverted in the future
            activetab: assignResourceValues?.activetab ?? "vendors",
          }}
        />
      )}

      <Container>
        <Content>
          {!noAuthMode && <BackButton backHandler={onExitClick} />}

          <ContentFrame>
            <StyledForm>
              <InputsContainer $isMobile={isMobile}>
                <InputRow $isMobile={isMobile}>
                  <label>Work Request Name:</label>
                  <div>
                    <Input
                      maxLength={100}
                      placeholder="Enter Work Request Name"
                      onChange={(e) => setFormData((data) => ({ ...data, ticketName: e.target.value }))}
                      value={formData.ticketName}
                      disabled={isVerifyMode}
                    />
                  </div>
                </InputRow>

                {isManager && !noAuthMode && (
                  <>
                    <InputRow $isMobile={isMobile} isSelectSearch>
                      <label>Client:</label>
                      <SelectSearch
                        defaultValue={clientId}
                        placeholder={"Select Client"}
                        $isMobile={isMobile}
                        disabled={isVerifyMode}
                        options={clients.length ? (clients as any) : [{ id: "0", value: "No Clients assigned." }]}
                        onChange={(item: DropdownItem) => {
                          setClientId(item.id.toString());
                        }}
                        bottomResultsGap={43}
                      />
                    </InputRow>
                    <InputRow $isMobile={isMobile} isSelectSearch>
                      <label>Location: </label>
                      <SelectSearch
                        defaultValue={formData.location}
                        placeholder={"Select Location"}
                        disabled={isVerifyMode || !clientId}
                        $isMobile={isMobile}
                        options={dropdownLocations}
                        onChange={(item: DropdownItem) => {
                          setFormData((data) => ({ ...data, location: String(item.id) }));
                        }}
                        bottomResultsGap={43}
                      />
                    </InputRow>
                  </>
                )}

                {!isManager && !noAuthMode ? (
                  <InputRow $isMobile={isMobile}>
                    <label>Service Type:</label>
                    <div>
                      <Input placeholder={"Select Service Type"} disabled value={formData.serviceType}></Input>
                    </div>

                    <HintIcon
                      style={{
                        visibility: defaultServiceType?.longDescription ? "visible" : "hidden",
                      }}
                      title="Edit Mode"
                      data-tip={
                        defaultServiceType?.longDescription
                          ? (defaultServiceType?.longDescription).replace(/<br ?\/?>/g, "\n")
                          : ""
                      }
                      icon={(faIcons as any)["faQuestionCircle"]}
                    />
                  </InputRow>
                ) : (
                  <InputRow $isMobile={isMobile} isSelectSearch>
                    <label>Service Type:</label>
                    <SelectSearch
                      defaultValue={formData.serviceType}
                      placeholder={"Select Service Type"}
                      disabled={isVerifyMode || !clientId}
                      $isMobile={isMobile}
                      options={dropdownServiceTypes}
                      onChange={(item: DropdownItem) => {
                        setFormData((data) => ({ ...data, serviceType: item.id as string, classification: "" }));
                        setServiceTypeId(String(item.id));
                      }}
                      bottomResultsGap={43}
                    />

                    <HintIcon
                      style={{
                        visibility: !!dropdownServiceTypes?.find(
                          (el: Record<string, string>) => el.id === formData.serviceType,
                        )?.longDescription
                          ? "visible"
                          : "hidden",
                      }}
                      data-tip={`<div style="overflow:hidden">${(
                        dropdownServiceTypes?.find((el: Record<string, string>) => el.id === formData.serviceType)
                          ?.longDescription || ""
                      ).replace(/<br ?\/?>/g, "\n")}</div>`}
                      icon={(faIcons as any)["faQuestionCircle"]}
                    />
                  </InputRow>
                )}

                <DropdownRow
                  sort
                  disabled={isVerifyMode || (isManager && !serviceTypeId) || (noAuthMode && !serviceTypeId)}
                  label="Classification:"
                  defaultValue={formData.classification}
                  dropdownItems={dropdownClassifications}
                  placeholder={"Select Classification"}
                  onClick={(item) => {
                    setFormData((data) => ({ ...data, classification: item.value }));
                    setClassificationId(String(item.id));
                  }}
                >
                  <HintIcon
                    style={{
                      visibility: !!dropdownClassifications.find((el) => el.value === formData.classification)
                        ?.longDescription
                        ? "visible"
                        : "hidden",
                    }}
                    data-tip={`<div style="overflow: hidden">${(
                      dropdownClassifications.find((el) => el.value === formData.classification)?.longDescription || ""
                    ).replace(/<br ?\/?>/g, "\n")}</div>`}
                    icon={(faIcons as any)["faQuestionCircle"]}
                  />
                </DropdownRow>

                <ReactTooltip className="tooltip" />

                <DropdownRow
                  sort
                  disabled={isVerifyMode}
                  label="Work Type:"
                  dropdownItems={dropdownWorkTypes}
                  placeholder={"Select Work Type"}
                  onClick={(item) => setFormData((data) => ({ ...data, workType: item.type }))}
                />

                {!noAuthMode && (
                  <DropdownRow
                    disabled={isVerifyMode || !clientId}
                    label="Priority Level:"
                    defaultValue={formData.priorityName}
                    dropdownItems={dropdownPriorityLevels || []}
                    placeholder={"Select Priority Level"}
                    onClick={(item) => onPriorityLevelChange(item)}
                  />
                )}

                {!isManager && (
                  <InputRow $isMobile={isMobile} isSelectSearch>
                    <label>Location: </label>
                    <SelectSearch
                      defaultValue={""}
                      placeholder={"Select Location"}
                      disabled={isVerifyMode || !clientId}
                      $isMobile={isMobile}
                      options={dropdownLocations}
                      onChange={(item: DropdownItem) => {
                        setFormData((data) => ({ ...data, location: String(item.id) }));
                        setTimezone(item.timezone);
                      }}
                      bottomResultsGap={43}
                    />
                  </InputRow>
                )}
              </InputsContainer>
              {!!serviceTypeQuestions.length &&
                serviceTypeQuestions.map((el: any) => (
                  <ServiceTypeQuestion
                    key={el.id}
                    question={el}
                    formData={formData}
                    setFormData={setFormData}
                    disabled={isVerifyMode}
                  />
                ))}

              <DescriptionContainer $isMobile={isMobile}>
                <label>Description: </label>

                <Textarea
                  countSymbols
                  width="360px"
                  value={formData.description}
                  disabled={isVerifyMode}
                  placeholder="Enter Description"
                  onChange={(e) => setFormData((data) => ({ ...data, description: e.target.value }))}
                ></Textarea>
              </DescriptionContainer>
              {customWorkrequestFields && (
                <CustomWorkRequestFieldsForm
                  customWorkRequestFields={customWorkrequestFields[clientId] ?? []}
                  $isMobile={isMobile}
                  form={form}
                  isPublic={!isManager && !!noAuthMode}
                  isClient={!isManager && !noAuthMode}
                />
              )}
              {isManager && <IsClientVisbleCheckBoxForm form={form} $isMobile={isMobile} />}
              {isManager && (
                <InputsContainer>
                  <InputRow $isMobile={isMobile}>
                    <label style={{ marginRight: 5 }}>Resource (optional) :</label>
                    <ResourseAssigned
                      employees={activeResources.employees}
                      vendors={activeResources.vendors}
                      onClick={() => {
                        if (!isVerifyMode) {
                          setIsModalVisible(true);
                        }
                      }}
                      vendorId={
                        assignResourceValues?.vendors?.vendorId ? assignResourceValues.vendors.vendorId : undefined
                      }
                      laborCode={
                        assignResourceValues?.employees?.laborCode
                          ? assignResourceValues.employees.laborCode
                          : undefined
                      }
                    />
                  </InputRow>
                </InputsContainer>
              )}
              {isVerifyMode && files?.length === 0 ? null : (
                <PhotoInput>
                  <UploadWorkOrderAttachments setFiles={setFiles} files={files} isViewMode={isVerifyMode} />
                </PhotoInput>
              )}

              {isManager && !noAuthMode && (
                <EmailToForm isManager={isManager} form={form} $isMobile={isMobile} disabled={isVerifyMode} />
              )}

              {!isVerifyMode && (
                <ButtonContainer>
                  <Button disabled={buttonDisabledConditions} onClick={onConfirmClick} text="Confirm" />
                </ButtonContainer>
              )}

              {isVerifyMode && (
                <InputsContainer showTopLine={files?.length === 0 ? 1 : 0} isVerify={isVerifyMode ? 1 : 0}>
                  <DateRow
                    timezone={timezone}
                    date={formData.targetStart}
                    $isMobile={isMobile}
                    label="Target Start:"
                    maximoTimezone={maximoTimezone}
                  />

                  <DateRow
                    timezone={timezone}
                    date={formData.targetFinish}
                    $isMobile={isMobile}
                    label="Target Finish:"
                    maximoTimezone={maximoTimezone}
                  />
                </InputsContainer>
              )}
              <ErrorMessage>{error && error}</ErrorMessage>
              {isVerifyMode && (
                <SubmitContainer $isMobile={isMobile}>
                  <Checkbox
                    onChange={(e) => setIsVerifyChecked(e.target.checked)}
                    checked={isVerifyChecked}
                    label="I verify the above entered details to be true and correct."
                  />
                  <ButtonContainer>
                    <Button onClick={() => setIsVerifyMode(false)} text="Make changes" variant="secondary" />
                    <Button
                      loading={isLoading}
                      disabled={!isVerifyChecked}
                      onClick={(e: React.MouseEvent) => onSubmit(e)}
                      text="Submit"
                    />
                  </ButtonContainer>
                </SubmitContainer>
              )}
            </StyledForm>
          </ContentFrame>
        </Content>
      </Container>
    </LoadingContainer>
  );
};
