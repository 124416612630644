import React, { CSSProperties } from "react";
import { InputRow } from "../ViewForm";
import Dropdown from "src/components/Dropdown";
import { DropdownItem } from "src/components/Dropdown/types";

export interface DropdownRowProps {
  dropdownItems: DropdownItem[];
  label?: string;
  placeholder?: string;
  onClick?: (item: DropdownItem) => void;
  defaultValue?: string;
  disabled?: boolean;
  sort?: boolean;
  defaultValueColor?: string;
  children?: React.ReactNode;
  marginBottom?: string;
  labelStyle?: CSSProperties;
  inputStyle?: string;
  placeholderStyle?: string;
  width?: string;
}

const DropdownRow = ({
  dropdownItems,
  label,
  onClick,
  placeholder,
  defaultValue,
  disabled,
  sort,
  defaultValueColor,
  marginBottom,
  labelStyle,
  inputStyle,
  placeholderStyle,
  width,
  children,
}: DropdownRowProps) => {
  return (
    <InputRow $marginBottom={marginBottom}>
      <label style={labelStyle}>{label}</label>
      <Dropdown
        defaultValueColor={defaultValueColor}
        sort={sort}
        placeholder={placeholder}
        items={dropdownItems}
        onClick={(item) => onClick && onClick(item)}
        noRadius
        disabled={disabled}
        defaultValue={defaultValue}
        width={width}
        inputStyle={inputStyle}
        placeholderStyle={placeholderStyle}
      />
      {children}
    </InputRow>
  );
};

export default DropdownRow;
