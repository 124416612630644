import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
  AdjustmentReason,
  Assignment,
  BaseResponse,
  Client,
  ClientPortalTargetTime,
  ClientSpecificLocationForTable,
  CompletedStatus,
  DBClientPortalTileGlobal,
  DBClientUser,
  DBContract,
  DBDropdownListSizes,
  DBEmployeeUser,
  DBKleenwayContact,
  DBLocation,
  DBMessage,
  DBObject,
  DBPayPeriod,
  DBVendor,
  DBVendorUser,
  DynamicFormField,
  Employee,
  EmployeePayrollData,
  IServiceTypeQuestion,
  LocationForTable,
  PayPeriodType,
  PriorityLevel,
  PriorityLevelList,
  QuickSearchEntity,
  ScheduleTemplateDB,
  ServiceTypeList,
  TeamMember,
  TeamMemberById,
  Ticket,
  UnregisteredUserData,
  WorkType,
  Worklog,
} from "./../../types/index";

import { UseFormProps } from "react-hook-form";
import { DynamicFormConfig, FormData } from "src/components/DynamicFormContainer/types";
import { OptionItem } from "src/components/SelectSearch";
import { AdjustmentFormValues } from "src/components/molecules/AdjustmentForm/types";
import { Contact } from "src/components/molecules/ContactForm/types";
import { ScheduleTemplate } from "src/components/molecules/ShiftTemplateForm/types";
import { CustomWorkRequestField, CustomWorkRequestFieldList } from "src/pages/TicketForm/CreateForm/types";
import { ActiveEmployee, ActiveVendor } from "src/pages/Tickets/TicketTable";
import { DBVendorDetails } from "src/pages/VendorDetails/types";
import { Classification, DBClientPortalTile, ID, IOmegaParentFolder, ServiceType, WorkOrder } from "src/types";
import FirebaseService from ".";
import { EmployeesApprovedHoursFormProps } from "../../pages/ClientDetails/ClientDetailsProvider";
import { AssignWOResourceFormValues } from "src/components/molecules/AssignWOResource/types";

type DataForTicketPageResponse = {
  siblings: number;
  ticketsPerPage: number;
  workTypes: WorkType[];
  serviceTypes: ServiceType;
  classifications: Classification[];
  completeStatuses: CompletedStatus[];
  priorityLevels: PriorityLevelList;
};

export interface TicketFormResponse {
  locationTimezone?: string;
  priorityLevels: PriorityLevel[];
  workTypes: WorkType[];
  locations: DBLocation[];
  classifications: Classification[];
  workOrder: WorkOrder;
  serviceTypes: ServiceTypeList;
  clients: Client[];
}

interface DataForTicketViewPageResponse extends TicketFormResponse {
  priorityLevel: PriorityLevel;
  technicianNotesWorklog: Worklog[];
  commentsSectionWorklog: Worklog[];
  statusList: OptionItem[];
  attachments: {
    technicianPhotos: any[];
    clientPhotos: any[];
    documents: any;
  };
  customWorkRequestFields: CustomWorkRequestField[];
  employees: ActiveEmployee[];
  vendors: ActiveVendor[];
}

export interface DataForTicketCreatePageResponse {
  locationTimezone?: string;
  priorityLevels: PriorityLevelList;
  workTypes: WorkType[];
  locations: DBLocation[];
  classifications: Classification[];
  workOrder: WorkOrder;
  serviceTypes: ServiceTypeList;
  clients: Client[];
  customWorkRequestFields: CustomWorkRequestFieldList;
}

export interface DataForOnBoardingPageResponse {
  locations: DBLocation[];
  clients: Client[];
  formData: DynamicFormField;
}

export interface NoAuthDataForTicketCreatePageResponse {
  locationTimezone?: string;
  priorityLevels: PriorityLevelList;
  workTypes: WorkType[];
  locations: DBLocation[];
  classifications: Classification[];
  workOrder: WorkOrder;
  targetTime: any;
  serviceTypes: ServiceType;
  status: string;
  customWorkRequestFields: CustomWorkRequestFieldList;
}

interface iClientDataForNoAuthMode {
  data: {
    clientData: {
      clientId: string;
      logo?: string;
      name?: string;
    };
    isCompanyCodeRequired: boolean;
    sessionTimeout: number | string;
    maximoTimezone?: string;
    labelMessage: string;
  };
}

interface iMaximoWorkOrders {
  data: {
    workOrders: Ticket[];
    workOrderCount: number;
    workOrderPage: number;
    searchTerm: string;
    vendors: ActiveVendor[];
    employees: ActiveEmployee[];
  };
}

interface iMaximoAttachment {
  base64: string;
  contentType: string;
  fileName: string;
}

interface iServiceTypeQuestions {
  data: IServiceTypeQuestion[];
  status: string;
  message: string;
}

interface iCreateWorkLogComment {
  data: IServiceTypeQuestion[];
  status: string;
  message: string;
}

interface iGetWorkLogComment {
  data: { worklogComments: Worklog[] };
  status: string;
  message: string;
}

interface iGetEmployeeLocations {
  data: LocationForTable[];
  status: string;
  message: string;
}

interface iGetEmployeeLocationsByClientId {
  data: ClientSpecificLocationForTable[];
  status: string;
  message: string;
}

interface iGetSupervisors {
  data: Employee[];
  status: string;
  message: string;
}
interface iGetClientPayrollData {
  data: { sortedByEmployeeName: EmployeePayrollData[]; adjustmentReasons: AdjustmentReason[] };
  status: string;
  message: string;
}

interface IGetTeamMembersData {
  data: TeamMember[];
  status: string;
  message: string;
  total: number;
  perPage: number;
}

interface IGetTeamMemberByIdData {
  data: TeamMemberById;
  status: string;
  message: string;
}
interface IGetTeamMemberArguments {
  managerId: ID;
  currentTeamMembersPage: number;
  filters?: Record<string, string | boolean>;
}

interface iGetScheduleTemplates {
  data: ScheduleTemplate[];
  status: string;
  message: string;
}

interface iGetQuickSearch {
  data: QuickSearchEntity[];
  status: string;
  message: string;
}
interface iGetTeamMemberAssignments {
  data: Assignment[];
  status: string;
  message: string;
}

interface iGetContacts {
  data: Contact[];
  status: string;
  message: string;
}

interface WorkRequestsStatistics {
  maxvalue: string;
  statusCode: string;
  status: string;
  count: number;
}

export interface GetWidgetsDataResponse {
  widgetsData: {
    workRequestsStatistics: WorkRequestsStatistics[];
  };
}

export interface iGetInfoMessages {
  data: {
    globalMessages: DBMessage[];
    colors: OptionItem[];
    fontSizes: OptionItem[];
  };
  status: string;
  message: string;
}
interface iCreateFolderOmega {
  data: number;
  status: string;
  message: string;
}

interface iGetPortalUsers {
  data: DBClientUser[];
  status: string;
  message: string;
}
interface iDeleteAttachemnt {
  data: null;
  status: string;
  message: string;
}

interface iAddAttachment {
  data: {
    domain: string;
    parentId: string;
    id: string;
  };
  status: string;
  message: string;
}
interface iShareWorkRequest {
  status: string;
  message: string;
}
export interface DayClassification {
  id: string;
  label: string;
  priority?: number;
}
interface GetClientContextResult {
  data: {
    payPeriodTypes: PayPeriodType[];
    globalContacts: DBKleenwayContact[];
    supportedTimezones: string[];
    infoMessages: DBMessage[];
    dropdownListSizes: DBDropdownListSizes;
    dayClassifications: DayClassification[];
  };
  status: string;
  message: string;
}
interface iGetPortalVendors {
  data: DBVendor[];
  status: string;
  message: string;
}

type UserDataType = DBEmployeeUser & DBVendorUser & (DBClientUser & { id: string });

interface iGetVendorDetails {
  data: { vendorDetails: DBVendorDetails; contract: DBContract[] };
  status: string;
  message: string;
}

interface iGetDynamicFormList {
  data: { dynamicFormList: DynamicFormConfig[] };
  status: string;
  message: string;
}

interface iGetDynamicFormData {
  data: {
    formConfig: DynamicFormConfig;
    clientsArray?: Client[];
    locationsArray?: DBLocation[];
    defaultValues?: UseFormProps["defaultValues"];
  };
  status: string;
  message: string;
}

interface iGetClientsAndLocationsArrayData {
  data: { locations: DBLocation[]; clients: Client[] };
  status: string;
  message: string;
}

interface iCloseWorkOrderRequest {
  status: string;
  message: string;
  data: { workOrderId: string };
}

export type Response = {
  status: string;
  message: string;
};

export class FirebaseDataService {
  static async getClientUserById(id: ID) {
    return FirebaseService.getData({
      ref: `clientUsers/${id}`,
      error: "The entered Kleenway Client User Id is not found",
    });
  }

  static async getRootFolderConfig() {
    return FirebaseService.getData({
      ref: `/kleenway/managerPortal/homeFolderConfig`,
      error: "Config is not defined",
    });
  }

  static async getLocationById(id: ID): Promise<{ data: DBLocation }> {
    return FirebaseService.getData({
      ref: `locations/${id}`,
      error: "The entered Kleenway Location Id is not found",
    });
  }

  static async getLocationByClientId(clientId: ID): Promise<{ data: DBObject<DBLocation> }> {
    return FirebaseService.getData({
      ref: `/locations`,
      error: "No assigned locations",
      orderByChild: "clientId",
      equalTo: clientId,
    });
  }

  static async getClientUserByEmail(email: string) {
    return FirebaseService.getData({
      ref: "clientUsers",
      error: "The entered Kleenway Client User email is not found",
      orderByChild: "email",
      equalTo: email,
    });
  }

  static async getClientDataById(id: ID): Promise<{ data: Client }> {
    return FirebaseService.getData({
      ref: `clients/${id}`,
      error: "The entered Kleenway Client Id is not found",
    });
  }

  static async getVendorDataById(id: ID): Promise<{ data: Client }> {
    return FirebaseService.getData({
      ref: `vendors/${id}`,
      error: "The entered Kleenway Vendor Id is not found",
    });
  }

  static async getRecaptchaKey() {
    return FirebaseService.getData({
      ref: `/kleenway/clientPortal/recaptcha/client`,
      error: "No captcha ID",
    });
  }

  static async getApprovalDetailsMessage() {
    return FirebaseService.getData({
      ref: `/kleenway/clientPortal/messages/approvalDetails`,
      error: "No approval details message",
    });
  }

  static async getClientApproveStatuses() {
    return FirebaseService.getData({
      ref: `/kleenway/clientPortal/statuses/approve`,
      error: "No approve statuses",
    });
  }

  static async getEmployeeUserByEmail(email: string) {
    return FirebaseService.getData({
      ref: "employees",
      error: "The entered Kleenway Employee User email is not found",
      orderByChild: "email",
      equalTo: email,
    });
  }
  static async getVendorUserByEmail(email: string) {
    return FirebaseService.getData({
      ref: "vendorUsers",
      error: "The entered Kleenway Vendor User email is not found",
      orderByChild: "email",
      equalTo: email,
    });
  }

  static async getEmployeeClientsPageSize() {
    return FirebaseService.getData({
      ref: `/kleenway/clientPortal/pagination/clientsPerPage`,
      error: "Page size is not defined",
    });
  }

  static async getEmployeeVendorsPageSize() {
    return FirebaseService.getData({
      ref: `/kleenway/clientPortal/pagination/vendorsPerPage`,
      error: "Page size is not defined",
    });
  }

  static async getEmployeeLocationsPageSize() {
    return FirebaseService.getData({
      ref: `/kleenway/clientPortal/pagination/locationsPerPage`,
      error: "Page size is not defined",
    });
  }

  static async getRolesFilter() {
    return FirebaseService.getData({
      ref: `/kleenway/clientPortal/rolesFilter`,
      error: "Roles filter is not defined",
    });
  }

  static async getEmployeeClients({ employeeId }: { employeeId: ID }) {
    return FirebaseService.getData({
      ref: `clients`,
      error: "No clients",
      orderByChild: "managerId",
      equalTo: employeeId,
    });
  }

  static async getClientsByManagerId({ managerId }: { managerId: ID }) {
    return FirebaseService.getData({
      ref: `clients`,
      error: "No clients",
      orderByChild: "managerId",
      equalTo: managerId,
    });
  }

  static async getLocationsByManagerId({ managerId }: { managerId: ID }) {
    return FirebaseService.getData({
      ref: `locations`,
      error: "No Locations",
      orderByChild: "managerId",
      equalTo: managerId,
    });
  }

  static async getDropdownListSizes(formId: string): Promise<{ data: DBDropdownListSizes }> {
    return FirebaseService.getData({
      ref: `/kleenway/managerPortal/dropdownListSizes/${formId}`,
      error: "Dropdown list sizes is not defined",
    });
  }

  static async getClientContext(clientId: string): Promise<GetClientContextResult> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientId: string }, GetClientContextResult>(
      functions,
      "clientPortal-getClientContext",
    );
    const res = await cloudFunction({ clientId });
    return res.data;
  }

  static async getScheduleTemplateTypes(): Promise<{ data: Record<string, number> }> {
    return FirebaseService.getData({
      ref: `/kleenway/schedule/types`,
      error: "Types are not defined.",
    });
  }

  static async getDemoForm(): Promise<{ data: DynamicFormField }> {
    return FirebaseService.getData({
      ref: `/kleenway/forms/demoForm`,
      error: "No data found.",
    });
  }

  static async getPayPeriodsByCountryCode(countryCode: string): Promise<{ data: DBObject<DBPayPeriod> }> {
    return FirebaseService.getData({
      ref: `/reports/payroll/payPeriods`,
      error: "Pay periods is not defined",
      orderByChild: "countryCode",
      equalTo: countryCode,
    });
  }

  static async getEmployeeClient({ clientId }: { clientId: ID }): Promise<{ data: Client }> {
    const { data, ...response } = await FirebaseService.getData({
      ref: `clients/${clientId}`,
      error: "Client not found",
    });

    return {
      data: {
        ...data,
        ...(data.contacts && {
          contacts: Array.isArray(data.contacts) ? data.contacts : Object.values(data.contacts),
        }),
      },
      ...response,
    };
  }

  static async getUserData(clientUserId: ID, email: string): Promise<{ data: UserDataType }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientUserId: ID; email: string }, { data: UserDataType }>(
      functions,
      "clientPortal-getUserData",
    );
    const res = await cloudFunction({ clientUserId, email });
    return res.data;
  }

  static async getWidgetsData(loggedInUserId: ID, isManager: boolean): Promise<{ data: GetWidgetsDataResponse }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ loggedInUserId: ID; isManager: boolean }, { data: GetWidgetsDataResponse }>(
      functions,
      "clientPortal-getWidgetsData",
    );
    const res = await cloudFunction({ loggedInUserId, isManager });
    return res.data;
  }

  static async getClientUserData(clientUserId: ID): Promise<{ data: DBClientUser & { id: string } }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientUserId: ID }, { data: DBClientUser & { id: string } }>(
      functions,
      "clientPortal-getClientUserData",
    );
    const res = await cloudFunction({ clientUserId });
    return res.data;
  }

  static async getEmployeeUserData(employeeEmail: string): Promise<{ data: DBEmployeeUser & { id: string } }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ employeeEmail: string }, { data: DBEmployeeUser & { id: string } }>(
      functions,
      "clientPortal-getEmployeeUserData",
    );
    const res = await cloudFunction({ employeeEmail });
    return res.data;
  }

  static async getHomepageTiles(
    clientId: ID,
    isManager: boolean,
    isVendor: boolean,
  ): Promise<{ data: DBClientPortalTile[]; isManager: boolean; isVendor: boolean }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { clientId: ID; isManager: boolean; isVendor: boolean },
      { data: DBClientPortalTile[]; isManager: boolean; isVendor: boolean }
    >(functions, "clientPortal-getHomepageTiles");
    const res = await cloudFunction({ clientId, isManager, isVendor });
    return res.data;
  }

  static async getClientUserRoles(clientUserId: ID): Promise<{ data: string[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientUserId: ID }, { data: string[] }>(
      functions,
      "clientPortal-getClientUserRoles",
    );
    const res = await cloudFunction({ clientUserId });
    return res.data;
  }

  static async getClientDataForNoAuthMode(clientCode: string): Promise<iClientDataForNoAuthMode> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientCode: string }, iClientDataForNoAuthMode>(
      functions,
      "clientPortal-getClientDataForNoAuthMode",
    );
    const res = await cloudFunction({ clientCode });
    return res.data;
  }

  static async getClientUserLocations(clientId: ID, roles: string[]): Promise<{ data: string[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientId: ID; roles: string[] }, { data: string[] }>(
      functions,
      "clientPortal-getClientUserLocations",
    );
    const res = await cloudFunction({ clientId, roles });
    return res.data;
  }

  static async getPriorityLevels(): Promise<{ data: PriorityLevel[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, { data: PriorityLevel[] }>(
      functions,
      "clientPortal-getPriorityLevels",
    );
    const res = await cloudFunction();
    return res.data;
  }

  static async getWorkTypes(): Promise<{ data: WorkType[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, { data: WorkType[] }>(functions, "clientPortal-getWorkTypes");
    const res = await cloudFunction();
    return res.data;
  }

  static async getServiceTypeById(serviceTypeId: string): Promise<{ data: ServiceType }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ serviceTypeId: string }, { data: ServiceType }>(
      functions,
      "clientPortal-getServiceTypeById",
    );
    const res = await cloudFunction({ serviceTypeId });
    return res.data;
  }

  static async getLocations(): Promise<{ data: DBLocation[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, { data: DBLocation[] }>(functions, "clientPortal-getLocations");
    const res = await cloudFunction();
    return res.data;
  }

  static async getMaximoWorkOrders(
    loggedInUserId: ID,
    filters: any,
    isManager: boolean,
    searchTerm: string,
    vendorId?: string,
  ): Promise<iMaximoWorkOrders> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        loggedInUserId: ID;
        filters: any;
        isManager: boolean;
        searchTerm: string;
        vendorId?: string;
      },
      iMaximoWorkOrders
    >(functions, "clientPortal-getWorkOrders");
    const res = await cloudFunction({ loggedInUserId, filters, isManager, searchTerm, vendorId });
    return res.data;
  }

  static async getMaximoClassifications(): Promise<{ data: Classification[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, { data: Classification[] }>(
      functions,
      "clientPortal-getClassifications",
    );
    const res = await cloudFunction();
    return res.data;
  }

  static async getFolderContent(folderId: ID): Promise<{ data: IOmegaParentFolder }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ folderId: ID }, { data: IOmegaParentFolder }>(
      functions,
      "clientPortal-getFolderContent",
    );
    const res = await cloudFunction({ folderId });
    return res.data;
  }

  static async getTileContent(parentId: ID, folderName: string): Promise<{ data: IOmegaParentFolder }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ parentId: ID; folderName: string }, { data: IOmegaParentFolder }>(
      functions,
      "clientPortal-getFolderContentByName",
    );
    const res = await cloudFunction({ parentId, folderName });
    return res.data;
  }

  static async getFileUrl(fileId: ID, action: string): Promise<{ data: string }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ fileId: ID; action: string }, { data: string }>(
      functions,
      "clientPortal-getFileUrl",
    );
    const res = await cloudFunction({ fileId, action });
    return res.data;
  }

  /* File sharing functions start */
  /* 
    Donwload folder
    folderId - download folder ID
  */
  static async downloadOmegaFolder(folderId: ID): Promise<{ data: string }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ folderId: ID }, { data: string }>(
      functions,
      "clientPortal-downloadOmegaFolder",
    );
    const res = await cloudFunction({ folderId });
    return res.data;
  }

  /* 
    Rename file or folder
    elemId - the id of the element to be rename
    name - new name of Element
    type - type of element to be renamed (file or folder) 
  */
  static async renameOmegaElement(elemId: ID, name: string, type: string): Promise<{ data: string }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ elemId: ID; name: string; type: string }, { data: string }>(
      functions,
      "clientPortal-renameOmegaElement",
    );
    const res = await cloudFunction({ elemId, name, type });
    return res.data;
  }

  /* 
    Create new folder
    folderId - id of the folder where the creation is in progress
    name - the name of the folder to be created
  */
  static async createOmegaFolder(folderId: ID, name: string): Promise<iCreateFolderOmega> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ folderId: ID; name: string }, iCreateFolderOmega>(
      functions,
      "clientPortal-createOmegaFolder",
    );
    const res = await cloudFunction({ folderId, name });
    return res.data;
  }

  /* 
    Delete file or folder
    elemId - the id of the element to be delete
    type - type of element to be removed (file or folder) 
  */
  static async deleteOmegaElement(elemId: ID, type: string): Promise<{ data: string }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ elemId: ID; type: string }, { data: string }>(
      functions,
      "clientPortal-deleteOmegaElement",
    );
    const res = await cloudFunction({ elemId, type });
    return res.data;
  }

  /* 
    Move file or folder to another folder
    dragId - the id of the element to be dragged
    propId - id of the folder where the file or folder will be dropped
    type - type of dragged element (file or folder) 
  */
  static async moveOmegaElement(dragId: ID, dropId: ID, type: string): Promise<{ data: string }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ dragId: ID; dropId: ID; type: string }, { data: string }>(
      functions,
      "clientPortal-moveOmegaElement",
    );
    const res = await cloudFunction({ dragId, dropId, type });
    return res.data;
  }
  /* File sharing functions end */

  static async createMaximoWorkOrder(
    workOrderData: any,
    clientUserId: ID,
    assignResource?: AssignWOResourceFormValues,
  ): Promise<BaseResponse<{ wonum: string; workorderid: string; message: string }>> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { workOrderData: any; clientUserId: ID; assignResource?: AssignWOResourceFormValues },
      BaseResponse<{ wonum: string; workorderid: string; message: string }>
    >(functions, "clientPortal-createMaximoWorkOrder");
    const res = await cloudFunction({ workOrderData, clientUserId, assignResource });
    return res.data;
  }

  static async addMaximoWorkOrderAttachment(
    base64: string,
    workOrderId: string,
    fileName: string,
    contentType: string,
    folderName: string,
  ): Promise<iAddAttachment> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        base64: string;
        workOrderId: string;
        fileName: string;
        contentType: string;
        folderName: string;
      },
      iAddAttachment
    >(functions, "clientPortal-addMaximWorkOrderAttachment");
    const res = await cloudFunction({ base64, workOrderId, fileName, contentType, folderName });
    return res.data;
  }

  static async getWorkOrderImages(
    id: number,
  ): Promise<{ data: { technicianPhotos: string[]; clientPhotos: string[] } }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { id: number },
      { data: { technicianPhotos: string[]; clientPhotos: string[] } }
    >(functions, "clientPortal-getWorkOrderImages");
    const res = await cloudFunction({ id });
    return res.data;
  }

  static async getTargetTime(): Promise<{ data: ClientPortalTargetTime }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, { data: ClientPortalTargetTime }>(
      functions,
      "clientPortal-getTargetTime",
    );
    const res = await cloudFunction();
    return res.data;
  }

  static async getLogtype(): Promise<{ data: string }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, { data: string }>(functions, "clientPortal-getLogtype");
    const res = await cloudFunction();
    return res.data;
  }

  static async getDataForTicketListPage(
    loggedInUserId: ID,
    isManager: boolean,
    isVendor: boolean,
  ): Promise<{ data: DataForTicketPageResponse }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { loggedInUserId: ID; isManager: boolean; isVendor: boolean },
      { data: DataForTicketPageResponse }
    >(functions, "clientPortal-getDataForTicketListPage");
    const res = await cloudFunction({ loggedInUserId, isManager, isVendor });
    return res.data;
  }

  static async getDataForTicketViewForm(
    wonum: string,
    clientUserId: ID,
    isManager: boolean,
  ): Promise<{ data: DataForTicketViewPageResponse }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        wonum: string;
        clientUserId: ID;
        isManager: boolean;
      },
      { data: DataForTicketViewPageResponse }
    >(functions, "clientPortal-getDataForTicketViewForm");
    const res = await cloudFunction({ wonum, clientUserId, isManager });
    return res.data;
  }

  static async shareWorkRequest(
    emailTo: { id: string; value: string }[],
    emailMessage: string,
    workorderid: number,
    managerName: string,
    wonum: string,
    replyTo: string,
  ): Promise<{ data: iShareWorkRequest }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        emailTo: { id: string; value: string }[];
        emailMessage: string;
        workorderid: number;
        managerName: string;
        wonum: string;
        replyTo: string;
      },
      { data: iShareWorkRequest }
    >(functions, "clientPortal-shareWorkRequest");
    const res = await cloudFunction({
      emailTo,
      emailMessage,
      workorderid,
      managerName,
      wonum,
      replyTo,
    });
    return res.data;
  }

  static async getMaximoAttachment(
    workOrderId: number,
    attachmentId: string,
    fileName: string,
  ): Promise<iMaximoAttachment> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        workOrderId: number;
        attachmentId: string;
        fileName: string;
      },
      iMaximoAttachment
    >(functions, "clientPortal-getMaximoAttachment");
    const res = await cloudFunction({ attachmentId, workOrderId, fileName });
    return res.data;
  }

  static async getDataForTicketCreateForm(
    wonum: string,
    clientUserId: ID,
    isManager: boolean,
  ): Promise<{ data: DataForTicketCreatePageResponse }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { wonum: string; clientUserId: ID; isManager: boolean },
      { data: DataForTicketCreatePageResponse }
    >(functions, "clientPortal-getDataForTicketCreateForm");
    const res = await cloudFunction({ wonum, clientUserId, isManager });
    return res.data;
  }

  static async getDataForOnBoardingForm(
    clientUserId: ID,
    isManager: boolean,
  ): Promise<{ data: DataForOnBoardingPageResponse }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { clientUserId: ID; isManager: boolean },
      { data: DataForOnBoardingPageResponse }
    >(functions, "clientPortal-getDataForOnBoardingForm");
    const res = await cloudFunction({ clientUserId, isManager });
    return res.data;
  }

  static async verifyClientUserInfo(
    data: UnregisteredUserData,
  ): Promise<{ data: NoAuthDataForTicketCreatePageResponse; status: string; message: string }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      UnregisteredUserData,
      { data: NoAuthDataForTicketCreatePageResponse; status: string; message: string }
    >(functions, "clientPortal-verifyClientUserInfo");
    const res = await cloudFunction({ ...data });
    return res.data;
  }

  static async getServiceTypeQuestions(clientId: ID, serviceTypeId: ID): Promise<iServiceTypeQuestions> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        clientId: ID;
        serviceTypeId: ID;
      },
      iServiceTypeQuestions
    >(functions, "clientPortal-getServiceTypeQuestions");
    const res = await cloudFunction({ clientId, serviceTypeId });
    return res.data;
  }

  static async runWorkOrderAction(workOrderId: string, action: string, data: object) {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable(functions, "clientPortal-runWorkOrderAction");
    const res = await cloudFunction({ workOrderId, action, data });
    return res.data;
  }

  static async createWorklogComment(
    worklogSummary: string,
    worklogDetails: string,
    workOrderId: ID,
    isManager: boolean,
    clientviewable: boolean,
  ): Promise<iCreateWorkLogComment> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        worklogSummary: string;
        worklogDetails: string;
        workOrderId: ID;
        clientviewable: boolean;
        isManager: boolean;
      },
      iCreateWorkLogComment
    >(functions, "clientPortal-createWorklogComment");
    const res = await cloudFunction({ worklogSummary, worklogDetails, workOrderId, isManager, clientviewable });
    return res.data;
  }

  static async getWorkLogComments(wonum: string, isManager: boolean): Promise<iGetWorkLogComment> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        wonum: string;
        isManager: boolean;
      },
      iGetWorkLogComment
    >(functions, "clientPortal-getWorkLogComments");
    const res = await cloudFunction({ wonum, isManager });
    return res.data;
  }

  static async updateClient(id: ID, client: Client) {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ id: ID; client: Client }, BaseResponse>(
      functions,
      "clientPortal-updateClient",
    );
    const res = await cloudFunction({ id, client });
    return res.data;
  }

  static async updateClientUsers(id: string, clientUser: Record<string, string | undefined | boolean>) {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { id: string; clientUser: Record<string, string | undefined | boolean> },
      Record<string, string>
    >(functions, "clientPortal-updateClientUser");
    const res = await cloudFunction({ id, clientUser });
    return res.data;
  }

  static async updateEmployeeApprovedHours(
    employeesApprovedHours: EmployeesApprovedHoursFormProps,
    clientId: string,
    payPeriodId: string,
  ) {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { employeesApprovedHours: EmployeesApprovedHoursFormProps; clientId: string; payPeriodId: string },
      BaseResponse
    >(functions, "clientPortal-updateEmployeeApprovedHours");
    const res = await cloudFunction({ employeesApprovedHours, clientId, payPeriodId });
    return res.data;
  }
  /**
   *This method is work as service for
   *update and create employee payperiod adjustment.
   */
  static async updateEmployeePayPeriodAdjustment(
    adjustment: AdjustmentFormValues,
    payPeriodId: string,
    employeeId: string,
  ) {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      { adjustment: AdjustmentFormValues; payPeriodId: string; employeeId: string },
      BaseResponse
    >(functions, "clientPortal-updateEmployeePayPeriodAdjustment");
    const res = await cloudFunction({ adjustment, payPeriodId, employeeId });
    return res.data;
  }

  static async getClientPayrollData(payPeriodId: string, clientId: string): Promise<iGetClientPayrollData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        payPeriodId: string;
        clientId: string;
      },
      iGetClientPayrollData
    >(functions, "clientPortal-getClientPayrollData");
    const res = await cloudFunction({
      payPeriodId,
      clientId,
    });
    return res.data;
  }

  static async updateLocation(id: ID, location: DBLocation) {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ id: ID; location: DBLocation }, BaseResponse>(
      functions,
      "clientPortal-updateLocation",
    );
    const res = await cloudFunction({ id, location });
    return res.data;
  }

  static async getEmployeeLocations(employeeId: ID): Promise<iGetEmployeeLocations> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ employeeId: ID }, iGetEmployeeLocations>(
      functions,
      "clientPortal-getEmployeeLocations",
    );
    const res = await cloudFunction({ employeeId });
    return res.data;
  }

  static async getLocationsByClientId(clientId: ID): Promise<iGetEmployeeLocationsByClientId> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientId: ID }, iGetEmployeeLocationsByClientId>(
      functions,
      "clientPortal-getLocationsByClientId",
    );
    const res = await cloudFunction({ clientId });
    return res.data;
  }

  static async getTeamMembers({
    managerId,
    currentTeamMembersPage,
    filters,
  }: IGetTeamMemberArguments): Promise<IGetTeamMembersData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<IGetTeamMemberArguments, IGetTeamMembersData>(
      functions,
      "clientPortal-getTeamMembers",
    );
    const res = await cloudFunction({ managerId, currentTeamMembersPage, filters });
    return res.data;
  }

  static async getTeamMemberById({
    managerId,
    employeeId,
  }: {
    managerId: ID;
    employeeId: ID;
  }): Promise<IGetTeamMemberByIdData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        managerId: ID;
        employeeId: ID;
      },
      IGetTeamMemberByIdData
    >(functions, "clientPortal-getTeamMemberById");
    const res = await cloudFunction({ managerId, employeeId });
    return res.data;
  }

  static async updateTeamMember({
    employeeId,
    teamMemberData,
  }: {
    employeeId: ID;
    teamMemberData: TeamMemberById;
  }): Promise<IGetTeamMemberByIdData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        employeeId: ID;
        teamMemberData: TeamMemberById;
      },
      IGetTeamMemberByIdData
    >(functions, "clientPortal-updateTeamMember");
    const res = await cloudFunction({ employeeId, teamMemberData });
    return res.data;
  }

  static async getSupervisors(): Promise<iGetSupervisors> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientId: ID }, iGetSupervisors>(functions, "clientPortal-getAllSupervisors");
    const res = await cloudFunction();
    return res.data;
  }

  static async getGeneralSchedules(): Promise<iGetScheduleTemplates> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, iGetScheduleTemplates>(
      functions,
      "clientPortal-getGeneralSchedules",
    );
    const res = await cloudFunction();
    return res.data;
  }

  static async updateGlobalScheduleTemplates(scheduleTemplates: ScheduleTemplateDB[]): Promise<IGetTeamMemberByIdData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<ScheduleTemplateDB[], IGetTeamMemberByIdData>(
      functions,
      "clientPortal-updateGlobalScheduleTemplates",
    );
    const res = await cloudFunction(scheduleTemplates);
    return res.data;
  }

  static async updateGlobalScheduleTemplatesNew(
    scheduleTemplates: ScheduleTemplate[],
  ): Promise<IGetTeamMemberByIdData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<ScheduleTemplate[], IGetTeamMemberByIdData>(
      functions,
      "clientPortal-updateGlobalScheduleTemplates",
    );
    const res = await cloudFunction(scheduleTemplates);
    return res.data;
  }

  static async getQuickSearchResults(search: string, managerId: string): Promise<iGetQuickSearch> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ search: string; managerId: string }, iGetQuickSearch>(
      functions,
      "clientPortal-getQuickSearchResults",
    );
    const res = await cloudFunction({ search, managerId });
    return res.data;
  }

  static async updateEmployeeAssignments({
    assignments,
    employeeId,
    managerId,
  }: {
    assignments: Assignment[];
    employeeId: string;
    managerId: string;
  }) {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        assignments: Assignment[];
        managerId: ID;
        employeeId: ID;
      },
      BaseResponse
    >(functions, "clientPortal-updateEmployeeAssignments");
    const res = await cloudFunction({ assignments, employeeId, managerId });
    return res.data;
  }

  static async getTeamMemberAssignments({
    managerId,
    employeeId,
  }: {
    managerId: ID;
    employeeId: ID;
  }): Promise<iGetTeamMemberAssignments> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        managerId: ID;
        employeeId: ID;
      },
      iGetTeamMemberAssignments
    >(functions, "clientPortal-getTeamMemberAssignments");
    const res = await cloudFunction({ managerId, employeeId });
    return res.data;
  }

  static async getContacts(): Promise<iGetContacts> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, iGetContacts>(functions, "clientPortal-getContacts");
    const res = await cloudFunction();
    return res.data;
  }

  static async updateGlobalContacts(contacts: Contact[]): Promise<IGetTeamMemberByIdData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<Contact[], IGetTeamMemberByIdData>(
      functions,
      "clientPortal-updateGlobalContacts",
    );
    const res = await cloudFunction(contacts);
    return res.data;
  }

  static async getInfoMessages(): Promise<iGetInfoMessages> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, iGetInfoMessages>(functions, "clientPortal-getInfoMessages");
    const res = await cloudFunction();
    return res.data;
  }

  static async updateGlobalInfoMessages(messages: DBMessage[]): Promise<IGetTeamMemberByIdData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<DBMessage[], IGetTeamMemberByIdData>(
      functions,
      "clientPortal-updateGlobalInfoMessages",
    );
    const res = await cloudFunction(messages);
    return res.data;
  }
  static async getClientFolders(clientId: ID): Promise<{ data: DBClientPortalTile[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientId: ID }, { data: DBClientPortalTile[] }>(
      functions,
      "clientPortal-getClientFolders",
    );
    const res = await cloudFunction({ clientId });
    return res.data;
  }

  static async getClientPortalTiles(clientId: ID): Promise<{ data: DBClientPortalTile[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientId: ID }, { data: DBClientPortalTile[] }>(
      functions,
      "clientPortal-getClientPortalTiles",
    );
    const res = await cloudFunction({ clientId });
    return res.data;
  }

  static async getGlobalTiles(clientId: ID): Promise<{ data: DBClientPortalTileGlobal[] }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientId: ID }, { data: DBClientPortalTileGlobal[] }>(
      functions,
      "clientPortal-getGlobalTiles",
    );
    const res = await cloudFunction({ clientId });
    return res.data;
  }

  static async updateMaximoStatus(workOrder: any) {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        workOrder: any;
      },
      BaseResponse
    >(functions, "clientPortal-updateMaximoStatus");
    const res = await cloudFunction({ workOrder });
    return res.data;
  }

  static async createFoldersOmega(title: string, folderId: number): Promise<iCreateFolderOmega> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ title: string; folderId: number }, iCreateFolderOmega>(
      functions,
      "clientPortal-createFoldersOmega",
    );
    const res = await cloudFunction({ title, folderId });
    return res.data;
  }
  static async getClientPortalUsers(clientId: string): Promise<iGetPortalUsers> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ clientId: string }, iGetPortalUsers>(
      functions,
      "clientPortal-getClientPortalUsers",
    );
    const res = await cloudFunction({ clientId });
    return res.data;
  }

  static async deleteMaximoAttachment(id: string, workOrderKey: string): Promise<iDeleteAttachemnt> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ id: string; workOrderKey: string }, iDeleteAttachemnt>(
      functions,
      "clientPortal-deleteMaximoAttachment",
    );
    const res = await cloudFunction({ id, workOrderKey });
    return res.data;
  }

  static async closeWorkOrder(
    wonum: string,
    notes: string,
    actstart: string,
    actfinish: string,
    approvedamount: string,
  ): Promise<iCloseWorkOrderRequest> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        notes: string;
        actstart: string;
        actfinish: string;
        approvedamount: string;
        wonum: string;
      },
      iCloseWorkOrderRequest
    >(functions, "vendorPortal-closeWorkOrder");
    const res = await cloudFunction({ wonum, notes, actfinish, actstart, approvedamount });
    return res.data;
  }

  static async updateMaximoWorkOrderField(id: string, fieldName: string, value: string): Promise<Response> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ id: string; fieldName: string; value: string }, Response>(
      functions,
      "clientPortal-updateMaximoWorkOrderField",
    );
    const res = await cloudFunction({ id, fieldName, value });
    return res.data;
  }

  static async assignResourceInWorkOrder({
    approvedamount,
    wonum,
    laborCode,
    vendorId,
    woacceptscharges,
    managerId,
  }: {
    wonum: string;
    vendorId?: string;
    laborCode?: string;
    woacceptscharges?: boolean;
    approvedamount?: number;
    managerId: string;
  }): Promise<Response> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<
      {
        wonum: string;
        vendorId?: string;
        laborCode?: string;
        woacceptscharges?: boolean;
        approvedamount?: number;
        managerId: string;
      },
      Response
    >(functions, "clientPortal-assignResourceInWorkOrder");
    const res = await cloudFunction({ wonum, approvedamount, laborCode, vendorId, woacceptscharges, managerId });
    return res.data;
  }

  static async getEmployeeVendors(userId: string): Promise<iGetPortalVendors> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ userId: string }, iGetPortalVendors>(
      functions,
      "clientPortal-getEmployeeVendors",
    );
    const res = await cloudFunction({ userId });
    return res.data;
  }

  static async getVendorDetails(vendorId: string): Promise<iGetVendorDetails> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ vendorId: string }, iGetVendorDetails>(
      functions,
      "vendorPortal-getVendorDetails",
    );
    const res = await cloudFunction({ vendorId });
    return res.data;
  }

  static async getDynamicFormList(): Promise<iGetDynamicFormList> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<undefined, iGetDynamicFormList>(functions, "clientPortal-getDynamicFormList");
    const res = await cloudFunction();
    return res.data;
  }

  static async getDynamicFormData(id: string, managerId: string): Promise<iGetDynamicFormData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ id: string; managerId: string }, iGetDynamicFormData>(
      functions,
      "clientPortal-getDynamicFormData",
    );
    const res = await cloudFunction({ id, managerId });
    return res.data;
  }

  static async saveDynamicFormData(id: string, formData: FormData): Promise<iGetClientsAndLocationsArrayData> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ id: string; formData: FormData }, iGetClientsAndLocationsArrayData>(
      functions,
      "clientPortal-saveDynamicFormData",
    );
    const res = await cloudFunction({ id, formData });
    return res.data;
  }
}
