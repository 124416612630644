import { useCallback, useEffect, useRef } from "react";

export const useIsMounted = () => {
  const activeState = useRef<boolean>(false);

  useEffect(() => {
    activeState.current = true;
    return () => {
      activeState.current = false;
    };
  }, []);

  return useCallback(() => {
    return activeState.current;
  }, []);
};
