import React, { Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

import Dropdown from "src/components/Dropdown";
import { colors } from "src/styles";
import { ID, IServiceTypeQuestion } from "src/types";

import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import { FormData } from "../types";
import { DatePicker } from "./datepicker";

type ServiceTypeQuestionProps = {
  question: IServiceTypeQuestion;
  isMobile?: boolean;
  formData: FormData;
  setFormData: Dispatch<SetStateAction<FormData>>;
  disabled?: boolean;
};

const getBooleanValue = (vv: boolean | undefined, def: boolean) => (typeof vv !== "undefined" ? vv : def);

const ServiceTypeQuestion: React.FC<ServiceTypeQuestionProps> = ({
  question,
  isMobile,
  formData,
  setFormData,
  disabled,
}) => {
  const now = new Date();

  const handleChange = (value: string | number | boolean | Date | null, id: ID) => {
    if (value) {
      const isDate = !isNaN(Date.parse(value as string)) && typeof value === "object";

      setFormData((old) => ({
        ...old,
        serviceTypeQuestions: {
          ...old.serviceTypeQuestions,
          [id]: isDate
            ? {
                dateValue: value,
                date: question.datepicker?.date,
                time: question.datepicker?.time,
              }
            : value || "",
        },
      }));
    }
  };

  const handleToggle = (value: string | number | boolean, id: ID) => {
    setFormData((old) => ({
      ...old,
      serviceTypeQuestions: {
        ...old.serviceTypeQuestions,
        [id]: old.serviceTypeQuestions[id]?.includes(value)
          ? old.serviceTypeQuestions[id].filter((el: string | number | boolean) => el !== value)
          : [...old.serviceTypeQuestions[id], value],
      },
    }));
  };

  const renderTypeContent = () => {
    switch (true) {
      case !!question.input:
        return (
          <Input
            disabled={disabled}
            required={getBooleanValue(question.rules?.required, true)}
            placeholder={question.input?.placeholder || "Enter answer"}
            onChange={(e) => handleChange(e.target.value, question.id)}
            value={formData.serviceTypeQuestions[question.id]}
            type={question.rules?.numeric ? "number" : "text"}
          />
        );
      case !!question.textarea:
        return (
          <Textarea
            width="100%"
            disabled={disabled}
            required={getBooleanValue(question.rules?.required, true)}
            placeholder={question.textarea?.placeholder || "Enter answer"}
            onChange={(e) => handleChange(e.target.value, question.id)}
            value={formData.serviceTypeQuestions[question.id]}
          />
        );
      case !!question.select:
        return (
          <Dropdown
            disabled={disabled}
            items={question.select?.options.map((el, i) => ({ ...el, id: i, value: el.value || el.label })) || []}
            onClick={(item) => handleChange(item.value, question.id)}
            noRadius
            defaultValue={formData.serviceTypeQuestions[question.id] || "Select answer"}
            width="100%"
            $isScrollable={Number(question.select?.options?.length) > 10}
          />
        );
      case !!question.radio:
        return (
          <Form.Group>
            {question.radio?.options.map((option, i) => (
              <Form.Check
                disabled={disabled}
                id={`custom-radio-${i}`}
                className="custom-radio"
                key={i}
                value={option.value || option.label}
                type="radio"
                label={option.label}
                onChange={(e) => handleChange(e.target.value, question.id)}
                checked={formData.serviceTypeQuestions[question.id] === (option.value || option.label)}
              />
            ))}
          </Form.Group>
        );
      case !!question.checkbox:
        return (
          <Form.Group>
            {question.checkbox?.options.map((option, i) => (
              <Form.Check
                disabled={disabled}
                className="custom-checkbox"
                id={`custom-checkbox-${i}`}
                key={i}
                value={option.value || option.label}
                type="checkbox"
                label={option.label}
                onChange={(e) => handleToggle(e.target.value, question.id)}
                checked={formData.serviceTypeQuestions[question.id]?.includes(option.value || option.label)}
              />
            ))}
          </Form.Group>
        );
      case !!question.toggle:
        return (
          <Form.Group>
            {question.toggle?.options.map((option, i) => (
              <Form.Check
                disabled={disabled}
                className="custom-toggle"
                id={`custom-toggle-${i}`}
                key={i}
                value={option.value || option.label}
                type="switch"
                label={option.label}
                onChange={(e) => handleToggle(e.target.value, question.id)}
                checked={formData.serviceTypeQuestions[question.id]?.includes(option.value || option.label)}
              />
            ))}
          </Form.Group>
        );
      case !!question.datepicker:
        return (
          <div className="datePicker">
            <DatePicker
              disabled={disabled}
              disableCalendar={true}
              disableClock={true}
              format={`${question.datepicker?.date ? "y/MM/dd " : ""}${question.datepicker?.time ? "hh:mm:ss a" : ""}`}
              onChange={(date) => handleChange(date, question.id)}
              value={formData.serviceTypeQuestions[question.id]?.dateValue || null}
              dayPlaceholder="DD"
              hourPlaceholder="h"
              minutePlaceholder="mm"
              monthPlaceholder="MM"
              secondPlaceholder="ss"
              yearPlaceholder="YYYY"
              minDate={new Date(1930, 0, 1, 0, 0, 0, 0)}
              maxDate={new Date(now.setFullYear(now.getFullYear() + 100))}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Container $isMobile={isMobile}>
      {question.label?.length && (
        <StyledLabel id="field-label">
          {question.label || question.id} {question.rules?.required === false && "(optional)"}:
        </StyledLabel>
      )}
      {renderTypeContent()}
    </Container>
  );
};

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${colors.black.light2};
`;

export const Container = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 40px;

  .datePicker {
    max-width: 300px;
  }

  .datePicker {
    .react-datetime-picker__wrapper {
      border: 1px solid ${colors.grey.light13};
      border-radius: 4px;
      padding: 5px;
    }
  }

  label:not([id="field-label"]) {
    font-size: 14px;
    margin-bottom: 10px;
    color: ${colors.grey.dark};
  }

  input {
    ${(props) => props.$isMobile && `width: 200px;`}
  }

  textarea {
    font-size: 14px;
  }

  .custom-radio input,
  .custom-checkbox input,
  .custom-toggle input {
    border: 1px solid ${colors.green.default};
  }

  .custom-radio input:checked,
  .custom-checkbox input:checked,
  .custom-toggle input:checked {
    background-color: ${colors.green.default};
  }

  .custom-radio input:checked:focus,
  .custom-checkbox input:checked:focus,
  .custom-toggle input:checked:focus {
    box-shadow: none;
  }

  .custom-radio input:focus,
  .custom-checkbox input:focus,
  .custom-toggle input:focus {
    box-shadow: none;
  }

  .custom-radio input:active,
  .custom-checkbox input:active,
  .custom-toggle input:active {
    box-shadow: none;
  }
`;

export default ServiceTypeQuestion;
