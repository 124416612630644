import React from "react";
import styled from "styled-components";

import { Ticket as TicketType } from "src/types";
import { Ticket } from "../Ticket";

export interface TicketsListProps {
  data: any;
  onClick?: (id: string) => void;
  vendorRole?: boolean;
  onCloseWorkOrderClick?: (workOrder: TicketType) => void;
}

export const TicketsList = ({ data, onClick, vendorRole, onCloseWorkOrderClick }: TicketsListProps) => {
  return (
    <>
      {data.map((ticket: TicketType, index: number) => (
        <TicketContainer
          onClick={() => {
            onClick && onClick(ticket.wonum);
          }}
          key={index}
        >
          <Ticket onCloseWorkOrderClick={onCloseWorkOrderClick} vendorRole={vendorRole} ticket={ticket} />
        </TicketContainer>
      ))}
    </>
  );
};

const TicketContainer = styled.div`
  margin-bottom: 10px;
`;
