import { createAction } from "@reduxjs/toolkit";

const ACTION_PREFIX = "WORK_REQUEST";

type RequestMessage = string;

export const setWorkRequestMessage = createAction<RequestMessage>(`${ACTION_PREFIX}/SET_WORK_REQUEST_MESSAGE`);

export const clearWorkRequestMessage = createAction(`${ACTION_PREFIX}/CLEAR_WORK_REQUEST_MESSAGE`);

export const setWorkRequestAlertVariant = createAction<string>(`${ACTION_PREFIX}/SET_WORK_REQUEST_ALERT_VARIANT`);
