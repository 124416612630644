import styled from "styled-components";

export const colors = {
  kleenway: {
    green: "#81C17D",
    greenLight: "#B9E0A5",
    error: "#EC6A5E",
    backgroundDark: "#2E2E30",
  },
  grey: {
    dark: "#555555",
    dark2: "#718096",
    dark3: "#595959",
    dark4: "#B4B4B4",
    dark5: "#999",
    dark6: "#6C6C6C",
    dark7: "#8C8C8C",
    default: "#CBCBCB",
    light1: "#E1E1E1",
    light2: "#F0F0F0",
    light3: "#E5E5E5",
    light4: "#C4C4C4",
    light5: "#979797",
    light6: "#FAFAFA",
    light7: "#EDF2F7",
    light8: "#E8E8E8",
    light9: "#F9F9F9",
    light10: "#EBEAED",
    light11: "#E6E6E6",
    light12: "#D6DDEB",
    light13: "#D8D8D8",
    light14: "#F5F5F5",
    light15: "#E7EAEE",
    light16: "#F7F8F9",
    light17: "#A8ADB7",
    light18: "#F2F2F2",
  },
  white: {
    default: "#FFFFFF",
  },
  yellow: {
    default: "#FFC700",
    light: "#FFF1BF",
    light1: "#FFD200",
  },
  green: {
    default: "#58A942",
    light: "#91D98C",
  },
  red: {
    default: "#CE5347",
    dark: "#EC6A5E",
    light: "#FF9A90",
    light1: "#F9B4AE",
  },
  orange: {
    default: "#FF7A00",
  },
  blue: {
    default: "#208AFD",
  },
  black: {
    dark1: "#1A202C",
    light1: "#3A3A3A",
    light2: "#25324B",
    light3: "#515B6F",
  },
};

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
`;

export const Content = styled.div`
  padding: 15px 30px;
`;

export const ContentFrame = styled.div`
  padding: 30px;
  background-color: ${colors.white.default};
  box-shadow: 0px 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;
