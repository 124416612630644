import { DropdownItem } from "src/components/Dropdown/types";
import { AdjustmentFormValues } from "src/components/molecules/AdjustmentForm/types";
import { ShiftData } from "src/components/molecules/ShiftForm/types";
import { PAY_TYPE } from "src/constants/validation";
import { clientPortalProps } from "src/pages/ClientDetails/ClientDetailsProvider";
import { ActiveEmployee, ActiveVendor } from "src/pages/Tickets/TicketTable";

export type ID = string | number;

export type InfoMessages = {
  firstText?: string;
  secondText?: string;
  welcomeText?: string;
};

export type DBClientUser = {
  id: string;
  clientId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  photo?: string;
  phoneNumber?: string;
};

export type DBVendor = {
  id: string;
  name: string;
  logo: string | undefined;
  address: string;
};

export type DBVendorUser = {
  id: string;
  vendorId: string;
  email: string;
  firstName: string;
  lastName: string;
  photo?: string;
  phoneNumber?: string;
};

export type DBEmployeeUser = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  photo?: string;
  phoneNumber?: string;
  roles?: { [key: string]: boolean };
};

export type Employee = DBEmployeeUser & {
  employeeId: string;
};

export interface Ticket {
  wonum: string;
  name: string;
  client: string;
  clientId: string;
  dateCreated: string;
  location: string;
  description?: string;
  serviceType: string;
  classification: string;
  workType?: string;
  priority?: string;
  status?: string;
  statusDescription?: string;
  priorityId: number;
  clientUserId: string;
  laborId?: string;
  vendorId?: string;
  href?: string;
  pmnum?: string;
  workorderid?: number;
  woacceptscharges: boolean;
  approvedamount?: number;
  targstartdate?: string;
  targcompdate?: string;
}

export interface Client {
  id: string;
  name: string;
  address?: string;
  inactive?: boolean;
  settings?: {
    timezone?: string;
  };
  contacts?: DBClientContact[];
  kleenwayContacts?: string[];
  logo?: string;
  infoMessages?: InfoMessages;
  countryCode?: string;
  clientPortal?: clientPortalProps;
  managerId: string;
}

export const enum PriorityLevels {
  EMERGENCY = "Emergency",
  LOW = "Low",
  HIGH = "High",
  IMMEDIATE = "Immediate",
}

export interface WorkType {
  name: string;
  type: string;
  id: string;
}

export interface PriorityLevelList {
  [clientId: string]: PriorityLevel[] | undefined;
}

export interface PriorityLevel {
  name: string;
  priority: number;
  hoursDelay: number;
  color: string;
}

export interface SortingOption {
  value: string;
  id: string;
  filter: any;
}

export type DBClientPortalTileChanges = {
  icon?: string;
  id?: ID;
  sortOrder?: number;
  title?: string;
  enabled?: boolean;
} & (
  | {
      type?: "folder";
      folderId?: ID;
      folder?: string;
      url?: never;
    }
  | {
      type?: "url";
      folderId?: never;
      folder?: never;
      url?: string;
    }
);

export type DBClientPortalTile = {
  id?: ID;
  tileType: string;
  sortOrder?: number;
  icon?: string;
  title: string | undefined;
  editMode?: boolean;
  isNew?: boolean;
  changes?: DBClientPortalTileChanges | Record<string, unknown>;
  enabled?: boolean;
  isValid?: boolean;
  type?: string;
} & (
  | {
      type: "folder";
      folderId?: ID;
      folder: string;
      url?: never;
    }
  | {
      type: "url";
      folderId?: never;
      folder?: never;
      url: string;
    }
);

export type DBClientPortalTileGlobal = DBClientPortalTile;

export type FileType = "folder" | "file";

export type IOmegaItem = {
  id: ID;
  type: FileType;
  name: string;
  index: number;
};

export type IOmegaParentFolder = {
  id: ID;
  name: string;
  children: IOmegaItem[];
};

export type IBreadCrumb = {
  id: ID | null;
  name: string;
};

export type DBClassification = {
  name: string;
  description?: string;
};

export type ServiceTypeItem = {
  id: string;
  name: string;
  description?: string;
  icon?: string;

  classifications: Record<string, DBClassification | null>;

  sortOrder?: number;
  showOnPublic?: boolean;
  longDescription?: string;
};

export type ServiceTypeList = {
  [key: string]: ServiceTypeItem[] | undefined;
};

export type ServiceType = {
  serviceTypesAmount: number;
  serviceTypes: ServiceTypeList;
};

export interface WorkOrder {
  parent?: string;
  parentOrder?: {
    wonum: string;
    description: string;
  };
  locationData: {
    label: string;
    address: string;
  };
  wochildren?: Array<WorkOrder>;
  localref?: string;
  aos: boolean;
  estservcost: number;
  pluscismobile: boolean;
  wolablnk?: string;
  pluscfrequency?: number;
  assetfilterby_description?: string;
  ownergroup?: string;
  assetlocpriority?: number;
  calcshift?: string;
  serviceType: string;
  workType: string;
  priority: string;
  priorityId: number;
  workpackmtlstatus?: string;
  plusporigqpricematerial?: number;
  wol4?: string;
  istask: boolean;
  plusprevnum?: number;
  dupflag?: string;
  storeroommtlstatus_description?: string;
  estatapprmatcost: number;
  flowaction?: string;
  taskid?: number;
  pcpchangeby?: string;
  pluspcustponum?: string;
  wopriority?: number;
  pluscloop: boolean;
  plusppoolitemnum?: string;
  wol3?: number;
  wol2?: string;
  actmatcost: number;
  generatedforpo?: string;
  wol1?: string;
  fnlconstraint?: string;
  classification: string;
  nestedjpinprocess: boolean;
  dateCreated: string;
  pluspcustomer?: {
    name: string;
    customer: string;
  };
  estatapprtoolcost: number;
  pluspactdeliverydate?: string;
  hasfollowupwork: boolean;
  route?: string;
  pmnum?: string;
  flowactionassist: boolean;
  ignorediavail: boolean;
  pcacthrs?: number;
  fincntrlid?: string;
  amcrew?: string;
  pluspporeq: boolean;
  launchentryname?: string;
  pluspallowquote: boolean;
  pointnum?: string;
  pluscfrequnit_description?: string;
  plusporigqprice?: number;
  commodity?: string;
  pluscfrequnit?: string;
  estlabhrs: number;
  classstructure?: WorkOrderClassstructure[];
  assignedownergroup?: string;
  dirissuemtlstatus_description?: string;
  pluscoverduedate?: string;
  workpackmtlstatus_description?: string;
  pmextdate?: string;
  estoutlabcost?: number;
  plusptargdeliverydate?: string;
  vendor?: string;
  crewworkgroup?: string;
  disabled: boolean;
  plusporigqtypeservice?: string;
  estdur: number;
  calendar?: string;
  fctaskid?: string;
  description_longdescription?: string;
  environment_longdescription?: string;
  plusppoolitemsetid?: string;
  plusppoolnum?: string;
  estatapprintlabhrs?: number;
  statusdate: string;
  estatapprtotalcost?: number;
  worklog?: Worklog[];
  repairfacility?: string;
  acttoolcost: number;
  pluspmaxprice?: number;
  actlabcost: number;
  estatapprlabhrs: number;
  respondby?: string;
  estatapprlabcost: number;
  lastcopylinkdate?: string;
  routestopid?: number;
  assetfilterby: string;
  _rowstamp?: string;
  siteid: string;
  crewid_description?: string;
  worklocation?: string;
  suspendflow: boolean;
  status_description?: string;
  actintlabhrs?: number;
  targetdesc?: string;
  calcpriority?: number;
  chargestore: boolean;
  outlabcost: number;
  orgid: string;
  schedfinish?: string;
  justifypriority?: string;
  inctasksinsched: boolean;
  plusplinestatus?: string;
  cinum?: string;
  flowcontrolled: boolean;
  storeroommtlstatus?: string;
  actstart?: string;
  description?: string;
  externalrefid?: string;
  assetuser?: string;
  estatapproutlabcost?: number;
  djpapplied: string;
  plusporigqtypematerial_description?: string;
  endfeaturelabel?: string;
  sneconstraint?: string;
  estoutlabhrs?: number;
  wosequence?: number;
  actintlabcost?: number;
  plusporigqtypelabor_description?: string;
  owner?: string;
  calcorgid?: string;
  wolo10?: number;
  pluspresponseplan?: string;
  pluspnextbillmemo?: string;
  environment?: string;
  esttotalcost?: number;
  wogroup?: string;
  assetreconrstkt?: string;
  schedstart?: string;
  location?: string;
  risk?: string;
  pluspcalcorgid?: string;
  haschildren: boolean;
  apptrequired: boolean;
  pluspcalccalnum?: string;
  historyflag: History;
  plusporigqpricelabor?: number;
  pluscnextdate?: string;
  parentchgsstatus: boolean;
  plusporigqpriceservice?: number;
  lms: boolean;
  wojp1?: string;
  wojp2?: string;
  wojp3?: string;
  genforpolineid?: number;
  wojp4?: number;
  wojp5?: string;
  acttotalcost?: number;
  plusporigqtypeservice_description?: string;
  href?: string;
  backoutplan_longdescription?: string;
  pmduedate?: string;
  contract?: string;
  pctaskcomp?: number;
  actlabhrs: number;
  measurementvalue?: number;
  assetcust?: string;
  pluspagreement?: string;
  intshift?: string;
  phone?: string;
  actservcost: number;
  reqasstdwntime: boolean;
  estmatcost: number;
  targcompdate?: string;
  origrecordclass?: string;
  status: string;
  pcphys?: number;
  commoditygroup?: string;
  problemcode?: string;
  esttoolcost: number;
  pluspcalcshift?: string;
  reportedby?: string;
  currentvalue?: number;
  los: boolean;
  reasonforchange?: string;
  feature?: string;
  pluscjprevnum?: number;
  assetnum?: string;
  persongroup?: string;
  crewid?: string;
  kleen_custresponse?: string;
  outmatcost: number;
  doclinks?: {
    [k: string]: unknown;
  }[];
  onbehalfof?: string;
  observation?: string;
  changeby?: string;
  interruptible: boolean;
  remdur?: number;
  sendersysid?: string;
  estlabcost: number;
  lead?: string;
  wonum: string;
  downtime: boolean;
  workorderid: number;
  pluspcostcenter?: string;
  whomischangefor?: string;
  wojo1?: string;
  wojo2?: string;
  wojo3?: string;
  wojo4?: number;
  wojo5?: string;
  wojo6?: string;
  wojo7?: string;
  wojo8?: string;
  sourcesysid?: string;
  ownersysid?: string;
  actoutlabcost?: number;
  estatapprservcost: number;
  pluspallowquotelabor: boolean;
  plusppricesched?: string;
  faildate?: string;
  ignoresrmavail: boolean;
  origrecordid?: string;
  outtoolcost: number;
  wopriority_description?: string;
  estatapproutlabhrs?: number;
  estatapprintlabcost?: number;
  firstapprstatus?: string;
  pluspnextbillstat_description?: string;
  pmnextduedate?: string;
  remarkdesc_longdescription?: string;
  verification?: string;
  changebyparent: boolean;
  woisswap: boolean;
  woacceptscharges: boolean;
  approvedamount?: number;
  repairlocflag: boolean;
  changedate?: string;
  woclass_description?: string;
  plusplinestatus_description?: string;
  pluspbillapproved: boolean;
  djperror?: string;
  woclass: string;
  backoutplan?: string;
  actoutlabhrs?: number;
  woserviceaddress?: any;
  _id?: string;
  displaywonum?: string;
  supervisor?: string;
  calccalendar?: string;
  targstartdate?: string;
  ams: boolean;
  jobtaskid?: number;
  reportdate?: string;
  displaytaskid?: number;
  plusporigqtypelabor?: string;
  pluspnextbillstat?: string;
  plusporigqtypematerial?: string;
  newchildclass: string;
  pluspallowquotematerial: boolean;
  _imagelibref?: string;
  worts3?: string;
  worts4?: string;
  worts1?: string;
  plusporigqtype?: string;
  worts2?: string;
  worts5?: number;
  plusporigqtype_description?: string;
  measuredate?: string;
  availstatusdate?: string;
  classstructureid?: string;
  assignment?: WorkOrderAssignment[];
  pluspallowquoteservice: boolean;
  assetwarrantynotice: boolean;
  worktype?: string;
  estintlabhrs?: number;
  pluspcustchacct?: string;
  inspector?: string;
  woeq1?: string;
  woeq2?: string;
  woeq3?: string;
  genforporevision?: number;
  woeq4?: string;
  woeq5?: number;
  woeq6?: string;
  glaccount?: string;
  pcpchangedate?: string;
  woeq7?: number;
  failurecode?: string;
  milestone: boolean;
  woeq8?: string;
  dirissuemtlstatus?: string;
  woeq9?: string;
  wolo2?: string;
  wolo1?: string;
  fcprojectid?: string;
  woeq11?: string;
  wolo4?: string;
  pluscphyloc?: string;
  woeq12?: number;
  wolo3?: string;
  woeq13?: string;
  wolo6?: number;
  locations?: any;
  actfinish?: string;
  estintlabcost?: number;
  woeq14?: number;
  wolo5?: string;
  inspformnum?: string;
  wolo8?: number;
  wolo7?: string;
  repfacsiteid?: string;
  woeq10?: string;
  wolo9?: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  email: string;
  name: string;
  clientUserId: string;
  highrisk?: string;
  capital?: string;
  wpservice?: WPService[];
  wplabor?: WPLabor[];

  [k: string]: unknown;
}

export interface WPLabor {
  apptrequired?: boolean;
  wplaborid?: string;
  plusplistprice?: number;
  quantity?: number;
  ratehaschanged?: boolean;
  localref?: string;
  plusprequiredate?: string;
  wplaboruid?: number;
  orgid?: string;
  laborcode?: string;
  _rowstamp?: string;
  rate?: number;
  href?: string;
  laborhrs?: number;
}

export interface WPService {
  plusplineprice?: number;
  plusplistprice?: number;
  localref?: string;
  unitcost?: number;
  linetype?: string;
  unitcosthaschanged?: false;
  description?: string;
  _rowstamp?: string;
  rate?: number;
  vendor?: string;
  href?: string;
  itemqty?: number;
  linecost?: number;
  hours?: number;
  ratehaschanged?: false;
  wpitemid?: number;
  linetype_description?: string;
  orgid?: string;
  pluspfeatureprice?: number;
  directreq?: true;
  mktplcitem?: false;
  requiredate?: string;
  itemsetid?: string;
  requestby?: string;
  pluspcustprovided?: false;
}
export interface WorkOrderClassstructure {
  useclassindesc: boolean;
  parent?: string;
  localref?: string;
  classstructureid: string;
  show: boolean;
  description?: string;
  classstructure?: WorkOrderClassstructure[];
  pluspisglobal: boolean;
  plusprolldownattr: boolean;
  plusprolldown: boolean;
  type?: string;
  classificationid: Classification;
  orgid?: string;
  pluspinsertcustomer?: string;
  _rowstamp?: string;
  _imagelibref?: string;
  showinassettopo?: boolean;
  sortorder?: number;
  href?: string;
  _id?: string;
  classstructureuid: number;
  haschildren: boolean;
  genassetdesc: boolean;

  [k: string]: unknown;
}

export interface Worklog {
  modifyby: string;
  pluspmaxprice?: number;
  localref?: string;
  pluspesttype_description?: string;
  createdate: Date;
  description?: string;
  clientviewable: boolean;
  pluspestenddt?: string;
  assignmentid?: number;
  langcode: string;
  pluspestremark?: string;
  _rowstamp?: string;
  createby: string;
  logtype: string;
  modifydate: string;
  _imagelibref?: string;
  pluspminprice?: number;
  recordkey: string;
  assignreplocid?: number;
  worklogid: number;
  href?: string;
  pluspesttype?: string;
  class: string;
  logtype_description?: string;
  plusppriceest?: number;
  description_longdescription?: string;
  pluspeststatusr?: string;
  orgid?: string;
  pluspeststatdt?: string;
  hasld: boolean;
  pluspeststat?: string;
  pluspeststat_description?: string;
  _id?: string;

  [k: string]: unknown;
}

export interface WorkOrderAssignmentLabor {
  ownersysid?: string;
  labinventorysite?: string;
  localref?: string;
  endlocation?: string;
  externalrefid?: string;
  labinventoryloc?: string;
  laborid: number;
  type?: WorkType;
  _rowstamp?: string;
  lbsinterval?: number;
  _imagelibref?: string;
  crewworkgroup?: string;
  crewid?: string;
  lbsdatafromwo: boolean;
  personid: string;
  href?: string;
  crewid_description?: string;
  worklocation?: string;
  ytdhrsrefused: number & string;
  type_description?: string;
  startlocation?: string;
  status_description?: string;
  ytdothrs: number & string;
  reportedhrs: number & string;
  sendersysid?: string;
  orgid: string;
  laborcode: string;
  startlocsiteid?: string;
  person?: WorkOrderPerson[];
  endlocsiteid?: string;
  worksite?: string;
  _id?: string;
  sourcesysid?: string;
  availfactor: number & string;
  status?: string;

  [k: string]: unknown;
}

export type BaseResponse<T = void> = {
  status: "error" | "success";
  message: string;
  data?: T;
};

export interface WorkOrderPersonPhone {
  _rowstamp?: string;
  localref?: string;
  _imagelibref?: string;
  phoneid: number;
  href?: string;
  _id?: string;
  phonenum: string;
  type?: string;
  isprimary: boolean;
  type_description?: string;

  [k: string]: unknown;
}

export interface ClientPortalTargetTime {
  targetStartDayDelay: number;
  targetStartTime: string;
}

export interface WorkOrderAssignment {
  apptrequired: number;
  wplaborid?: string;
  localref?: string;
  craft?: string;
  scheduledate?: string;
  appointment: boolean;
  startdate?: string;
  assignmentid: number;
  _rowstamp?: string;
  skilllevel?: string;
  _imagelibref?: string;
  vendor?: string;
  crewworkgroup?: string;
  href?: string;
  contractnum?: string;
  status_description?: string;
  workdate?: string;
  changeby?: string;
  finishdate?: string;
  amcrewtype?: string;
  changedate?: string;
  orgid: string;
  laborcode?: string;
  labor?: WorkOrderAssignmentLabor[];
  amcrew?: string;
  _id?: string;
  worklog: boolean;
  parentassignid?: number;
  laborhrs?: string;
  status: string;

  [k: string]: unknown;
}

export interface Classification {
  hierarchypath?: string;
  localref?: string;
  show: boolean;
  description?: string;
  classspec?: {
    [k: string]: unknown;
  }[];
  pluspisglobal: boolean;
  plusprolldown: boolean;
  type?: string;
  classificationid: string;
  pluspinsertcustomer?: string;
  _rowstamp?: string;
  _imagelibref?: string;
  showinassettopo?: boolean;
  siteid?: string;
  sortorder?: number;
  href?: string;
  genassetdesc: boolean;
  useclassindesc: boolean;
  description_longdescription?: string;
  classstructureid: string;
  objectname?: Record<string, unknown>;
  plusprolldownattr: boolean;
  orgid?: string;
  classusewith?: {
    [k: string]: unknown;
  }[];
  _id?: string;

  [k: string]: unknown;
}

export interface WorkOrderPerson {
  country?: string;
  ownersysid?: string;
  personuid: number;
  birthdate?: string;
  localref?: string;
  county?: string;
  language?: string;
  ownergroup?: string;
  pluspcompanyorgid?: string;
  hiredate?: string;
  pcardnum?: string;
  wfmailelection?: string;
  usernotftype_description?: string;
  employeetype_description?: string;
  _rowstamp?: string;
  primarysms?: string;
  wfmailelection_description?: string;
  href?: string;
  vip?: number;
  status_description?: string;
  wopriority?: number;
  nextevaldate?: string;
  timezone_description?: string;
  pcardexpdate?: string;
  stateprovince?: string;
  pluspcustomer?: {
    name: string;
    customer: string;
  };
  lastname?: string;
  jobcode_description?: string;
  saddresscode?: string;
  locale_description?: string;
  acceptingwfmail: boolean;
  phone?: WorkOrderPersonPhone[];
  regiondistrict?: string;
  addressline3?: string;
  caltype_description?: string;
  addressline2?: string;
  addressline1?: string;
  _id?: string;
  pcardtype?: string;
  supervisor?: string;
  caltype?: string;
  status: string;
  firstname?: string;
  usernotftype?: string;
  transemailelection_description?: string;
  city?: string;
  timezone?: string;
  loctoservreq: boolean;
  externalrefid?: string;
  billtoaddress?: string;
  locale?: string;
  title?: string;
  pcardverification?: string;
  delegate?: string;
  delegatefromdate?: string;
  droppoint?: string;
  vip_description?: string;
  _imagelibref?: string;
  lastevaldate?: string;
  postalcode?: string;
  personid: string;
  department?: string;
  deviceclass?: number;
  email?: WorkOrderPersonEmail[];
  employeetype?: string;
  pluspcustvendor?: string;
  terminationdate?: string;
  locationorg?: string;
  classstructureid?: Classification;
  locationsite?: string;
  deviceclass_description?: string;
  im_id?: string;
  sendersysid?: string;
  transemailelection: string;
  shiptoaddress?: string;
  statusdate: string;
  dfltapp?: string;
  pluspcustvndtype_description?: string;
  displayname?: string;
  delegatetodate?: string;
  jobcode?: string;
  location?: string;
  pluspcustvndtype: string;
  pcardtype_description?: string;
  sourcesysid?: string;

  [k: string]: unknown;
}

export interface WorkOrderPersonEmail {
  _rowstamp?: string;
  localref?: string;
  _imagelibref?: string;
  emailaddress: string;
  emailid: number;
  href?: string;
  _id?: string;
  type?: string;
  isprimary: boolean;
  type_description?: string;

  [k: string]: unknown;
}

export type DBObject<T> = {
  [key in ID]: T;
};

export type LocationForTable = {
  id: ID;
  label: string;
  clientName?: string;
  address?: string;
  timezone?: string;
  supervisorName?: string;
};

export type ClientSpecificLocationForTable = {
  id: ID;
  label: string;
  address?: string;
  timezone?: string;
  supervisorName?: string;
};

export type DBLocation = {
  id?: string;
  clientId: string | number;
  label: string;
  assignedSupervisorId?: string | number | boolean | null;
  evacuationPlan?: string;
  address?: string;
  contacts?: DBObject<DBClientContact>;
  position?: DBLocationPosition | null;

  // TODO to add types later

  "sub-locations"?: any;

  scopeOfWork?: any;
  safetyTalk?: any;
  floors?: DBObject<string>;
  kpi?: any;

  KPIs?: {
    qualityAuditsTarget: any;
    qualityAuditsCache: any;
  };

  // contain object like { "202205": 10, "202206": 5 }, where key is YYYYMM
  // NOTE: the '1' should be used by default, if value was not defined
  expectedAuditsAmount?: Record<string, number>;

  maximoSynchronized?: boolean; // contain a status of synchronization
  timezone?: string;
  inactive?: boolean;
  countryCode: string;
  regionCode: string;
  firstDayOfWeek: number;
  firstDayOfYear: number;
  hoursPerDay: number;
  hoursPerWeek: number;
  overtimeCoefficient: number;
  infoMessages?: InfoMessages | null;
};

export interface CompletedStatus {
  abbreviation: string;
  id: string;
  status: string;
}

export type DBClientContact = {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  phone?: string;
  jobDescription?: string;
  title?: string;
  isValid?: boolean;
};

export type DBKleenwayContact = {
  id: ID;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  title?: string;
};

export type DBDropdownListSizes = Record<string, number>;

export type DBMessage = {
  id: string;
  text: string;
  color?: string;
  fontSize?: string;
  label: string;
  isNew?: boolean;
};

export type DBPayPeriodType = {
  description: string;
  label: string;
  payPeriodPayloadId: string;
};

export type PayPeriodType = DBPayPeriodType & {
  id: ID;
  employeesNumber: number;
};

export type DBPayPeriod = {
  countryCode: string;
  payPeriodTypeCode: string;
  year: string;
  number: string;
  startDate: string;
  endDate: string;
};

export interface MaximoDocument {
  description: string;
  fileName: string;
  href: string;
  attachmentId: string;
  error?: string;
  state?: string;
  file?: File;
}

export type DBLocationPosition = {
  lat: string;
  long: string;
};

type WhereFilter = ((string | string[])[] | (string | number)[] | { [key: string]: any })[];
type OrderByFilter = (string | string[])[];

export interface WorkOrderFilters {
  where: WhereFilter;
  orderBy: OrderByFilter;
  pageSize: number;
  pageNum?: number;
}

export type searchType = {
  value?: string;
  applied?: boolean;
  completedValue?: string;
  completedApplied?: boolean;
  plannedValue?: string;
  plannedApplied?: boolean;
  approvalRequiredValue?: string;
  approvalRequiredApplied?: boolean;
};

export type TableHeadersTypes = {
  value: string;
  applied: boolean;
  onChange: (value: string) => void;
  onClickCancel: () => void;
  handleApplyCall: () => void;
  handleClearCall: () => void;
  defaultItem: DropdownItem;
  defaultDirection: DropdownItem;
  items: DropdownItem[];
  directions: DropdownItem[];
  onDropdownItemClick: (sortingOption: DropdownItem) => void;
  onDropdownDirectionClick: (sortingDirection: DropdownItem) => void;
};

export interface WorkOrderData {
  workOrders: Ticket[];
  workOrderCount: number;
  vendors: ActiveVendor[];
  employees: ActiveEmployee[];
}

export type UnregisteredUserData = {
  clientId: ID;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  companyCode: string;
  captcha: any;
};

interface IServiceTypeQuestionOptions {
  label: string;
  value?: string;
}

export interface IServiceTypeQuestion {
  id: ID;
  label?: string;
  input?: {
    placeholder: string;
    errorMessage: string;
  };
  textarea?: {
    placeholder: string;
    errorMessage: string;
  };
  select?: {
    options: IServiceTypeQuestionOptions[];
  };
  radio?: {
    options: IServiceTypeQuestionOptions[];
  };
  toggle?: {
    options: IServiceTypeQuestionOptions[];
  };
  checkbox?: {
    options: IServiceTypeQuestionOptions[];
  };
  datepicker?: {
    date: boolean;
    time: boolean;
  };
  rules?: {
    required: boolean;
    numeric: boolean;
  };
}

export type TimeReportPair = {
  payPeriodDayId: string;
  clockInReportId: string | null;
  clockOutReportId: string | null;
  clockInDate: string | null;
  clockOutDate: string | null;
  totalRawHours?: number;
  employeeId: ID;
  locationId: ID;
  locationDisplayName: string;
  payPeriodId: string;
  clientId: ID;
  employeeIdLocationId: string;
  payPeriodIdClientId: string;
  date: string;
  timestamp: number;
  timezoneLocation: string;
};

export type StatDay = {
  name: string;
};

export type PayPeriodDay = {
  payPeriodId: string;
  date: string;
  isWeekend: boolean;
  isStat: StatDay | false;
};

export type EnrichedPayPeriodDay = PayPeriodDay & {
  date: string;
  scheduledHours: number;
  rawHours: number;
  approvedHours: number;
  classification: string;
  locations: {
    [key: string]: TimeReportPair[];
  };
};

export type EmployeePayrollData = {
  employeeId: ID;
  employeeDisplayName: string;
  payPeriodDays: EnrichedPayPeriodDay[];
  totalScheduledHours: number;
  totalRawHours: number;
  totalApprovedHours: number;
  hasSeveralClients: boolean;
  allClientsScheduledHours: number;
  allClientsTotalOvertimeHours: number;
  allClientsTotalRegularHours: number;
  stat: number;
  statWorked: number;
  adjustment: Adjustment;
  salaryAmount: number;
  payType: string;
  roles?: EmployeeRoles;
};

export type EmployeeRoles = {
  vendor: boolean;
};

export interface AdjustmentReason {
  code: string;
  label: string;
  priority?: number;
}

export type Adjustment = {
  adjustmentAmount: number;
  adjustmentComment: string;
  adjustmentReason: AdjustmentReason;
};
export type EmployeeAdjustment = {
  adjustment: AdjustmentFormValues;
  employeeId: string;
};
export type excelExport = {
  baseURL: string;
  id: string;
  payPeriod?: string;
  clientName: string;
  payPeriodName?: string;
  excelType: string;
};
export type ExcelExportDateProps = {
  fromDate?: string;
  toDate?: string;
};
// TEAM MEMBERS//
export interface TeamMember {
  employeeId: string;
  managerId: string;
  employeeDisplayName: string;
  onSite: Record<string, string>;
  clients: { id: ID; name: string }[];
  locations: { id: ID; name: string; clientId: string }[];
  roles: {
    supervisor?: string;
    employee?: string;
    manager?: string;
  };
}

export interface TeamMemberById {
  firstName: string;
  middleName: string;
  lastName: string;
  employeeId: ID;
  roles: string[];
  payPeriodType: string;
  inductionDate: string;
  trainingDate: string;
  clearanceDate: string;
  phone: string;
  email: string;
  address: string;
  isOnSite?: boolean;
  profilePicture: File | null | string;
  title?: string;
  vacationAccrualRate: number | null | string;
  salaryAmount?: number;
  hourlyRate?: number;
  payType?: PAY_TYPE;
}

// SCHEDULES//

export enum DaysByAbbr {
  mon = "MO",
  tue = "TU",
  wed = "WE",
  thu = "TH",
  fri = "FR",
  sat = "SA",
  sun = "SU",
}

export enum AbbrByButtonDay {
  MO = "mon",
  TU = "tue",
  WE = "wed",
  TH = "thu",
  FR = "fri",
  SA = "sat",
  SU = "sun",
}

export interface ScheduleDay {
  end?: string;
  start?: string;
}

export interface ScheduleTemplateShifts {
  mon?: Array<ScheduleDay>;
  tue?: Array<ScheduleDay>;
  wed?: Array<ScheduleDay>;
  thu?: Array<ScheduleDay>;
  fri?: Array<ScheduleDay>;
  sat?: Array<ScheduleDay>;
  sun?: Array<ScheduleDay>;
}
export interface ScheduleTemplateDB {
  id: string;
  label: string;
  shifts: ScheduleTemplateShifts;
  isUsedByEmployees: boolean;
  isNew?: boolean;
}

// QUICK SEARCH//

export interface QuickSearchEntity {
  entity: string;
  data: Record<string, any>[];
}

//ASSIGNMENTS//
export type Assignment = {
  id: string;
  employeeId: string;
  clientId: string;
  locationId: string;
  schedule?: TeamMemberSchedule;
  managerId: string;
};

export type AssignmentDB = {
  id: string;
  employeeId: string;
  clientId: string;
  locationId: string;
  schedule?: TeamMemberSchedule;
  employeeDisplayName: string;
  clientDisplayName: string;
  locationDisplayName: string;
  managerId: string;
  countryCode?: string;
  regionCode?: string;
  vacationAccrualRate?: number;
  isShared?: boolean;
  payPeriodType: string;
};

export type TeamMemberSchedule = {
  template?: string;
  type?: string;
  shifts?: ShiftData[];
};

export type FieldError = {
  message?: string;
};

export type DynamicField = {
  id: string;
  label: string;
  sortOrder: number;
  isActive?: boolean;
  isVisibleToPublic?: boolean;
  isVisibleToClient?: boolean;
  defaultValue?: string;
  rules?: {
    required?: boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
  };
  hidden?: true;
  text?:
    | boolean
    | {
        type?: string;
        placeholder: string;
      };
  textarea?:
    | boolean
    | {
        type?: string;
        placeholder: string;
      };
  dropdown?: DynamicDropdown;
  checkbox?: {
    options: DynamicOptions[];
  };
  radioGroup?: {
    options: DynamicOptions[];
  };
  file?: {
    accept: string[];
    multiple: boolean;
    folderName: string;
  };
  datepicker?: {
    type?: string;
    placeholder?: string;
    variant?: string;
  };
};

export type DynamicDropdown = {
  placeholder?: string;
  options: DynamicOptions[];
};
export type DynamicOptions = {
  label: string;
  value: string;
  timezone?: string;
  clientId?: string;
};

export type DynamicFormField = {
  id: string;
  name: string;
  hook: string;
  fields: DynamicField[];
  email?: EmailMetaData;
};

export type EmailMetaData = {
  subject: string;
  template: string;
  emails: string[];
  cc: string[];
  bcc: string[];
};
export type OnBoardingFormRequest = {
  formData: OnBoardingForm;
  emailData: EmailMetaData;
};
export type OnBoardingForm = {
  requestor: string;
  applicantEmail: string;
  applicantFirstName: string;
  applicantLastName: string;
  client: string;
  employmentType: string;
  location: string;
  payGroup: string;
  payRate: string;
  payType: string;
  startDate: string;
  urgent: string;
  hoursOfWork: string;
  reportingTo: string;
  vacation: string;
  benefits: string;
  retirementPlan: string;
  title: string;
};

export type FirebaseFile = {
  base64: string;
  contentType: string;
  fileName: string;
  folderName: string;
};

export type DemoFormField = {
  hidden: string;
  textField: string;
  decimalField: string;
  numberField: string;
  emailField: string;
  dropDownField: string;
  checkboxField: string;
  dateField: string;
  timeField: string;
  singleFileField: FirebaseFile[];
  multipleFileField: FirebaseFile[];
};
export type DemoFormRequest = {
  formData: DemoFormField;
  emailData: EmailMetaData;
};
export interface ActiveResouces {
  vendors: ActiveVendor[];
  employees: ActiveEmployee[];
}

export interface FileFilterResult {
  supportedFiles: File[];
  unsupportedFiles: File[];
}

export interface DBContract {
  porequired: boolean;
  vendtermallowed: boolean;
  paymentsched: boolean;
  historyflag: boolean;
  totalbasecost: number;
  contracttype_description: string;
  description: string;
  currencycode: string;
  startdate?: string;
  enddate?: string;
  renewaldate?: string;
  totalcost: number;
  _rowstamp: string;
  buyahead: boolean;
  vendor: string;
  contractid: 502;
  href: string;
  contractnum: string;
  inclusive5: boolean;
  inclusive4: boolean;
  status_description: string;
  changeby: string;
  processclaim: boolean;
  changedate: string;
  revisionnum: 0;
  hasinsurance: boolean;
  inclusive1: boolean;
  inclusive3: boolean;
  orgid: string;
  inclusive2: boolean;
  statusdate: string;
  inspectionrequired: boolean;
  contracttype: string;
  extendable: boolean;
  custtermallowed: boolean;
  status: string;
  contractline: ContractLine[];
}
interface ContractLine {
  preRequired: boolean;
  commodity: string;
  chgPriceOnUse: boolean;
  localRef: string;
  unitCost: number;
  contractLineNum: number;
  lineType: string;
  commodityGroup: string;
  description: string;
  rowStamp: string;
  lineStatus: string;
  href: string;
  linecost: number;
  enteredDate: string;
  chgTypeOnUse: boolean;
  lineTypeDescription: string;
  hasPaymentSchedule: boolean;
  itemNum: string;
  inspectionRequired: boolean;
  enteredBy: string;
  contractType: string;
  itemSetId: string;
  lineCost2: number;
  contractLineId: number;
  orderQty: number;
}
