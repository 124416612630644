import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { SelectBox, SelectBoxContainer, SelectedItem } from "src/components/Dropdown/styles";
import { colors } from "src/styles";
import styled, { css } from "styled-components";
import { MobileProps } from "./types";

export const PhotoInput = styled.div<MobileProps>`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 100%;
  max-width: 800px;
  border-bottom: 1px solid ${colors.grey.light12};
  border-top: 1px solid ${colors.grey.light12};
  padding: 30px 0;

  label {
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 195px;

    color: ${colors.black.light2};
    white-space: nowrap;
    margin-right: 20px;
    display: flex;
    align-items: center;

    span {
      display: block;
      font-size: 14px;
      font-weight: 400;
      padding-left: 5px;
    }

    ${(props) => props.$isMobile && `width: 100px;`}
  }
`;

export const StyledForm = styled(Form)`
  padding-top: 20px;
`;

export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  margin: 30px;
  background-color: ${colors.white.default};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubmitContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin: 0 10px;
  }

  label {
    margin-left: 5px;

    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 12px;
        width: 80px;
      `}
  }

  ${ButtonContainer} {
    margin-top: 10px;
  }
`;

export const ErrorMessage = styled.p`
  color: ${colors.red.default};
  font-size: 14px;
  text-align: center;
`;

export interface InputRowProps extends MobileProps {
  $marginBottom?: string;
  isSelectSearch?: boolean;
}

export const InputRow = styled.div<InputRowProps>`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ $marginBottom }) => `${$marginBottom === undefined ? "40" : $marginBottom}px`};
  max-width: 580px;
  width: 100%;
  position: relative;

  & div {
    width: 100%;
  }

  ${(props) =>
    props.topLabel &&
    css`
      align-items: flex-start;
    `}

  div {
    font-size: 14px;

    p {
      font-style: italic;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
    padding-top: 10px;

    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 12px;
        width: 100px;
      `}
  }

  input {
    ${(props) =>
      props.$isMobile &&
      css`
        width: 200px;
      `}
  }

  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;

export const HintIcon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  height: 20px;
  width: 20px;
  position: absolute;
  right: -25px;
`;

export const InputsContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  border-top: ${({ showTopLine }) => showTopLine && `1px solid ${colors.grey.light13}`};
  padding-top: ${({ showTopLine }) => showTopLine && "30px"};

  border-bottom: ${({ isVerify }) => isVerify && `1px solid ${colors.grey.light13}`};
  margin-bottom: ${({ isVerify }) => isVerify && "30px"};

  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectedItem} {
        font-size: 12px;
      }
    `}
  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBox} {
        width: 200px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      ${InputRow} {
        margin-bottom: 20px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      label {
        font-size: 12px;
        width: 100px;
      }

      input {
        font-size: 12px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBoxContainer} {
        height: 25px;
      }
    `};
`;

export const InputsSection = styled(InputsContainer)`
  margin-bottom: 40px;
  border-bottom: 1px solid ${colors.grey.light12};
`;

export const DescriptionContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: ${({ isComments }) => (isComments ? "column" : "row")};
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 800px;
  width: 100%;

  .ql-toolbar.ql-snow {
    display: none;
    border: none;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    border: 1px solid ${colors.grey.light13};
    padding: 10px;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    flex: 0 0 200px;
    margin-right: 20px;
    color: ${colors.black.light2};
    margin-bottom: ${({ isComments }) => (isComments ? "10px" : "0px")};
  }

  ${(props) =>
    props.$isMobile &&
    css`
      label {
        font-size: 12px;
      }
    `}
`;
