import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "src/styles";
import styled from "styled-components";
import { ActiveEmployee, ActiveVendor } from "../TicketTable";
interface ResourseAssignedProps {
  vendors: ActiveVendor[];
  employees: ActiveEmployee[];
  vendorId?: string;
  laborCode?: string;
  onClick: () => void;
  isTableCell?: boolean;
  highlight?: string;
}
const ResourseAssigned = ({
  employees,
  vendors,
  laborCode,
  vendorId,
  onClick,
  highlight,
  isTableCell,
}: ResourseAssignedProps) => {
  // console.log(laborCode);

  const getResourseData = () => {
    if (vendorId) {
      const vendor = vendors.find((v) => v.vendorId === vendorId);
      return vendor?.displayName ?? "";
    } else if (laborCode) {
      const employee = employees.find((e) => e.laborCode === laborCode);
      return employee?.displayName ?? "";
    } else {
      return;
    }
  };

  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = getResourseData()?.split(regex);
  return (
    <Container>
      <ResourseWrapper
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        {!(vendorId || laborCode) ? (
          <>
            <Icon color="orange" size={"lg"} icon={faTriangleExclamation} />
            <span>Unassigned</span>
          </>
        ) : (
          <span>
            {parts?.filter(String).map((part: string, i: number) => {
              if (isTableCell && highlight) {
                return regex.test(part) ? <Mark key={i}>{part}</Mark> : <span key={i}>{part}</span>;
              }
              return <span key={i}>{part}</span>;
            })}
          </span>
        )}
      </ResourseWrapper>
    </Container>
  );
};

export default ResourseAssigned;
const Mark = styled.mark`
  background-color: inherit;
  color: red;
`;
const ResourseWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  span {
    color: ${colors.grey.dark};
  }
`;
const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  padding-right: 8px;
`;
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 40px;

  &:hover {
    background: ${colors.grey.light1} !important;
  }
`;
