import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Table } from "react-bootstrap";
import { colors } from "src/styles";
import styled, { css } from "styled-components";
import { EmployeeTableCellProps, EmployeeTableRowProps } from "./types";

export const PayRollTableHeader = styled.th`
  background-color: #f0f0f0 !important;
  height: 42px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #f0f0f0;
  color: ${colors.grey.dark} !important;
`;
export const EmployeeTableRow = styled.tr<EmployeeTableRowProps>`
  outline: ${({ $isToday }) => $isToday && `1px dashed ${colors.grey.dark5};`};
`;

export const PayRollTableCell = styled.td<EmployeeTableCellProps>`
  border: 1px solid #f0f0f0;
  color: ${colors.grey.dark} !important;
  text-align: center;
  vertical-align: middle;
  height: 60px;
  ${(props) => props.minwidth && `max-width: ${props.minwidth}px;`}
  ${(props) => props.$isWeekend && `background-color: ${colors.grey.light2}!important;`}
    ${(props) => props.$isStat && `background-color: ${colors.yellow.light}!important;`}
    ${(props) => props.$hideBottomBorder && "border-bottom: unset;"}
    ${(props) => props.$hideTopBorder && "border-top: unset;"};
`;

export const EmployeeListItemWrapper = styled(Stack)<{ isselected: boolean }>`
  margin-top: 8px;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  ${({ isselected }) =>
    isselected &&
    css`
      border: 1px solid ${colors.kleenway.greenLight};
      background: ${colors.kleenway.greenLight};
    `}
`;

export const Wrapper = styled.div`
  position: absolute;
  right: 40px;
  display: flex;
  flex-direction: column;
  top: 160px;
`;
export const Container = styled(Stack)`
  height: 100%;
  align-items: flex-start;
`;

export const SidePanel = styled(Stack)`
  gap: 10px;
  width: 20%;
  min-width: 200px;
`;

export const ContentPanel = styled.div`
  width: 80%;
  min-width: 200px;
  padding: 0 15px;
`;
export const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ExportErrorMesssage = styled.span`
  height: 30px;
  color: ${colors.grey.dark};
`;

export const UserIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  padding: 5px;
  color: #6c6c6c;
`;

export const TextContainer = styled(Stack)`
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.grey.dark};
`;
export const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const StyledTable = styled(Table)`
  width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
`;
